<template>
  <w_header />

  <load_ing :isLoading="isLoading" />

  <div class="container">
    <div class="row mt-3">
      <div
        class="col-6"
        style="
          margin-left: 45px;
          margin-top: 0px;
          border: 2px solid rgba(0, 0, 0, 0.35);
        "
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Image</th>
              <th scope="col">Title</th>
              <th scope="col">Author</th>
              <th scope="col">Unit Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cartItem, index) in cartList" v-bind:key="cartItem._id">
              <th scope="row">{{ index + 1 }}</th>
              <td>
                <img
                  v-if="cartItem.book.avatar"
                  :src="`${cartItem.book.avatar}`"
                  alt="Book image"
                  style="height: 70px"
                />

                <span v-else>No Picture</span>
              </td>
              <td>{{ cartItem.book.name }}</td>
              <td>{{ cartItem.book.author }}</td>
              <td>{{ cartItem.book.price }}</td>
              <td>{{ cartItem.amount }}</td>
              <td>{{ cartItem.amount * cartItem.book.price }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <form
        @submit.prevent="placeOrder"
        class="pt-4 col-5"
        style="margin-left: 30px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px"
      >
        <div class="d-flex justify-content-between">
          <div>
            <label for="exampleFormControlInput1">Name*:</label>
          </div>
          &nbsp;
          <div>
            <label
              type="text"
              placeholder="Enter Your Name"
              style="padding-left: 4px; width: 400px"
              class="text-left"
              required
              >{{ name }}</label
            >
          </div>
        </div>

        <br />

        <div class="d-flex justify-content-between">
          <div>
            <label for="exampleFormControlInput1">Phone*:</label>
          </div>
          &nbsp;
          <div>
            <input
              type="text"
              v-model="phone"
              placeholder="Enter Phone number"
              style="padding-left: 4px; width: 400px"
              required
            />
          </div>
        </div>

        <br />

        <div class="d-flex justify-content-between">
          <div>
            <label for="exampleFormControlInput1">Email*:</label>
          </div>
          &nbsp;
          <div>
            <label
              type="text"
              placeholder="Enter Email address"
              class="text-left"
              style="padding-left: 4px; width: 400px"
              >{{ email }}</label
            >
          </div>
        </div>

        <br />

        <div class="d-flex justify-content-between">
          <div>
            <label for="exampleFormControlInput1">Full Address*:</label>
          </div>
          &nbsp;
          <div>
            <textarea
              type="text"
              v-model="fullAddress"
              placeholder="House number and village/city name"
              style="padding-left: 4px; width: 400px"
              required
            />
          </div>
        </div>

        <br />

        <div class="d-flex justify-content-between">
          <div>
            <label for="exampleFormControlInput1">Division*:</label>
          </div>
          &nbsp;
          <div>
            <select
              style="padding-left: 4px; width: 400px"
              v-model="selectedDivision"
              @change="updateDistrict(selectedDivision)"
              required
            >
              <option disabled value="">Select a Division</option>
              <option v-for="div in division" :key="div" :value="div">
                {{ div.title }}
              </option>
            </select>
          </div>
        </div>

        <br />

        <div class="d-flex justify-content-between">
          <div>
            <label for="exampleFormControlInput1">District*:</label>
          </div>
          &nbsp;
          <div>
            <select
              style="padding-left: 4px; width: 400px"
              v-model="selectedDistrict"
              @change="updateUpazila(selectedDistrict)"
              required
            >
              <option disabled value="">Select a District</option>
              <option v-for="dis in district" :key="dis" :value="dis">
                {{ dis.title }}
              </option>
            </select>
          </div>
        </div>

        <br />

        <div class="d-flex justify-content-between">
          <div>
            <label for="exampleFormControlInput1">Upazila/Thana*:</label>
          </div>
          &nbsp;
          <div>
            <select
              style="padding-left: 4px; width: 400px"
              v-model="selectedUpazila"
              @change="updateUnion(selectedUpazila)"
              required
            >
              <option disabled value="">Select an Upazila</option>
              <option v-for="upa in upazila" :key="upa" :value="upa">
                {{ upa.title }}
              </option>
            </select>
          </div>
        </div>

        <br />

        <div class="d-flex justify-content-between">
          <div>
            <label for="exampleFormControlInput1">Postal Code*:</label>
          </div>
          &nbsp;
          <div>
            <input
              type="text"
              v-model="postalCode"
              placeholder="Enter postal code"
              style="padding-left: 4px; width: 400px"
              required
            />
          </div>
        </div>

        <br />

        <table class="d-grid justify-content-center align-items-center">
          <tr>
            <th colspan="2">Your order</th>
          </tr>
          <tr class="d-flex justify-content-between">
            <td>Total Books:</td>
            <td>{{ calculateQuantity }}</td>
          </tr>
          <tr class="d-flex justify-content-between">
            <td>Subtotal:</td>
            <td>{{ calculateTotal }}</td>
          </tr>
          <tr class="d-flex justify-content-between">
            <td>Shipping Cost:</td>
            <td>{{ shippingCharge }}</td>
          </tr>
          <tr class="d-flex justify-content-between">
            <td>Total Amount:</td>
            <td>{{ calculateTotal + shippingCharge }}</td>
          </tr>
        </table>

        <br />

        <div class="d-flex flex-column align-items-center">
          <div class="p-2 d-flex align-items: center;">
            <input
              type="radio"
              name="dbt"
              value="COD"
              v-model="selectedPaymentMethod"
              required
            />&nbsp; Cash on Delivery &nbsp;
          </div>
          <div class="p-2 d-flex align-items: center;">
            <input
              type="radio"
              name="dbt"
              value="bkash"
              v-model="selectedPaymentMethod"
              required
            />&nbsp; BKash &nbsp;
            <span>
              <img
                src="../../assets/imgs/website/bkash.webp"
                alt=""
                width="20"
              />
            </span>
          </div>
          <div class="p-2 d-flex align-items: center;">
            <input
              type="radio"
              name="dbt"
              value="nagad"
              v-model="selectedPaymentMethod"
              required
            />&nbsp; Nagad &nbsp;
            <span>
              <img
                src="../../assets/imgs/website/nagad.webp"
                alt=""
                width="20"
              />
            </span>
          </div>
          <div class="p-2 d-flex align-items: center;">
            <input
              type="radio"
              name="dbt"
              value="rocket"
              v-model="selectedPaymentMethod"
              required
            />&nbsp; Rocket &nbsp;
            <span>
              <img
                src="../../assets/imgs/website/rocket.png"
                alt=""
                width="20"
              />
            </span>
          </div>
        </div>

        <br />
        <br />

        <div class="d-grid gap-2">
          <input
            class="btn btn-outline-dark"
            type="submit"
            value="Place Order"
          />
        </div>

        <br />
        <br />
      </form>
    </div>
  </div>
  <teleport to="body">
    <div class="bgPop" style="z-index: 999" v-if="cart">
      <div class="popBg">
        <h2 class="d-flex justify-content-end">
          <!-- <span style="cursor: pointer" @click="handle_cancel()">❌</span> -->
        </h2>
        <h2><span style="color: rgb(0, 187, 44)"></span> Message</h2>

        <div class="popText" style="text-align: center">
          <p class="errorMsg" style="box-shadow: none; border: none">
            Your order placed successfully...
          </p>
        </div>

        <div class="actionButtons">
          <!-- <a href="/userLogin" class="confirm"> Log In </a> -->
          <a @click="$router.push('/')" class="confirm">OK</a>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import load_ing from "../models/loading.vue";
import w_header from "./section/header.vue";
// import * as bootstrap from  "bootstrap";
import {
  getAllDistrict,
  getAllDivision,
  getAllUnion,
  getAllUpazila,
} from "bd-divisions-to-unions";

export default {
  name: "place_order",
  components: {
    w_header,
    load_ing,
  },
  data() {
    return {
      isLoading: false,
      cart: false,
      config: {},
      cartList: [],
      user: {},
      phone: "",
      email: "",
      name: "",
      selectedPaymentMethod: "",

      fullAddress: "",
      district: [],
      selectedDistrict: "",
      division: [],
      selectedDivision: "",
      upazila: [],
      selectedUpazila: "",
      union: [],
      selectedUnion: "",
      postalCode: "",

      finalDivision: "",
      finalDistrict: "",
      finalUpazila: "",
      finalUnion: "",
    };
  },
  methods: {
    updateDistrict(id) {
      this.district = getAllDistrict("en")[`${id.value}`];
      this.upazila = [];
      this.union = [];
      this.finalDivision = id.title;
    },
    updateUpazila(id) {
      this.upazila = getAllUpazila("en")[`${id.value}`];
      this.union = [];
      this.finalDistrict = id.title;
    },
    updateUnion(id) {
      this.union = getAllUnion("en")[`${id.value}`];
      this.finalUpazila = id.title;
    },
    getUnion(id) {
      this.finalUnion = id.title;
    },

    async placeOrder() {
      try {
        const body = {
          address: this.fullAddress,
          division: this.finalDivision,
          district: this.finalDistrict,
          thana: this.finalUpazila,
          postcode: this.postalCode,
          mobile: this.phone,
          paymentType: this.selectedPaymentMethod,
          total_amount: this.calculateTotal + this.shippingCharge,
        };
        const token = localStorage.getItem("Hakkani_token");
        const config = {
          headers: {
            Authorization: `${token}`,
          },
        };
        const url = "/order/user/add";
        this.isLoading = true;
        const response = await axios.post(url, body, config);
        this.isLoading = false;
        console.log(response);

        localStorage.setItem("Hakkani_token", response.data.token);
        this.cart = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    calculateTotal() {
      let totalPrice = 0;
      this.cartList.forEach((item) => {
        totalPrice += item.book.price * item.amount;
      });
      return totalPrice;
    },
    calculateQuantity() {
      let totallItems = 0;
      this.cartList.forEach((item) => {
        totallItems += item.amount;
      });
      return totallItems;
    },
    shippingCharge() {
      if (this.district == "Dhaka") {
        const amount = 80;

        return parseInt(amount);
      } else {
        return 120.0;
      }
    },
  },
  async mounted() {
    try {
      this.division = getAllDivision("en");
      const token = localStorage.getItem("Hakkani_token");
      this.config = {
        headers: {
          Authorization: `${token}`,
        },
      };

      const url = "/cart";
      this.isLoading = true;
      const response = await axios.get(url, this.config);
      this.isLoading = false;

      this.cartList = response.data[0].cartList;
      console.log(this.cartList);
    } catch (error) {
      console.log(error);
    }
    try {
      const data = localStorage.getItem("userId");
      this.user = JSON.parse(data);
      this.name = this.user.name;
      this.phone = this.user.mobile;
      this.email = this.user.email;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style>
body {
  /* background: url('http://all4desktop.com/data_images/original/4236532-background-images.jpg'); */
  font-family: "Roboto Condensed", sans-serif;
  color: #262626;
}
.container1 {
  /* margin: 5% 0; */
  width: 100%;
  /* padding-right: 15px; */
  /* padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
  min-height: 900px !important;
  font-size: 12px !important;
}
@media (min-width: 1200px) {
  .container1 {
    max-width: 1140px;
  }
}
.d-flex1 {
  display: flex;
  flex-direction: row;
  background: #f6f6f6;
  border-radius: 0 0 5px 5px;
  padding: 25px;
}
form {
  flex: 4;
}
.Yorder1 {
  flex: 2;
}
.title1 {
  background: gradient(
    linear,
    left top,
    right bottom,
    color-stop(0, #5195a8),
    color-stop(100, #70eaff)
  );
  background: -moz-linear-gradient(top left, #5195a8 0%, #70eaff 100%);
  background: -ms-linear-gradient(top left, #5195a8 0%, #70eaff 100%);
  background: -o-linear-gradient(top left, #5195a8 0%, #70eaff 100%);
  background: linear-gradient(to bottom right, #5195a8 0%, #70eaff 100%);
  border-radius: 5px 5px 0 0;
  padding: 10px;
  color: #f6f6f6;
  height: 40px;
}
h2 {
  margin: 0;
  padding-left: 15px;
  font-size: 16px !important;
}
.required {
  color: red;
}
label,
table {
  display: block;
  margin: 15px;
}
/* label>span{
  float: left;
  width: 25%;
  margin-top: 12px;
  padding-right: 10px;
} */
input[type="text"],
input[type="tel"],
input[type="email"],
select {
  width: 70%;
  height: 30px;
  padding: 5px 10px;
  margin-bottom: 10px;
  border: 1px solid #dadada;
  color: #888;
}
select {
  width: 72%;
  height: 45px;
  padding: 5px 10px;
  margin-bottom: 10px;
}
.Yorder1 {
  /* margin-top: 15px; */
  /* min-height: 900px; */
  padding: 20px;
  border: 1px solid #dadada;
}
table {
  margin: 0;
  padding: 0;
}
th {
  border-bottom: 1px solid #dadada;
  padding: 5px 0;
}
tr > td:nth-child(1) {
  text-align: left;
  color: #2d2d2a;
}
tr > td:nth-child(2) {
  text-align: right;
  color: #000000;
  font-size: 16px !important;
  font-weight: 800 !important;
}
td {
  border-bottom: 1px solid #dadada;
  padding: 10px 25px 3px 0px;
}

p {
  display: block;
  color: #888;
  margin: 0;
  padding-left: 25px;
}
.Yorder1 > div {
  padding: 15px 0;
}

button {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 30px;
  background: #52ad9c;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}
button:hover {
  cursor: pointer;
  background: #428a7d;
}
</style>
