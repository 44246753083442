<template>
  <div class="container-01">
    <side_bar />
    <load_ing :isLoading="isLoading" />
    <error_show
      :showError="showError"
      :errorMsg="errorMsg"
      @close-error="showError = false"
    />
    <success_show
      :showMsg="isSuccess"
      :successMsg="msgS"
      @close-error="showMsg = false"
    />
    <!-- ========================= Main ==================== -->
    <div class="main_admin">
      <top_bar :pageTitle="pgTitle" />

      <div class="container">
        <div class="row mt-5">
          <div class="col-3"></div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-3"></div>
          <div
            class="pt-4 col-6 mb-5"
            style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px"
          >
            <form
              @submit.prevent="book_update"
              enctype="multipart/form-data"
              style="margin-left: 30px"
            >
              <div class="d-flex justify-content-between">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Title : &nbsp;<span style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="name"
                    placeholder="Enter Book Titles"
                    style="padding-left: 4px; width: 350px"
                    required
                  />
                </div>
              </div>

              <br />

              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Author : &nbsp;<span style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="author"
                    placeholder="Enter Book's Author's Name"
                    style="padding-left: 4px; width: 350px"
                    required
                  />
                </div>
              </div>

              <br />

              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Editor/Authors :
                  </label>
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="editor"
                    placeholder="Enter Book's Editor's or others Author's Name"
                    style="padding-left: 4px; width: 350px"
                  />
                </div>
              </div>

              <br />
              <div class="d-flex justify-content-between">
                <!-- btn-outline-dark -->

                <div>
                  <label for="" class="form-label"
                    >Publication Type : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                <div>
                  <select
                    class="btn btn-outline-primary"
                    style="padding-left: 4px; width: 350px"
                    v-model="p_type"
                    required
                  >
                    <option value="" disabled>Select cover type</option>
                    <option value="Own Publication">Own Publication</option>
                    <option value="Joined Venchar Publication">
                      Joined Venchar Publication
                    </option>
                  </select>
                </div>
              </div>

              <br />
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Publishers :
                  </label>
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="publisher"
                    placeholder="Enter publisher's Name"
                    style="padding-left: 4px; width: 350px"
                  />
                </div>
              </div>

              <br />
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Book Type : &nbsp;<span style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <select
                    v-if="selectedType != 'Others**'"
                    v-model="bType"
                    style="padding-left: 4px; width: 350px"
                    required
                  >
                    <option disabled value="">Select Book Type</option>
                    <option v-for="type in Types" :key="type" :value="type">
                      {{ type }}
                    </option>
                  </select>
                </div>
              </div>

              <br />

              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Edition:</label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="edition"
                    placeholder="Enter Book's Edition"
                    style="padding-left: 4px; width: 350px"
                  />
                </div>
              </div>

              <br />

              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >ISBN : &nbsp;<span style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="isbn_num"
                    placeholder="Enter ISBN number"
                    style="padding-left: 4px; width: 350px"
                    required
                  />
                </div>
              </div>

              <br />

              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >ISSN:</label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="issn_num"
                    placeholder="Enter ISSN Number"
                    style="padding-left: 4px; width: 350px"
                  />
                </div>
              </div>

              <br />

              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Short Description : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <textarea
                    v-model="short_description"
                    placeholder="Write some short description about the books"
                    rows="3"
                    style="padding-left: 4px; width: 350px"
                    required
                  ></textarea>
                </div>
              </div>

              <br />

              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Long Description : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <textarea
                    v-model="long_description"
                    required
                    placeholder="Write a long description about the books"
                    rows="5"
                    style="padding-left: 4px; width: 350px"
                  ></textarea>
                </div>
              </div>

              <br />
              <div class="d-flex justify-content-between">
                <!-- btn-outline-dark -->

                <div>
                  <label for="" class="form-label"
                    >Cover Type : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                <div>
                  <select
                    class="btn btn-outline-primary"
                    style="padding-left: 4px; width: 350px"
                    v-model="cover"
                    required
                  >
                    <option value="" disabled>Select cover type</option>
                    <option value="Both">Both</option>
                    <option value="Paper Back">Paper Back</option>
                    <option value="Hard Cover">Hard Cover</option>
                  </select>
                </div>
              </div>

              <br />

              <div
                v-if="cover !== 'Paper Back'"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Hard Cover Price : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="hc_price"
                    placeholder="Enter Hard Cover book price"
                    style="padding-left: 4px; width: 350px"
                    required
                  />
                </div>
              </div>

              <br />

              <div
                v-if="cover !== 'Paper Back'"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Hard Cover Quantity : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="hc_quantity"
                    required
                    placeholder="Enter Hard Cover books quantity"
                    style="padding-left: 4px; width: 350px"
                  />
                </div>
              </div>

              <br />

              <div
                v-if="cover !== 'Paper Back'"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Hard Cover Discount : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="hc_discount"
                    required
                    placeholder="Enter Hard Cover books Discount"
                    style="padding-left: 4px; width: 350px"
                  />
                </div>
              </div>

              <br />

              <div
                v-if="cover !== 'Hard Cover'"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Paper Back Price : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="pb_price"
                    placeholder="Enter Paper Back book price"
                    style="padding-left: 4px; width: 350px"
                    required
                  />
                </div>
              </div>

              <br />

              <div
                v-if="cover !== 'Hard Cover'"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Paper Back Quantity : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="pb_quantity"
                    required
                    placeholder="Enter Paper Back books quantity"
                    style="padding-left: 4px; width: 350px"
                  />
                </div>
              </div>

              <br />

              <div
                v-if="cover !== 'Hard Cover'"
                class="d-flex justify-content-between align-items-center"
              >
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Paper Back Discount : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="pb_discount"
                    required
                    placeholder="Enter Paper Back books discount"
                    style="padding-left: 4px; width: 350px"
                  />
                </div>
              </div>

              <br />
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Page Number:</label
                  >
                </div>
                &nbsp;
                <div>
                  <input
                    type="text"
                    v-model="pageNumber"
                    placeholder="Enter page number"
                    style="padding-left: 4px; width: 350px"
                  />
                </div>
              </div>

              <br />

              <div class="d-flex justify-content-between">
                <div>
                  <label for="" class="form-label"
                    >Room Number : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                <div>
                  <select
                    class="btn btn-outline-primary"
                    style="padding-left: 4px; width: 350px"
                    v-model="selectedRoom"
                    required
                  >
                    <option value="null" disabled>Select Room Number</option>
                    <option v-for="room in rooms" :key="room">
                      {{ room }}
                    </option>
                  </select>
                </div>
              </div>
              <br />

              <div class="d-flex justify-content-between">
                <!-- btn-outline-dark -->

                <div>
                  <label for="" class="form-label"
                    >Shelf Number : &nbsp;<span
                      style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                <div>
                  <select
                    class="btn btn-outline-primary"
                    style="padding-left: 4px; width: 350px"
                    v-model="selectedShelf"
                    required
                  >
                    <option value="null" disabled>Select Shelf Number</option>
                    <option v-for="shelf in roomShelf" :key="shelf">
                      {{ shelf }}
                    </option>
                  </select>
                </div>
              </div>
              <br />

              <div class="d-flex justify-content-between">
                <!-- btn-outline-dark -->

                <div>
                  <label for="" class="form-label"
                    >Row Number: &nbsp;<span style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                <div>
                  <select
                    class="btn btn-outline-primary"
                    style="padding-left: 4px; width: 350px"
                    v-model="selectedRow"
                    required
                  >
                    <option value="null" disabled>Select Row Number</option>
                    <option v-for="row in rows" :key="row">
                      {{ row }}
                    </option>
                  </select>
                </div>
              </div>
              <br />

              <div class="d-flex justify-content-between">
                <!-- btn-outline-dark -->

                <div>
                  <label for="" class="form-label"
                    >Language : &nbsp;<span style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                <div>
                  <select
                    class="btn btn-outline-primary"
                    style="padding-left: 4px; width: 350px"
                    v-model="language"
                    required
                  >
                    <option value="" disabled>Select Language</option>
                    <option value="English">English</option>
                    <option value="Bangla">Bangla</option>
                  </select>
                </div>
              </div>

              <br />

              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="exampleFormControlInput1" class="form-label"
                    >Note:</label
                  >
                </div>
                &nbsp;
                <div>
                  <textarea
                    v-model="notes"
                    placeholder="Write note about the books"
                    rows="3"
                    style="padding-left: 4px; width: 400px"
                  ></textarea>
                </div>
              </div>

              <br />
              <div>
                <img
                  v-if="img"
                  :src="`${img}`"
                  alt="book image"
                  style="height: 150px"
                />
              </div>
              <br />
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <label for="formFile" class="form-label"
                    >Picture : &nbsp;<span style="color: red; font-size: 16px"
                      >*</span
                    ></label
                  >
                </div>
                &nbsp;
                <div class="">
                  <input
                    type="file"
                    name="image"
                    @change="handleFileChange"
                    style="padding-left: 4px; width: 350px"
                  />
                </div>
              </div>

              <br />
              <br />

              <div class="d-grid">
                <button class="btn btn-outline-success" type="submit">
                  Update Book Information
                </button>
              </div>

              <br />
            </form>
            <button
              v-if="user.permission === 'Admin'"
              class="btn btn-danger mb-4"
              @click="confirmDelete"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <teleport to="body">
    <div class="bgPop" style="z-index: 999" v-if="isDelete">
      <div class="popBg">
        <h2><span style="color: rgb(0, 187, 44)"></span>Confirmation</h2>

        <div class="popText" style="text-align: center">
          <p class="errorMsg" style="box-shadow: none; border: none">
            Are you sure to delete the books?
          </p>
        </div>

        <div class="actionButtons">
          <a @click="deleteBook" class="Delete"> Delete </a>
          <a @click="cancelDelete" class="confirm">Cancel</a>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import constants from "../../../config/constants";
import error_show from "../../models/error_show.vue";
import load_ing from "../../models/loading.vue";
import success_show from "../../models/success_show.vue";
import side_bar from "../sections/sidebar.vue";
import top_bar from "../sections/topbar.vue";
export default {
  name: "update-book",
  components: {
    side_bar,
    top_bar,
    error_show,
    load_ing,
    success_show,
  },
  data() {
    return {
      pgTitle: "Update Book",
      user: {},
      isLoading: false,
      showError: false,
      isSuccess: false,
      isDelete: false,
      msgS: "",
      cover: "",
      selectedRoom: null,
      selectedShelf: null,
      selectedRow: null,
      rooms: constants.roomNumber,
      roomShelf: constants.shelfNumber,
      rows: constants.rowNumber,
      selfNumbers: [],
      Types: constants.BOOK_TYPES,
      name: "",
      author: "",
      issn_num: "",
      isbn_num: "",
      short_description: "",
      long_description: "",
      avatar: "",
      bType: "",
      books: {},
      selectedType: "",
      img: "",
      notes: "",
      pageNumber: "",
      edition: "",
      language: "",
      selfNumber: "",
      roomNumber: "",
      publisher: "",
      p_type: "",
      pb_price: null,
      pb_quantity: null,
      hc_price: null,
      hc_quantity: null,
      pb_discount: null,
      hc_discount: null,
      discount: 0,
      editor: "",
      bookId: "",
    };
  },
  methods: {
    confirmDelete() {
      this.isDelete = true;
    },
    cancelDelete() {
      this.isDelete = false;
    },
    async deleteBook() {
      try {
        const token = localStorage.getItem("Hakkani_token");
        const config = {
          headers: {
            Authorization: `${token}`,
          },
        };

        const url = `/book/view/${this.bookId}`;

        this.isLoading = true;
        axios.delete(url, config);
        this.isLoading = false;
        this.$router.push("/add_books");
      } catch (error) {
        this.showError = true;
        this.errorMsg = error;
      }
    },
    handleFileChange(event) {
      this.avatar = event.target.files[0];
      console.log(event.target.files[0]);

      const reader = new FileReader();

      reader.onload = () => {
        this.isLoading = true;
        this.img = reader.result;
        this.isLoading = false;
      };

      reader.readAsDataURL(this.avatar);
    },
    async book_update() {
      try {
        const token = localStorage.getItem("Hakkani_token");
        const config = {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const bookInfo = {
          name: this.name,
          author: this.author,
          type: this.bType,
          cover_type: this.cover,
          isbn_num: this.isbn_num,
          issn_num: this.issn_num,
          short_description: this.short_description,
          long_description: this.long_description,
          edition: this.edition,
          pageNumber: this.pageNumber,
          language: this.language,
          note: this.notes,
          room_no: this.selectedRoom,
          self_no: this.selectedShelf,
          row: this.selectedRow,
          avatar: this.img,
          editor: this.editor,
          publication_type: this.p_type,
          publisher: this.publisher,
          h_price: this.hc_price,
          h_quantity: this.hc_quantity,
          p_price: this.pb_price,
          p_quantity: this.pb_quantity,
          h_discount: this.hc_discount,
          p_discount: this.pb_discount,
        };

        console.log(bookInfo);
        const url = `/book/view/${this.bookId}`;
        this.isLoading = true;
        const response = await axios.put(url, bookInfo, config);
        this.isLoading = false;
        console.log(response);
        this.msgS = "Books Updated Successfully";
        this.isSuccess = true;
      } catch (error) {
        this.isLoading = false;
        this.showError = true;
        this.errorMsg = error;
      }
    },
  },
  async mounted() {
    try {
      this.user = JSON.parse(localStorage.getItem("users"));
      console.log(this.user);
      const token = localStorage.getItem("Hakkani_token");
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };
      this.bookId = this.$route.params.book_id;
      const url = `/book/view/${this.bookId}`;
      this.isLoading = true;
      const response = await axios.get(url, config);
      this.isLoading = false;
      this.name = response.data.name;
      this.author = response.data.author;
      this.cover = response.data.cover_type;
      this.isbn_num = response.data.isbn_num;
      this.issn_num = response.data.issn_num;
      this.short_description = response.data.short_description;
      this.long_description = response.data.long_description;
      this.edition = response.data.edition;
      this.pageNumber = response.data.pageNumber;
      this.language = response.data.language;
      this.notes = response.data.note;
      this.selectedRoom = response.data.room_no;
      this.selectedShelf = response.data.self_no;
      this.img = response.data.avatar;
      this.bType = response.data.type;
      this.editor = response.data.editor;
      this.p_type = response.data.publication_type;
      this.publisher = response.data.publisher;
      this.hc_price = response.data.h_price;
      this.hc_quantity = response.data.h_quantity;
      this.pb_price = response.data.p_price;
      this.pb_quantity = response.data.p_quantity;
      this.pb_discount = response.data.p_discount;
      this.hc_discount = response.data.h_discount;
      this.selectedRow = response.data.row;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style></style>
