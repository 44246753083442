<template>
  <load_ing :isLoading="isLoading" />
  <success_show
    :showMsg="isSuccess"
    :successMsg="msgS"
    @close-error="showMsg = false"
  />
  <div class="container-fluid wholePage">
    <div class="row">
      <div class="col-4"></div>

      <form
        @submit.prevent="addUser"
        enctype="multipart/form-data"
        class="col-5 border my-3 formPage"
        style="box-shadow: 0px 0px 10px 10px #adadad65; padding: 50px"
      >
        <h1 style="font-size: 24px; border-bottom: 2px solid #000">
          User Registration Form
        </h1>
        <div class="d-flex justify-content-between align-items-center my-2">
          <div>
            <label for="exampleFormControlInput1" class="form-label"
              >Name*:</label
            >
          </div>
          &nbsp;
          <div>
            <input
              type="text"
              style="padding-left: 4px; width: 400px"
              v-model="name"
              placeholder="Enter your name"
              required
            />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center my-2">
          <div>
            <label for="exampleFormControlInput1" class="form-label"
              >Email:</label
            >
          </div>
          &nbsp;
          <div>
            <input
              type="text"
              style="padding-left: 4px; width: 400px"
              v-model="email"
              placeholder="Enter your mail"
            />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center my-2">
          <div>
            <label for="exampleFormControlInput1" class="form-label"
              >Phone*:</label
            >
          </div>
          &nbsp;
          <div>
            <input
              type="text"
              style="padding-left: 4px; width: 400px"
              v-model="phone"
              placeholder="Enter you phone number"
              required
            />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center my-2">
          <div>
            <label for="exampleFormControlInput1" class="form-label"
              >Password*:</label
            >
          </div>
          &nbsp;
          <div>
            <input
              type="password"
              style="padding-left: 4px; width: 400px"
              v-model="password"
              placeholder="Enter your password"
              required
            />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center my-2">
          <div class="text-align-left">
            <label for="exampleFormControlInput1" class="form-label"
              >Confirm Password*:</label
            >
          </div>
          &nbsp;
          <div>
            <input
              type="password"
              style="padding-left: 4px; width: 400px"
              v-model="confirm_password"
              placeholder="Confirm your password"
              required
            />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center my-2">
          <div>
            <label for="exampleFormControlInput1" class="form-label"
              >Full Address:</label
            >
          </div>
          &nbsp;
          <div>
            <textarea
              type="text"
              style="padding-left: 4px; width: 400px"
              v-model="fullAddress"
              placeholder="Enter your full address"
            />
          </div>
        </div>

        <div class="d-flex justify-content-between my-3">
          <div>
            <label for="exampleFormControlInput1">Division:</label>
          </div>
          &nbsp;
          <div>
            <select
              style="padding-left: 4px; width: 400px"
              v-model="selectedDivision"
              @change="updateDistrict(selectedDivision)"
              required
            >
              <option disabled value="">Select a Division</option>
              <option v-for="div in division" :key="div" :value="div">
                {{ div.title }}
              </option>
            </select>
          </div>
        </div>

        <div class="d-flex justify-content-between my-3">
          <div>
            <label for="exampleFormControlInput1">District:</label>
          </div>
          &nbsp;
          <div>
            <select
              style="padding-left: 4px; width: 400px"
              v-model="selectedDistrict"
              @change="updateUpazila(selectedDistrict)"
              required
            >
              <option disabled value="">Select a District</option>
              <option v-for="dis in district" :key="dis" :value="dis">
                {{ dis.title }}
              </option>
            </select>
          </div>
        </div>

        <div class="d-flex justify-content-between my-3">
          <div>
            <label for="exampleFormControlInput1">Upazila/Thana:</label>
          </div>
          &nbsp;
          <div>
            <select
              style="padding-left: 4px; width: 400px"
              v-model="selectedUpazila"
              @change="updateUnion(selectedUpazila)"
              required
            >
              <option disabled value="">Select an Upazila</option>
              <option v-for="upa in upazila" :key="upa" :value="upa">
                {{ upa.title }}
              </option>
            </select>
          </div>
        </div>

        <div class="d-flex justify-content-between my-3">
          <div>
            <label for="exampleFormControlInput1">Postal Code:</label>
          </div>
          &nbsp;
          <div>
            <input
              type="text"
              v-model="postalCode"
              placeholder="Enter postal code"
              style="padding-left: 4px; width: 400px"
              required
            />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center my-4">
          <div>
            <label for="formFile" class="form-label">Picture:</label>
          </div>
          &nbsp;
          <div class="">
            <input
              class="form-control"
              @change="handleFileChange"
              type="file"
              id="formFile"
            />
          </div>
        </div>

        <div class="d-grid gap-2">
          <input class="btn btn-success" value="Registration" type="submit" />
        </div>

        <br /><br />
      </form>

      <div class="col-3"></div>
    </div>

    <br /><br />
  </div>
</template>

<script>
import axios from "axios";
// import axios from "axios";
import load_ing from "../models/loading.vue";
import success_show from "../models/success_show.vue";
// import * as bootstrap from  "bootstrap";
import {
  getAllDistrict,
  getAllDivision,
  getAllUnion,
  getAllUpazila,
} from "bd-divisions-to-unions";

export default {
  name: "userRegistration",
  components: {
    load_ing,
    success_show,
  },
  data() {
    return {
      isLoading: false,
      config: {},
      name: "",
      phone: "",
      email: "",
      avatar: "",
      password: "",
      confirm_password: "",
      img: null,
      msgS: "",
      isSuccess: false,

      fullAddress: "",
      district: [],
      selectedDistrict: "",
      division: getAllDivision("en"),
      selectedDivision: "",
      upazila: [],
      selectedUpazila: "",
      union: [],
      selectedUnion: "",
      postalCode: "",

      finalDivision: "",
      finalDistrict: "",
      finalUpazila: "",
      finalUnion: "",
    };
  },
  methods: {
    updateDistrict(id) {
      this.district = getAllDistrict("en")[`${id.value}`];
      this.upazila = [];
      this.union = [];
      this.finalDivision = id.title;
    },
    updateUpazila(id) {
      this.upazila = getAllUpazila("en")[`${id.value}`];
      this.union = [];
      this.finalDistrict = id.title;
    },
    updateUnion(id) {
      this.union = getAllUnion("en")[`${id.value}`];
      this.finalUpazila = id.title;
    },
    getUnion(id) {
      this.finalUnion = id.title;
    },
    handleFileChange(event) {
      this.avatar = event.target.files[0];
      const reader = new FileReader();
      this.isLoading = true;
      reader.onload = () => {
        this.img = reader.result;
        console.log(this.img);
      };
      this.isLoading = false;
      reader.readAsDataURL(this.avatar);
    },
    async addUser() {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const body = {
        name: this.name,
        mobile: this.phone,
        email: this.email,
        password: this.password,
        address: this.fullAddress,
        division: this.finalDivision,
        district: this.finalDistrict,
        thana: this.finalUpazila,
        postcode: this.postalCode,
        avatar: this.img,
      };
      console.log(body);
      const url = "/user/add";
      this.isLoading = true;
      const response = await axios.post(url, body, config);
      this.isLoading = false;
      this.msgS = "Rregistration Succesfull...";
      this.isSuccess = true;
      console.log(response);
    },
  },
};
</script>

<style>
.wholePage {
  background: linear-gradient(to bottom, #ffffff, #ffffff);
}
.formPage {
  background: linear-gradient(to bottom, #ffffff26, #a13d8a00);
}
</style>
