
import backup from "./components/offical_panel/backup/backup.vue";
import add_books from "./components/offical_panel/books/add_books.vue";
import book_search from "./components/offical_panel/books/book_serach.vue";
import update_book from "./components/offical_panel/books/update_books";
import overview from "./components/offical_panel/dashboard/dashboard.vue";
import login from "./components/offical_panel/logIn/login.vue";
import manage_orders from "./components/offical_panel/order/manage_order.vue";
import single_order from "./components/offical_panel/order/single_order.vue";
import re_book_list from "./components/offical_panel/report/book_list.vue";
import report from "./components/offical_panel/report/report.vue";
import add_users from "./components/offical_panel/users/add_users.vue";
import update_users from "./components/offical_panel/users/update_users.vue";


import book_pdf from "./components/offical_panel/books/book_pdf.vue";
import aboutUs from "./components/website/aboutUs.vue";
import book_list from "./components/website/book_list.vue";
import cart_list from "./components/website/cart_list.vue";
import ebookPage from "./components/website/ebookPage.vue";
import ejournalPage from "./components/website/ejournalPage.vue";
import eperiodicalPage from "./components/website/eperiodicalPage.vue";
import index from "./components/website/index.vue";
import place_order from "./components/website/place_order.vue";
import single_cart from "./components/website/single_cart.vue";
import userLogin from "./components/website/userLogin.vue";
import userOrderDetails from "./components/website/userOrderDetails.vue";
import userOrderPage from "./components/website/userOrderPage.vue";
import userProfile from "./components/website/userProfile.vue";
import userRegistration from "./components/website/userRegistration.vue";

const routes = [
    {
        name:"login",
        component:login,
        path:"/admin"
    },
    {
        name:"overview",
        component:overview,
        path:"/dashboard"
    },
    {
        name:"add_books",
        component:add_books,
        path:"/add_books"
    },
    {
        name:"update_book",
        component:update_book,
        path:"/update_book/:book_id"
    },
    
    {
        name:"book_search",
        component:book_search,
        path:"/book_search"
    },
    {
        name:"add_users",
        component:add_users,
        path:"/add_users"
    },
    {
        name:"manage_orders",
        component:manage_orders,
        path:"/manage_orders"
    },
    {
        name:"update_users",
        component:update_users,
        path:"/update_users/:id"
    },
    {
        name:"report",
        component:report,
        path:"/report"
    },
    {
        name:"re_book_list",
        component:re_book_list,
        path:"/re_book_list"
    },
    {
        name:"backup",
        component:backup,
        path:"/backup"
    },

    {
        name:"single_order",
        component:single_order,
        path:"/single_order"
    },
    {
        name:"index",
        component:index,
        path:"/"
    },
    {
        name:"single_cart",
        component:single_cart,
        path:"/single_cart/:id"
    },
    {
        name:"place_order",
        component:place_order,
        path:"/place_order"
    },
    {
        name:"cart_list",
        component:cart_list,
        path:"/cart_list"
    },
    {
        name:"userLogin",
        component:userLogin,
        path:"/userLogin"
    },
    {
        name:"userProfile",
        component:userProfile,
        path:"/userProfile"
    },
    {
        name:"userRegistration",
        component:userRegistration,
        path:"/userRegistration"
    },
    {
        name:"book_list",
        component:book_list,
        path:"/book_list/:key/:value"
    },
    {
        name:"userOrderPage",
        component:userOrderPage,
        path:"/userOrderPage/:cart_id"
    },
    {
        name:"ebookPage",
        component:ebookPage,
        path:"/ebookPage"
    },
    {
        name:"eperiodicalPage",
        component:eperiodicalPage,
        path:"/eperiodicalPage"
    },
    {
        name:"book_pdf",
        component:book_pdf,
        path:"/book_pdf/:key/:value"
    },
    {
        name:"ejournalPage",
        component:ejournalPage,
        path:"/ejournalPage"
    },
    {
        name:"aboutUs",
        component:aboutUs,
        path:"/aboutUs"
    },
    {
        name:"userOrderDetails",
        component:userOrderDetails,
        path:"/userOrderDetails"
    },
    
    

    
];

import { createRouter, createWebHistory } from 'vue-router';


const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'hash'
});

export default router;