<template>
  <w_header />
  <load_ing :isLoading="isLoading" />

  <section>
    <div class="shadow-lg">
      <div class="section02">
        <div class="content02">
          <br />
          <h1 class="mx-2">E-Books</h1>
          <div>
            <p class="text-justify mx-2">
              Hakkani Publishers' commitment to excellence extends beyond mere
              publication; it's a dedication to fostering learning and knowledge
              dissemination. By partnering with esteemed entities such as
              Elsevier, Pearson, Oxford, Cambridge, John Wiley, Emerald, McGraw
              Hill, and others, Hakkani Publishers solidifies its standing as a
              premier provider of e-books in the educational and professional
              landscape. This strategic collaboration not only enhances the
              breadth and depth of their digital library but also underscores
              their mission to empower learners and professionals with the most
              authoritative resources available. With these partnerships,
              Hakkani Publishers is not just offering e-books; they're opening
              doors to a world of unparalleled educational and intellectual
              exploration, enriching minds and shaping futures.
            </p>

            <p class="text-justify mx-2">
              Embark on a journey of boundless knowledge and intellectual
              enrichment with Hakkani Publishers' comprehensive e-book
              collection. Through strategic partnerships with esteemed
              publishers such as Elsevier, Pearson, Oxford, Cambridge, John
              Wiley, Emerald, McGraw Hill, and others, we offer an unparalleled
              selection of e-books spanning various subjects and genres. From
              educational resources to professional development materials, our
              curated repository ensures access to high-quality content from
              leading publishers worldwide. Whether you're a student, educator,
              or professional, Hakkani Publishers empowers you to explore,
              learn, and grow with confidence. Immerse yourself in a world of
              limitless possibilities, where each e-book is a gateway to new
              ideas, perspectives, and insights. Join us in our commitment to
              excellence, accessibility, and the relentless pursuit of
              knowledge, as we redefine the landscape of digital publishing
              together.
            </p>
          </div>
        </div>

        <div class="picture02">
          <div
            class="d-flex flex-column justify-content-center align-items-center m-3"
          >
            <div class="card col-6 p-1 m-2">
              <img
                src="../../assets/imgs/website/e-book.gif"
                class="card-img-top"
                alt="..."
              />
            </div>

            <div class="card col-6 p-1 m-2">
              <img
                src="../../assets/imgs/website/ebook.jpg"
                class="card-img-top"
                alt="history"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <w_footer />
</template>

<script>
import load_ing from "../models/loading.vue";
import w_footer from "./section/footer.vue";
import w_header from "./section/header.vue";
// import * as bootstrap from  "bootstrap";
export default {
  name: "ebookPage",
  components: {
    w_header,
    load_ing,
    w_footer,
  },
  data() {
    return {
      district: "Dhaka",
      cartList: [],
      isLoading: false,
      modalMsg: "",
      modal_view: false,
    };
  },
};
</script>

<style></style>
