<template>
  <div class="container-01">
    <side_bar />
    <load_ing :isLoading="isLoading" />
    <error_show
      :showError="showError"
      :errorMsg="errorMsg"
      @close-error="showError = false"
    />
    <success_show
      :showMsg="isSuccess"
      :successMsg="msgS"
      @close-error="showMsg = false"
    />
    <!-- ========================= Main ==================== -->
    <div class="main_admin">
      <top_bar :pageTitle="pgTitle" />

      <div class="container">
        <div class="row mt-5">
          <div class="col-3"></div>

          <div
            class="pt-4 col-6"
            style="
              margin-left: 30px;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              border-radius: 15px;
            "
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Name:</label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="name"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder=""
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Designation:</label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="designation"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder=""
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Email:</label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder=""
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Phone:</label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="phone"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder=""
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Address:</label
                >
              </div>
              &nbsp;
              <div>
                <textarea
                  class="form-control"
                  v-model="address"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  style="padding-left: 46px"
                ></textarea>
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >User Role:</label
                >
              </div>
              &nbsp;
              <select
                class="btn btn-outline-primary"
                style="padding-left: 4px; width: 200px"
                v-model="checkedPermissions"
              >
                <option value="" disabled>Select user type</option>
                <option value="Admin">Admin</option>
                <option value="Employee">Employee</option>
              </select>
            </div>

            <br />
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="formFile" class="form-label">Picture:</label>
              </div>
              &nbsp;
              <div class="">
                <input class="form-control" type="file" id="formFile" />
              </div>
            </div>

            <br />
            <br />

            <div class="d-grid gap-2">
              <button
                class="btn btn-outline-dark"
                @click="update_user"
                type="button"
              >
                Update User
              </button>
            </div>

            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import axios from "axios";
import error_show from "../../models/error_show.vue";
import load_ing from "../../models/loading.vue";
import success_show from "../../models/success_show.vue";
import side_bar from "../sections/sidebar.vue";
import top_bar from "../sections/topbar.vue";
export default {
  name: "update-users",
  components: {
    side_bar,
    top_bar,
    load_ing,
    error_show,
    success_show,
  },
  data() {
    return {
      pgTitle: "Update Users",
      isLoading: false,
      showError: false,
      showMsg: false,
      showDeletation: false,
      user_id: "",
      name: "",
      designation: "",
      email: "",
      phone: "",
      address: "",
      password: "",
      confirm_password: "",
      checkedPermissions: "",
      user_details: {},
      MainUser: {},
    };
  },
  methods: {
    async update_user() {
      const token = localStorage.getItem("Hakkani_token");
      const config = {
        headers: { Authorization: `${token}` },
      };
      const body = {
        name: this.name,
        designation: this.designation,
        email: this.email,
        mobile: this.phone,
        permission: this.checkedPermissions,
        address: this.address,
      };
      const url = `/employee/view/${this.user_id}`;
      this.isLoading = true;
      const response = await axios.put(url, body, config);
      this.isLoading = false;
      console.log(response);
      if (response.data._id === this.user_id) {
        localStorage.setItem("users", JSON.stringify(response.data));
      }
    },
  },
  async mounted() {
    try {
      this.MainUser = JSON.parse(localStorage.getItem("users"));
      this.user_id = this.$route.params.id;
      const token = localStorage.getItem("Hakkani_token");
      const config = {
        headers: { Authorization: `${token}` },
      };
      const url = `/employee/view/${this.user_id}`;
      this.isLoading = true;
      const response = await axios.get(url, config);
      this.isLoading = false;
      console.log(response);
      this.name = response.data.name;
      this.designation = response.data.designation;
      this.email = response.data.email;
      this.phone = response.data.mobile;
      this.checkedPermissions = response.data.permission;
      this.address = response.data.address;
    } catch (error) {
      this.isLoading = false;
      this.showError = true;
      this.errorMsg = error.response.data.message;
    }
  },
};
</script>

<style></style>
