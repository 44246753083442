<template>
  <div class="container-01">
    <side_bar />
    <load_ing :isLoading="isLoading" />
    <error_show
      :showError="showError"
      :errorMsg="errorMsg"
      @close-error="showError = false"
    />
    <success_show
      :showMsg="isSuccess"
      :successMsg="msgS"
      @close-error="isSuccess = false"
    />
    <!-- ========================= Main ==================== -->
    <div class="main_admin">
      <top_bar :pageTitle="pgTitle" />
      <div class="container">
        <div class="table-wrapper">
          <div class="table-title">
            <div class="row">
              <div class="col-sm-4">
                <h2>Order <b>Details </b></h2>
              </div>
              <div class="col-sm-8"></div>
            </div>
          </div>
          <div class="table-filter">
            <div class="row">
              <div class="col-sm-3"></div>
              <div
                class="col-sm-9 d-flex justify-content-evenly align-items-center"
              >
                <div class="filter-group">
                  <label>Order Status</label>
                  <select class="form-control" v-model="orderStatus">
                    <option value="">Any</option>
                    <option value="delivered">Delivered</option>
                    <option value="shipped">Shipped</option>
                    <option value="pending">Pending</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>
                <div class="filter-group">
                  <label>Payment Status</label>
                  <select class="form-control" v-model="paymentStatus">
                    <option value="">Any</option>
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                  </select>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  style="width: 60px; margin: 5px"
                  @click="order_search"
                >
                  <img
                    style="height: 14px"
                    src="../../../assets/imgs/icons/search-svgrepo-com.svg "
                  />
                </button>
              </div>
            </div>
          </div>
          <table
            class="table table-striped table-hover"
            style="min-width: 100px"
          >
            <thead>
              <tr>
                <th style="width: 50px">#</th>
                <th style="width: 250px">Customer</th>
                <th style="width: 150px">Location</th>
                <th style="width: 150px">Order Date</th>
                <th style="width: 150px">Status</th>
                <th style="width: 150px">Net Amount</th>
                <th style="width: 150px">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(order, index) in orders" v-bind:key="index">
                <td style="width: 50px">
                  {{ order._id }}
                </td>
                <td style="width: 250px; text-align: center">
                  {{ order.user.name }}
                </td>
                <td style="width: 150px">
                  {{ order.district }},{{ order.division }}
                </td>
                <td style="width: 150px">{{ order.paymentStatus }}</td>
                <td
                  style="width: 150px"
                  v-if="order.orderStatus === 'delivered'"
                >
                  <span class="status text-success">&bull;</span>
                  Delivered
                </td>
                <td style="width: 150px" v-else>
                  <span class="status text-warning">&bull;</span>
                  Pending
                </td>
                <td style="width: 150px">{{ order.total_amount }}</td>
                <td>
                  <a
                    href="/single_order"
                    class="view icon"
                    title="View Details"
                    data-toggle="tooltip"
                    ><img
                      src="../../../assets/imgs/icons/arrow-narrow-circle.svg"
                      alt=""
                  /></a>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="clearfix">
            <div v-if="maxPage != 0" class="pagination">
              <button
                @click="updateCurrentPage(-1)"
                :disabled="currentPage <= 3"
              >
                Previous
              </button>
              <h2 style="color: black">...</h2>
              <button v-if="currentPage - 2 > 0" @click="updateCurrentPage(-2)">
                {{ currentPage - 2 }}
              </button>
              <button v-if="currentPage - 1 > 0" @click="updateCurrentPage(-1)">
                {{ currentPage - 1 }}
              </button>

              <button class="currentPage">{{ currentPage }}</button>

              <button
                v-if="currentPage + 1 <= maxPage"
                @click="updateCurrentPage(+1)"
              >
                {{ currentPage + 1 }}
              </button>
              <button
                v-if="currentPage + 2 <= maxPage"
                @click="updateCurrentPage(+2)"
              >
                {{ currentPage + 2 }}
              </button>
              <h2 style="color: black">...</h2>

              <button
                @click="updateCurrentPage(+1)"
                :disabled="currentPage >= maxPage - 2"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import error_show from "../../models/error_show.vue";
import load_ing from "../../models/loading.vue";
import success_show from "../../models/success_show.vue";
import side_bar from "../sections/sidebar.vue";
import top_bar from "../sections/topbar.vue";
export default {
  name: "manage_order",
  components: {
    side_bar,
    top_bar,
    error_show,
    load_ing,
    success_show,
  },
  data() {
    return {
      pgTitle: "Manage Orders",
      materialIcon: "&#xE863;",
      loaction: "all",
      isLoading: false,
      showError: false,
      isSuccess: false,
      msgS: "",
      errorMsg: "",
      orders: {},
      orderStatus: "",
      paymentStatus: "",

      currentPage: 1,
      pageSize: 15,
      bookLength: 0,
      maxPage: 0,
      directPageNumber: 1,
    };
  },
  methods: {
    updateCurrentPage(num) {
      this.currentPage = parseInt(this.currentPage) + parseInt(num);
      this.book_search();
      this.directPageNumber = parseInt(this.currentPage);
    },
    directPage() {
      if (this.directPageNumber > this.maxPage || this.directPageNumber <= 0) {
        this.showError = true;
        this.errorMsg = "No more page exist in this search results.";
      } else {
        this.currentPage = parseInt(this.directPageNumber);
        this.book_search();
      }
    },
    async order_search() {
      try {
        const token = localStorage.getItem("Hakkani_token");
        const config = {
          headers: {
            Authorization: `${token}`,
          },
        };
        const body = {
          paymentStatus: this.paymentStatus,
          orderStatus: this.orderStatus,
        };
        console.log(body);
        const url = `/order/admin/search`;
        this.isLoading = true;
        const response = await axios.post(url, body, config);
        this.isLoading = false;

        this.orders = response.data;
        this.currentPage = response.data.currentPage;
        this.bookLength = response.data.totalCount;
        this.maxPage = Math.ceil(this.bookLength / this.pageSize);
        console.log(response);
      } catch (error) {
        this.showError = true;
        this.errorMsg = error;
      }
    },
  },
  async mounted() {
    try {
      const token = localStorage.getItem("Hakkani_token");
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };
      const url = "/order/admin?page=1";
      this.isLoading = true;
      const response = await axios.get(url, config);
      this.isLoading = false;

      this.currentPage = response.data.currentPage;
      this.bookLength = response.data.totalCount;
      this.maxPage = Math.ceil(this.bookLength / this.pageSize);

      this.orders = response.data.order;
      console.log(response);
    } catch (error) {
      this.showError = true;
      this.errorMsg = error;
    }
  },
};
</script>
<style scoped>
.table-wrapper {
  background: #fff;
  margin: 60px 40px;
  border-radius: 3px;
  padding: 20px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.table-wrapper .btn {
  float: right;
  color: #333;
  background-color: #fff;
  border-radius: 3px;
  border: none;
  outline: none !important;
  margin-left: 10px;
}
.table-wrapper .btn:hover {
  color: #333;
  background: #f2f2f2;
}
.table-wrapper .btn.btn-primary {
  color: #fff;
  background: #03a9f4;
}
.table-wrapper .btn.btn-primary:hover {
  background: #03a3e7;
}
.table-title .btn {
  font-size: 13px;
  border: none;
}
.table-title .btn i {
  float: left;
  font-size: 21px;
  margin-right: 5px;
}
.table-title .btn span {
  float: left;
  margin-top: 2px;
}
.table-title {
  color: #fff;
  background: #4980ff;
  padding: 16px 25px;
  border-radius: 3px 3px 0 0;
}
.table-title h2 {
  margin: 5px 0 0;
  font-size: 24px;
}
.show-entries select.form-control {
  width: 60px;
  margin: 0 5px;
}
.table-filter .filter-group {
  float: right;
  margin-left: 15px;
}
.table-filter input,
.table-filter select {
  height: 34px;
  border-radius: 3px;
  border-color: #ddd;
  box-shadow: none;
}
.table-filter {
  padding: 5px 0 15px;
  border-bottom: 1px solid #e9e9e9;
}
.table-filter .btn {
  height: 34px;
}
.table-filter label {
  font-weight: normal;
  margin-left: 10px;
}
.table-filter select,
.table-filter input {
  display: inline-block;
  margin-left: 5px;
}
.table-filter input {
  width: 200px;
  display: inline-block;
}
.filter-group select.form-control {
  width: 110px;
}
.filter-icon {
  float: right;
  margin-top: 7px;
}
.icon img {
  height: 25px;
}
.filter-icon i {
  font-size: 18px;
  opacity: 0.7;
}
table.table tr th,
table.table tr td {
  border-color: #e9e9e9;
  padding: 12px 15px;
  vertical-align: middle;
}
table.table tr th:first-child {
  width: 60px;
}
table.table tr th:last-child {
  width: 80px;
}
table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
table.table-striped.table-hover tbody tr:hover {
  background: #f5f5f5;
}
table.table th i {
  font-size: 13px;
  margin: 0 5px;
  cursor: pointer;
}
table.table td a {
  font-weight: bold;
  color: #566787;
  display: inline-block;
  text-decoration: none;
}
table.table td a:hover {
  color: #2196f3;
}
table.table td a.view {
  width: 40px;
  height: 40px;
  color: #2196f3;
  text-align: center;
}
table.table td a.view img {
  height: 40px;
}
table.table .avatar {
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}
.status {
  font-size: 30px;
  margin: 2px 2px 0 0;
  display: inline-block;
  vertical-align: middle;
  line-height: 10px;
}
.text-success {
  color: #10c469;
}
.text-info {
  color: #62c9e8;
}
.text-warning {
  color: #ffc107;
}
.text-danger {
  color: #ff5b5b;
}
.pagination {
  float: right;
  margin: 0 0 5px;
}
.pagination li a {
  border: none;
  font-size: 13px;
  min-width: 30px;
  min-height: 30px;
  color: #999;
  margin: 0 2px;
  line-height: 30px;
  border-radius: 2px !important;
  text-align: center;
  padding: 0 6px;
}
.pagination li a:hover {
  color: #666;
}
.pagination li.active a {
  background: #03a9f4;
}
.pagination li.active a:hover {
  background: #0397d6;
}
.pagination li.disabled i {
  color: #ccc;
}
.pagination li i {
  font-size: 16px;
  padding-top: 6px;
}
.hint-text {
  float: left;
  margin-top: 10px;
  font-size: 13px;
}
</style>
