<template>
  <w_header />
  <load_ing :isLoading="isLoading" />

  <div class="wrapper">
    <aside
      style="
        width: 20%;
        margin-left: 100px;
        background-color: #f2f2f2;
        box-shadow: inset 0px 0px 5px 3px rgba(27, 153, 255, 0.392) !important;
      "
    >
      <div class="h-100">
        <div class="sidebar-logo">
          <a>All Books</a>
        </div>

        <ul class="sidebar-nav">
          <a class="sidebar-header" @click="allBook">All Books</a>

          <li class="sidebar-item">
            <a href="#" class="sidebar-link"> </a>
          </li>

          <li class="sidebar-item">
            <a
              href="#"
              class="sidebar-link collapsed title_bk"
              data-bs-toggle="collapse"
              data-bs-target="#Language"
              aria-expanded="false"
              aria-controls="Language"
            >
              Language
            </a>
            <ul
              id="Language"
              class="sidebar-dropdown list-unstyled collapse"
              data-bs-parent="#sidebar"
            >
              <li class="sidebar-item mx-4">
                <a
                  href="#"
                  class="sidebar-link"
                  @click="search('language', 'Bangla')"
                  >Bangla</a
                >
              </li>

              <li class="sidebar-item mx-4">
                <a
                  href="#"
                  class="sidebar-link"
                  @click="search('language', 'English')"
                  >English</a
                >
              </li>
            </ul>
          </li>

          <li class="sidebar-item">
            <a
              href="#"
              class="sidebar-link collapsed title_bk"
              data-bs-toggle="collapse"
              data-bs-target="#Availability"
              aria-expanded="false"
              aria-controls="Availability"
            >
              Availability
            </a>
            <ul
              id="Availability"
              class="sidebar-dropdown list-unstyled collapse"
              data-bs-parent="#sidebar"
            >
              <li class="sidebar-item ms-5">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    @click="search('quantity', 1)"
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Hide out of stock
                  </label>
                </div>
              </li>
            </ul>
          </li>

          <li class="sidebar-item">
            <a
              href="#"
              class="sidebar-link collapsed title_bk"
              data-bs-toggle="collapse"
              data-bs-target="#Categories"
              aria-expanded="false"
              aria-controls="Categories"
            >
              Categories
            </a>
            <ul
              id="Categories"
              class="sidebar-dropdown list-unstyled collapse"
              data-bs-parent="#sidebar"
            >
              <li class="sidebar-item d-flex flex-column align-items-left">
                <!-- <div class="form-check d-flex align-items-center">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="option1"
                    @click="search('type', '')"
                    checked
                  />
                  <label class="form-check" for="exampleRadios1">
                    All Books
                  </label>
                </div> -->

                <div
                  class="form-check pl"
                  v-for="type in Types"
                  :key="type"
                  :value="type"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    v-model="typeValue"
                    checked
                    style="color: blue"
                    @click="search('type', type)"
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    {{ type }}
                  </label>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </aside>

    <div class="mainList">
      <!-- <nav class="navbar navbar-expand px-3 border-bottom">
        <button class="btn" type="button">
          <span class="navbar-toggler-icon"></span>
        </button>
      </nav> -->

      <!-- new card -->

      <div class="container-lg shadow-lg">
        <div class="row">
          <div
            class="card col-lg-2 col-xxl-2 col-md-3 col-sm-4 col-6 m-3 newBooks"
            v-for="(Sbooks, index) in second_books"
            :key="index"
          >
            <a @click="$router.push('/single_cart/' + Sbooks._id)">
              <div>
                <span class="newBooksLabel">
                  <span>{{ Sbooks.type }}</span>
                </span>
              </div>
              <img
                :src="`${Sbooks.avatar}`"
                style="height: 250px"
                class="card-img-top"
                alt="..."
              />
            </a>
            <div class="card-body newBooksBox">
              <h5
                class="card-title text-center m-auto"
                style="font-size: 14px; font-weight: 400; height: 3rem"
              >
                {{ Sbooks.name }}
              </h5>
            </div>
            <div class="d-flex justify-content-center">
              <div class="form-group">
                <select
                  class="form-control"
                  :v-model="selectedPriceCategory[index] == Sbooks.cover_type"
                  @change="coverTypeChange(index, $event.target.value)"
                >
                  <option
                    v-if="Sbooks.cover_type !== 'Hard Cover'"
                    :selected="Sbooks.cover_type !== 'Hard Cover'"
                    value="Paper Back"
                  >
                    Paper back - {{ Sbooks.p_price }}
                  </option>
                  <option
                    v-if="Sbooks.cover_type !== 'Paper Back'"
                    :selected="Sbooks.cover_type !== 'Paper Back'"
                    value="Hard Cover"
                  >
                    Hard cover - {{ Sbooks.h_price }}
                  </option>
                </select>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <!-- Displaying both paperback and hardcover prices -->
              <div class="d-flex flex-column align-items-center">
                <p v-if="Sbooks.p_price" class="card-text newBooksPrice">
                  Paperback: <br />
                  <br />{{
                    Sbooks.p_price * ((100 - Sbooks.p_discount) / 100)
                  }}৳
                </p>
                <p v-else class="card-text newBooksPrice">
                  Hardcover: <br />
                  <br />{{
                    Sbooks.h_price * ((100 - Sbooks.h_discount) / 100)
                  }}৳
                </p>
              </div>
              <div class="mx-2"></div>
              <div class="d-flex flex-column align-items-center">
                <p v-if="Sbooks.p_price" class="text-decoration-line-through">
                  <br />
                  <br />
                  {{ Sbooks.p_price }}৳
                </p>
                <p v-else class="text-decoration-line-through">
                  <br />
                  <br />{{ Sbooks.h_price }}৳
                </p>
              </div>
            </div>
            <div class="d-grid m-1">
              <button
                class="btn newBooksButton"
                type="button"
                @click="
                  checkAuthentication(
                    Sbooks._id,
                    Sbooks.p_quantity,
                    Sbooks.h_quantity,
                    selectedPriceCategory[index]
                  )
                "
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- card ends -->
    </div>
  </div>

  <teleport to="body">
    <div class="bgPop" v-if="modal_view">
      <div class="popBg">
        <h2>Massage</h2>

        <div class="popText">
          <p>{{ modalMsg }}</p>
        </div>

        <div class="actionButtons">
          <button @click="handle_cancel()" class="cancel">close</button>
        </div>
      </div>
    </div>
  </teleport>
  <teleport to="body">
    <div class="bgPop" style="z-index: 999" v-if="cart">
      <div class="popBg">
        <h2 class="d-flex justify-content-end">
          <span style="cursor: pointer" @click="handle_cancel()">❌</span>
        </h2>
        <h2><span style="color: rgb(0, 187, 44)"></span>Important Message</h2>

        <div class="popText" style="text-align: center">
          <p class="errorMsg" style="box-shadow: none; border: none">
            {{ cartMsg }}
          </p>
        </div>

        <div class="actionButtons">
          <a @click="$router.push('/userLogin')" class="confirm"> Log In </a>
          <a @click="$router.push('/userRegistration')" class="confirm"
            >Registration</a
          >
        </div>
      </div>
    </div>
  </teleport>
  <w_footer />
</template>
<script>
import axios from "axios";
import load_ing from "../models/loading.vue";
import w_header from "./section/header.vue";

import constants from "../../config/constants";
import w_footer from "./section/footer.vue";

// import * as bootstrap from  "bootstrap";
export default {
  name: "book_list",
  components: {
    w_header,
    load_ing,
    w_footer,
  },
  data() {
    return {
      isLoading: false,
      second_books: {},
      Types: constants.BOOK_TYPES,
      typeValue: null,
      modalMsg: "",
      modal_view: false,
      searchType: "",
      cart: false,
      cartMsg: "",
      selectedPriceCategory: [],
    };
  },
  methods: {
    coverTypeChange(index, type) {
      this.selectedPriceCategory[index] = type;
      console.log(this.selectedPriceCategory[index]);
    },
    handle_cancel() {
      this.modal_view = false;
      this.cart = false;
    },
    async allBook() {
      try {
        const url = "/book/view?page=1";
        this.isLoading = true;
        const second_book_details = await axios.get(url);
        this.isLoading = false;
        this.second_books = second_book_details.data.book;
        console.log(this.second_books);
      } catch (error) {
        console.log(error);
      }
    },
    async search(key, value) {
      try {
        const body = {
          [key]: value,
        };

        const url = "/search/book";
        this.isLoading = true;
        const second_book_details = await axios.post(url, body);
        this.isLoading = false;
        this.second_books = second_book_details.data.book;
        console.log(this.second_books);
      } catch (error) {
        console.log(error);
      }
    },
    async checkAuthentication(bookId, qn) {
      const token = localStorage.getItem("Hakkani_token");
      const key = localStorage.getItem("key");
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };
      if (token != null && key == "kufan") {
        if (qn > 0) {
          const body = {
            amount: 1,
          };
          const url = `/book/view/addtocart/${bookId}`;
          const response = await axios.post(url, body, config);

          if (response.data.message === "This Book is already added in cart") {
            this.showError = true;
            this.errorMsg = "This Book is already added in cart..!!!";
          } else {
            this.isSuccess = true;
            this.modalMsg = "The book added to the cart successfully";
          }
        } else {
          this.showError = true;
          this.errorMsg = "The  book is out off stock..!!!";
        }
      } else {
        this.cart = true;
        this.cartMsg = "You have to login to add books into the cart.";
      }
    },
  },
  async mounted() {
    try {
      this.searchType = this.$route.params.key;
      this.typeValue = this.$route.params.value;

      const url = `/search/${this.searchType}`;

      if (this.searchType === "newarrival") {
        this.isLoading = true;
        const second_book_details = await axios.get(url);
        this.isLoading = false;
        this.second_books = second_book_details.data.book;
      } else if (this.searchType === "bestSeller") {
        const url1 = `/book/bestseller?page=1`;

        this.isLoading = true;
        const second_book_details = await axios.get(url1);
        this.isLoading = false;
        this.second_books = second_book_details.data.book;
      } else if (this.searchType === "allbook") {
        const body = {
          book: this.typeValue,
        };
        this.isLoading = true;
        const second_book_details = await axios.post(url, body);
        this.isLoading = false;
        this.second_books = [].concat(
          second_book_details.data.type,
          second_book_details.data.name,
          second_book_details.data.author,
          second_book_details.data.isbn_num,
          second_book_details.data.cover_type
        );
      } else {
        const body = {
          [this.searchType]: this.typeValue,
        };

        const url = "/search/book";
        this.isLoading = true;
        const second_book_details = await axios.post(url, body);
        this.isLoading = false;
        this.second_books = second_book_details.data.book;
      }

      console.log(this.second_books);
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>
/* sidebar start */
*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
}

h3 {
  font-size: 1.2375rem;
  color: black;
}

a {
  cursor: pointer;
  text-decoration: none !important;
  font-family: "Times New Roman", Times, serif;
}

li {
  list-style: none;
}

.wrapper {
  display: flex;
  width: 100%;
}

#sidebar {
  max-width: 264px;
  min-width: 264px;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 75px 32px rgba(180, 174, 171, 0.127) !important;
  z-index: 1111;
}

#sidebar.collapsed {
  margin-left: -264px;
}
.title_bk {
  background-color: #01b5fc;
  margin: 5px;
  border-radius: 3px;
  box-shadow: 0px 0px 75px 32px rgba(180, 174, 171, 0.127) !important;
}
.mainList {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 80%;
  /* overflow: hidden; */

  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

.sidebar-logo {
  padding: 1.15rem 1.5rem;
}

.sidebar-logo a {
  color: #000;
  font-size: 1.25rem;
  font-weight: 500;
}

.sidebar-nav {
  padding: 0;
}

.sidebar-header {
  color: #000;
  font-style: 0.75rem;
  padding: 1.5rem 1.5rem 0.375rem;
  font-size: 17px !important;
  font-weight: 400 !important;
}

a.sidebar-link {
  padding: 0.625rem 1.625rem;
  position: relative;
  display: block;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000;
}

/* .sidebar-item{
    margin-left: 10px;
} */

.sidebar-link[data-bs-toggle="collapse"]::after {
  border: solid;
  border-width: 0 0.075rem 0.075rem 0;
  content: "";
  display: inline-block;
  padding: 2px;
  position: absolute;
  right: 1.5rem;
  top: 1.4rem;
  transform: rotate(-135deg) !important;
  transition: all 0.2s ease-out;
  -webkit-transform: rotate(-135deg) !important;
  -moz-transform: rotate(-135deg) !important;
  -ms-transform: rotate(-135deg) !important;
  -o-transform: rotate(-135deg) !important;
  color: #000 !important;
}

.sidebar-link[data-bs-toggle="collapse"].collapsed::after {
  transform: rotate(45deg) !important;
  transition: all 0.2s ease-out;
  -webkit-transform: rotate(45deg) !important;
  -moz-transform: rotate(45deg) !important;
  -ms-transform: rotate(45deg) !important;
  -o-transform: rotate(45deg) !important;
}

.content {
  flex: 1;
  max-width: 100vw;
  width: 100vw;
}

@media (min-width: 768px) {
  .content {
    width: auto;
  }
}

.catagoryButton {
  background-color: rgb(207, 152, 85);
  border-radius: 20px;
}

.catagoryButton:hover {
  background-color: rgb(230, 192, 147);
  transition: 0.3s;
}

/* newbooks */

.row {
  padding-left: 20px;
}

.newBooksButton {
  background-color: rgb(207, 152, 85);
  border-radius: 0px !important;
}

.newBooksButton:hover {
  background-color: rgb(230, 192, 147);
  transition: 0.3s;
}

.newBooksPrice:hover {
  color: rgb(207, 152, 85);
}

.newBooksLabel {
  position: absolute;
  top: 12px;
  left: 12px;
  bottom: auto;
  right: auto;
  font-weight: 500;
  font-size: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80px !important;
  width: 65px !important;
  border-radius: 50rem;
  background-color: rgba(67, 168, 0, 0.8);
  color: #ffffff;
}

.newBooksLabel-2 {
  position: absolute;
  top: 18px;
  left: 0px;
  bottom: auto;
  right: auto;
  font-weight: 500;
  padding: 0 6px;
  font-size: 12px;
  line-height: 18px;
  background-color: rgba(168, 0, 0, 0.8);
  color: #ffffff;
  box-sizing: border-box;
}

.newBooks {
  max-height: 600px !important;
  max-width: 270px !important;
  border: 2px solid #e3e3e3 !important;
  border-radius: 5px !important;
}

.newBooksLabel-2-after {
  position: absolute;
  top: 0px;
  left: 100%;
  bottom: auto;
  right: auto;
  border-style: solid;
  border-width: 9px;
  width: 0;
  height: 0;
  border-color: rgba(168, 0, 0, 0.5) transparent rgba(168, 0, 0, 0.5)
    rgba(168, 0, 0, 0.8);
}

/* icon hover effect */
.newBooks:hover {
  transform: scale(1.01);
  transition: 0.4s;
  box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.2);
}

.newBooksBox .newBooksIcon {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.newBooksBox .newBooksIcon li {
  margin: 0 3px 0 0;
  /* display: inline-block;  */
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.newBooks:hover .newBooksIcon li {
  opacity: 1;
}

.newBooks .newBooksIcon li a {
  color: #0f070a99;
  background-color: #f6e8e8;
  font-size: 16px;
  line-height: 28px;
  margin: 5px;
  height: 30px;
  width: 30px;
  border: 2px solid transparent;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  transform: rotate(0);
  transition: all 0.3s;
}

.newBooks:hover .newBooksIcon li a {
  color: #0b0708;
  background: #f3eaea;
  border-color: #030202;
  /* transform: rotate(360deg); */
}

.shadow-lg {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5) !important;
}
/* NEW ARRIVALS BOOK  SECTION END*/

.bgPop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000ae;
}
.popBg {
  position: relative;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.popBg h2 {
  text-decoration: none;
  font-size: 18px;
  color: #000;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;
}

.popBg .popText {
  width: 600px;
  height: 40px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.1px;
  text-align: left;
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.popBg .popText span {
  text-align: left;
  width: 150px;
  padding: 2px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.1px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.popBg .popText p {
  margin: 0;
  color: #000;
  text-align: left;
  height: 40px;
  width: 450px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.1px;
  box-shadow: none;
}

.errorMsg {
  color: red;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.3px;
  box-shadow: none;
}
.successMsg {
  color: green;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.3px;
  box-shadow: none;
}
.popText input,
select {
  height: 35px;
  padding-left: 20px;
  width: 350px;
  box-shadow: 2px 2px 6px #000;
}

.stockBox {
  padding: 1px;
  margin: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.stockBox span {
  padding: 2px;
  font-size: 13px;
  font-weight: 600;
}

.popBg .actionButtons {
  margin: 2px;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.popBg .actionButtons .confirm {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: rgb(0, 165, 0);
  box-shadow: 1px 2px 7px #000;
  cursor: pointer;
  transition: 0.3s ease-in;
}

.popBg .actionButtons .Delete,
.cancel {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: red;
  box-shadow: 1px 2px 7px #000;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.popBg .actionButtons .confirm:hover {
  background-color: green;
}

.popBg .actionButtons .cancel:hover {
  background-color: red;
}
</style>
