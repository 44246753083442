<template>
  <w_header />
  <load_ing :isLoading="isLoading" />

  <div class="container" style="width: 1000px">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" class="h5 tw-200">Book cover</th>
          <th scope="col" class="h5 tw-500">Title</th>
          <th scope="col" class="tw-200">Unit Price</th>
          <th scope="col" class="tw-100">Quantity</th>
          <th scope="col" class="tw-200">Total Price</th>
          <!-- <th scope="col" class="tw-200"></th> -->
          <!-- <th scope="col"></th> -->
        </tr>
      </thead>

      <tbody>
        <tr v-for="data in cartList" :key="data.id">
          <td class="tw-200">
            <img
              :src="`${data.book.avatar}`"
              class="img-fluid rounded-3 cart-image"
              alt="Book"
              style="width: 100px; height: 120px"
            />
          </td>

          <td
            class="my-auto tw-500"
            style="display: flex; flex-direction: column; align-items: center"
          >
            <p>{{ data.book.name }}</p>
            <p>{{ data.book.author }}</p>
          </td>

          <td class="p-2 tw-200">{{ data.book.price }}</td>

          <td class="d-flex p-2 tw-100">
            <span class="m-auto p-1" id="output">{{ data.amount }}</span>
          </td>

          <td class="p-2 tw-200">{{ data.amount * data.book.price }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <teleport to="body">
    <div class="bgPop" v-if="modal_view">
      <div class="popBg">
        <h2>Massage</h2>

        <div class="popText">
          <p>{{ modalMsg }}</p>
        </div>

        <div class="actionButtons">
          <button @click="handle_cancel()" class="cancel">close</button>
        </div>
      </div>
    </div>
  </teleport>
  <w_footer />
</template>

<script>
import axios from "axios";
import load_ing from "../models/loading.vue";
import w_footer from "./section/footer.vue";
import w_header from "./section/header.vue";
export default {
  name: "userOrderPage",
  components: {
    w_header,
    w_footer,
    load_ing,
  },
  data() {
    return {
      district: "Dhaka",
      cartList: [],
      isLoading: false,
      modalMsg: "",
      modal_view: false,
    };
  },
  methods: {
    handle_cancel() {
      this.modal_view = false;
    },
  },
  computed: {
    calculateTotal() {
      let totalPrice = 0;
      this.cartList.forEach((item) => {
        totalPrice += item.book.price * item.amount;
      });
      return totalPrice;
    },
    shippingCharge() {
      if (this.district == "Dhaka") {
        const amount = 80;

        return parseInt(amount);
      } else {
        return 120.0;
      }
    },
  },
  async mounted() {
    try {
      const token = localStorage.getItem("Hakkani_token");
      this.config = {
        headers: { Authorization: `${token}` },
      };

      const url = `/cart/showcart/${this.$route.params.cart_id}`;
      this.isLoading = true;
      const response = await axios.get(url, this.config);
      this.isLoading = false;
      this.cartList = response.data.cartList;

      console.log(this.cartList);
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style>
/* 
  @media (min-width: 1025px) {
      .h-custom {
      min-height: 100vh !important;
      }
      .cart-image{
          width: 40px !important;
      }
      }
  
  
  
  
  @media (max-width: 600px) {
      .h-custom {
          min-height: 100vh !important;
          font-size: 12px !important;
  
      }
      .cart-image{
          width: 40px !important;
      }
  } */

.counter {
  border: 1px solid black !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.tw-100 {
  width: 100px !important;
}

.tw-200 {
  width: 200px !important;
}
.tw-500 {
  width: 500px !important;
}

/* .col-6 .card .counter .btn{
  
      border: none !important;    
  }
  } */
</style>
