<template>
  <w_header />

  <section>
    <div class="shadow-lg">
      <div class="section02">
        <div class="content02">
          <br />
          <h1 class="mx-2">Hakkani Publishers</h1>
          <div>
            <p class="text-justify mx-2">
              Hakkani Publishers, based in the vibrant city of Dhaka,
              Bangladesh, is a beacon of knowledge and education. We are a
              renowned publishing company with a rich legacy, dedicated to
              providing high-quality books and educational materials to readers
              of all ages. Our commitment to enlightenment and learning has been
              unwavering for over 137 years, making us a trusted name in the
              publishing industry. Overview of the Company’s History: Our
              journey began in the late 19th century, and since then, we have
              been at the forefront of cultivating minds and fostering a love
              for reading. Our history is not just a testament to our longevity
              but also a reflection of our adaptability, resilience, and
              relentless pursuit of excellence. We have witnessed the evolution
              of literature, embraced innovations, and have been a part of the
              intellectual growth of generations.
            </p>
          </div>
        </div>

        <div class="picture02">
          <div
            class="d-flex flex-column justify-content-center align-items-center m-3"
          >
            <div class="card col-6 p-1 m-2">
              <img
                src="../../assets/imgs/website/new arrival.jpg"
                class="card-img-top"
                alt="..."
              />
            </div>

            <div class="card col-6 p-1 m-2">
              <img
                src="../../assets/imgs/website/best.jpg"
                class="card-img-top"
                alt="history"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <w_footer />
</template>

<script>
import w_footer from "./section/footer.vue";
import w_header from "./section/header.vue";

export default {
  name: "ebookPage",
  components: {
    w_header,
    w_footer,
  },
};
</script>

<style></style>
