<template>
  <load_ing :isLoading="isLoading" />
  <error_Msg
    :showError="showError"
    :errorMsg="errorMsg"
    @close-error="showError = false"
  />

  <div class="main1">
    <div class="boxLeft">
      <div class="login_box">
        <img src="../../../assets/logo.png" alt="" />
        <h2>&nbsp;Admin Log In &nbsp;</h2>
        <form @submit.prevent="doLogin">
          <input
            type="text"
            placeholder="Enter Your User Name"
            v-model="emailLogin"
            required
          />
          <input
            type="password"
            placeholder="Enter Your Password"
            v-model="passwordLogin"
            required
          />
          <button type="submit">Log In</button>
        </form>
        <h3 @click="confirmation">Forget Password</h3>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import error_Msg from "../../models/error_show.vue";
import load_ing from "../../models/loading.vue";

export default {
  name: "logIn",
  components: {
    load_ing,
    error_Msg,
  },
  data() {
    return {
      isLoading: false,
      showError: false,
      errorMsg: "",
      registerActive: false,
      emailLogin: "",
      passwordLogin: "",
      emailReg: "",
      passwordReg: "",
      confirmReg: "",
      emptyFields: false,
    };
  },
  emits: ["closeError"],

  methods: {
    async doLogin() {
      try {
        const loginInfo = {
          email: this.emailLogin,
          password: this.passwordLogin,
        };
        this.isLoading = true;
        const response = await axios.post("/", loginInfo);
        this.isLoading = false;

        console.log(response);
        if (response.status == 200) {
          localStorage.setItem("Hakkani_token", response.data.token);
          localStorage.setItem("key", "apac");

          localStorage.setItem("users", JSON.stringify(response.data.user));
          this.$router.push("/dashboard");
        }
      } catch (error) {
        this.isLoading = false;
        this.showError = true;
        this.errorMsg = error;
      }
    },
  },
};
</script>

<style>
:root {
  --text-color: #fff;
  --black: #000;
}
.main1 {
  background-color: #0083c9;
  height: 1000px;
  padding: 0;
  border: none;
  display: flex;
  justify-content: left;
  align-items: center;
}

.boxLeft {
  background-color: #ddf2fd;
  min-height: 1000px;
  width: 50%;
}

.boxLeft .login_box {
  margin-left: 75%;
  margin-top: 20%;
  padding: 10px;
  background-color: #93d6fb;
  min-height: 500px;
  width: 320px;
  border-radius: 15px;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  box-shadow: -15px 15px 15px #0083c9;
}

.boxLeft .login_box img {
  height: 130px;
  padding: 5px;
  /* background-color: white ; */
  border-radius: 5px;
  /* box-shadow: 2px 2px 3px var(--black); */
  margin-bottom: -2px;
}

.boxLeft .login_box h2 {
  color: var(--black);
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 5px;
  border-bottom: 10px dotted var(--black);
}
.boxLeft .login_box h3 {
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  letter-spacing: 1.2px;
}

.boxLeft .login_box h3:hover {
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 4px;
  transition: 0.5s ease-in;
  cursor: pointer;
}

.boxLeft .login_box form {
  padding: 10px;
  margin: 2px;
  width: 300px;
  min-height: 200px;
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.boxLeft .login_box form input {
  width: 280px;
  height: 35px;
  padding-left: 15px;
  font-size: 14px;
  color: var(--black);
  border-radius: 20px;
  box-shadow: -3px 3px 6px #0083c9;
  letter-spacing: 2px;
}

.boxLeft .login_box form button {
  width: 120px;
  height: 40px;
  color: var(--text-color);
  font-size: 20px;
  font-weight: 500;
  background-color: #0083c9;
  box-shadow: -3px 3px 6px #ddf2fd;
  border-radius: 20px;
}

.boxLeft .login_box form button:hover {
  width: 180px;
  color: #0083c9;
  font-size: 22px;
  background-color: var(--text-color);
  box-shadow: -3px 3px 6px #0083c9;
  letter-spacing: 2px;
  transition: 0.5s ease-in;
  cursor: pointer;
}
p {
  line-height: 1rem;
}

.card {
  padding: 20px;
}

.form-group input {
  margin-bottom: 20px;
}

.login-page {
  align-items: center;
  display: flex;
  height: 100vh;
}
.wallpaper-login {
  background: url(https://images.pexels.com/photos/32237/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
    no-repeat center center;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.wallpaper-register {
  background: url(https://images.pexels.com/photos/533671/pexels-photo-533671.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)
    no-repeat center center;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

h1 {
  margin-bottom: 1.5rem;
}

.error {
  animation-name: errorShake;
  animation-duration: 0.3s;
}

@keyframes errorShake {
  0% {
    transform: translateX(-25px);
  }
  25% {
    transform: translateX(25px);
  }
  50% {
    transform: translateX(-25px);
  }
  75% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
