<template>
  <load_ing :isLoading="isLoading" />
  <div class="container-01">
    <side_bar />
    <div class="main_admin">
      <top_bar :pageTitle="pgTitle" />
      <div class="container mt-5">
        <div class="row d-flex justify-content-center">
          <div class="col-md-10">
            <div
              class="card p-3 py-4"
              style="box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08)"
            >
              <h5>An Easier way to find Books</h5>
              <div class="row g-3 mt-2">
                <div class="col-md-3">
                  <div>
                    <select class="btn btn-primary" v-model="key">
                      <option value="book">Keyword</option>
                      <option value="name">Title</option>
                      <option value="author">Author</option>
                      <option value="isbn_num">ISBN</option>
                      <option value="language">Language</option>
                      <option value="stock">Out of stock</option>
                      <option value="quantity">Quantity is greater than</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <input
                    v-model="search_text"
                    type="text"
                    class="form-control"
                    placeholder="Enter your value"
                  />
                </div>
                <div class="col-md-3">
                  <button
                    @click="search(key, search_text)"
                    class="btn btn-primary btn-block"
                  >
                    Search Results
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="col">
        <div
          class="card shadow"
          style="box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08)"
        >
          <div class="card-header border-0">
            <h3 class="mb-0">Searched Book Lists</h3>
          </div>
          <div class="table-responsive">
            <p class="d-flex justify-content-end mr-4 mt-2">
              <!-- <span class="b">Total Books : </span> <span>500</span> -->
            </p>
            <table class="table align-items-center table-flush">
              <thead class="thead-light">
                <tr>
                  <th scope="col" style="width: 250px">Title</th>
                  <th scope="col" style="width: 250px">Author</th>
                  <th scope="col" style="width: 150px">ISBN</th>
                  <th scope="col" style="width: 150px">Quantity</th>
                  <th scope="col" style="width: 150px">Price</th>
                  <th scope="col" style="width: 250px">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="book in second_books" v-bind:key="book._id">
                  <th scope="row" class="text-center" style="width: 250px">
                    {{ book.name }}
                  </th>
                  <td class="text-center" style="width: 250px">
                    {{ book.author }}
                  </td>
                  <td class="text-center" style="width: 150px">
                    {{ book.isbn_num }}
                  </td>
                  <td style="width: 150px">
                    <span v-if="book.p_quantity"
                      >P - {{ book.p_quantity }} <br
                    /></span>
                    <span v-if="book.h_quantity"
                      >H - {{ book.h_quantity }}</span
                    >
                  </td>
                  <td style="width: 150px">
                    <span v-if="book.p_price">{{ book.p_price }} <br /> </span>
                    <span v-if="book.h_price">{{ book.h_price }}</span>
                  </td>
                  <td class="text-right" style="width: 150px">
                    <router-link :to="'/update_book/' + book._id">
                      <button class="btn btn-success">Edit</button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="maxPage != 0"
            style="
              margin-top: 10px;
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
            "
          >
            <span class="boxDesign">Page :</span>
            <input
              type="text"
              class="boxDesign"
              style="width: 50px; margin-left: 20px"
              @keyup.enter="directPage()"
              v-model="directPageNumber"
            />
          </div>
          <div v-if="maxPage != 0" class="pagination">
            <button @click="updateCurrentPage(-1)" :disabled="currentPage <= 3">
              Previous
            </button>
            <h2 style="color: black">...</h2>
            <button v-if="currentPage - 2 > 0" @click="updateCurrentPage(-2)">
              {{ currentPage - 2 }}
            </button>
            <button v-if="currentPage - 1 > 0" @click="updateCurrentPage(-1)">
              {{ currentPage - 1 }}
            </button>

            <button class="currentPage">{{ currentPage }}</button>

            <button
              v-if="currentPage + 1 <= maxPage"
              @click="updateCurrentPage(+1)"
            >
              {{ currentPage + 1 }}
            </button>
            <button
              v-if="currentPage + 2 <= maxPage"
              @click="updateCurrentPage(+2)"
            >
              {{ currentPage + 2 }}
            </button>
            <h2 style="color: black">...</h2>

            <button
              @click="updateCurrentPage(+1)"
              :disabled="currentPage >= maxPage - 2"
            >
              Next
            </button>
          </div>
          <button @click="pdf(key, search_text)" class="btn btn-success">
            Ptint
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import load_ing from "../../models/loading.vue";
import side_bar from "../sections/sidebar.vue";
import top_bar from "../sections/topbar.vue";

export default {
  name: "book_search",
  components: {
    side_bar,
    top_bar,
    load_ing,
  },
  data() {
    return {
      pgTitle: "Search Books",
      isLoading: false,
      selectedStatus: "",
      search_text: "",
      isDropdownOpen: false,
      second_books: {},
      key: "book",

      currentPage: 1,
      pageSize: 15,
      bookLength: 0,
      maxPage: 0,
      directPageNumber: 1,
    };
  },
  methods: {
    async pdf(key, value) {
      try {
        const body = {
          [key]: value,
        };

        console.log(body);
        let url = `/search/book?page=${this.currentPage}`;
        if (key === "book") {
          url = "/search/allbook";
          this.isLoading = true;
          const second_book_details = await axios.post(url, body);
          this.isLoading = false;
          this.second_books = [].concat(
            second_book_details.data.type,
            second_book_details.data.name,
            second_book_details.data.author,
            second_book_details.data.isbn_num,
            second_book_details.data.cover_type
          );
        } else if (key === "stock") {
          const url1 = "/book/outofstock?page=1";
          this.isLoading = true;
          const response = await axios.get(url1);
          this.isLoading = false;
          this.second_books = response.data.book;
        } else {
          this.isLoading = true;
          const response = await axios.post(url, body);
          this.isLoading = false;
          this.second_books = response.data;
        }
        const pdf = "pdf";
        const hello = "hello";
        this.$router.push({
          name: "book_pdf",
          params: { pdf, hello },
          target: "_blank",
        });
        this.currentPage = this.second_books.currentPage;
        this.bookLength = this.second_books.totalCount;
        this.maxPage = Math.ceil(this.bookLength / this.pageSize);
      } catch (error) {
        console.log(error);
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      console.log(this.isDropdownOpen);
    },
    selectStatus(status) {
      this.selectedStatus = status;
      this.isDropdownOpen = false; // Close the dropdown after selection
    },
    updateCurrentPage(num) {
      this.currentPage = parseInt(this.currentPage) + parseInt(num);
      this.search(this.key, this.search_text);
      this.directPageNumber = parseInt(this.currentPage);
    },
    directPage() {
      if (this.directPageNumber > this.maxPage || this.directPageNumber <= 0) {
        this.showError = true;
        this.errorMsg = "No more page exist in this search results.";
      } else {
        this.currentPage = parseInt(this.directPageNumber);
        this.search(this.key, this.search_text);
      }
    },
    async search(key, value) {
      try {
        const body = {
          [key]: value,
        };

        console.log(body);
        let url = `/search/book?page=${this.currentPage}`;
        if (key === "book") {
          url = "/search/allbook";
          this.isLoading = true;
          const second_book_details = await axios.post(url, body);
          this.isLoading = false;

          this.currentPage = second_book_details.data.currentPage;
          this.bookLength = second_book_details.data.totalCount;
          this.second_books = [].concat(
            second_book_details.data.type,
            second_book_details.data.name,
            second_book_details.data.author,
            second_book_details.data.isbn_num,
            second_book_details.data.cover_type
          );
        } else if (key === "stock") {
          const url1 = "/book/outofstock?page=1";
          this.isLoading = true;
          const response = await axios.get(url1);
          this.isLoading = false;
          this.second_books = response.data.book;

          this.currentPage = response.data.currentPage;
          this.bookLength = response.data.totalCount;
        } else {
          console.log(body);
          this.isLoading = true;
          const response = await axios.post(url, body);
          this.isLoading = false;
          this.second_books = response.data.book;

          this.currentPage = response.data.currentPage;
          this.bookLength = response.data.totalCount;
        }
        console.log(this.second_books);
        this.maxPage = Math.ceil(this.bookLength / this.pageSize);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.book-title-cell {
  max-width: 200px; /* Adjust the width as needed */
  word-wrap: break-word;
}

/* Add or ensure that you have the necessary dropdown styles */
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.5rem 0;
}

.dropdown-menu.show {
  display: block;
}
</style>
