<template>
  <div class="container-01">
    <side_bar />
    <load_ing :isLoading="isLoading" />
    <error_show
      :showError="showError"
      :errorMsg="errorMsg"
      @close-error="showError = false"
    />
    <success_show
      :showMsg="isSuccess"
      :successMsg="msgS"
      @close-error="isSuccess = false"
    />
    <!-- ========================= Main ==================== -->
    <div class="main_admin">
      <top_bar :pageTitle="pgTitle" />

      <!-- Rest code will be added lower -->

      <div class="container">
        <div class="row mt-3">
          <form
            @submit.prevent="books_add"
            enctype="multipart/form-data"
            class="pt-4 col-5"
            style="
              margin-left: 30px;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            "
          >
            <div class="d-flex justify-content-between">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Title : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="name"
                  placeholder="Enter Book Titles"
                  style="padding-left: 4px; width: 350px"
                  required
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Author : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="author"
                  placeholder="Enter Book's Author's Name"
                  style="padding-left: 4px; width: 350px"
                  required
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Editor/Authors :
                </label>
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="editor"
                  placeholder="Enter Book's Editor's or others Author's Name"
                  style="padding-left: 4px; width: 350px"
                />
              </div>
            </div>

            <br />
            <div class="d-flex justify-content-between">
              <!-- btn-outline-dark -->

              <div>
                <label for="" class="form-label"
                  >Publication Type : &nbsp;<span
                    style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              <div>
                <select
                  class="btn btn-outline-primary"
                  style="padding-left: 4px; width: 350px"
                  v-model="p_type"
                  required
                >
                  <option value="" disabled>Select cover type</option>
                  <option value="Own Publication">Own Publication</option>
                  <option value="Joined Venchar Publication">
                    Joined Venchar Publication
                  </option>
                </select>
              </div>
            </div>

            <br />
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Publishers :
                </label>
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="publisher"
                  placeholder="Enter publisher's Name"
                  style="padding-left: 4px; width: 350px"
                />
              </div>
            </div>

            <br />
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Book Type : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <select
                  v-if="selectedType != 'Others**'"
                  v-model="bType"
                  style="padding-left: 4px; width: 350px"
                  required
                >
                  <option disabled value="">Select Book Type</option>
                  <option v-for="type in Types" :key="type" :value="type">
                    {{ type }}
                  </option>
                </select>
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Edition:</label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="edition"
                  placeholder="Enter Book's Edition"
                  style="padding-left: 4px; width: 350px"
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >ISBN : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="isbn_num"
                  placeholder="Enter ISBN number"
                  style="padding-left: 4px; width: 350px"
                  required
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >ISSN:</label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="issn_num"
                  placeholder="Enter ISSN Number"
                  style="padding-left: 4px; width: 350px"
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Short Description : &nbsp;<span
                    style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <textarea
                  v-model="short_description"
                  placeholder="Write some short description about the books"
                  rows="3"
                  style="padding-left: 4px; width: 350px"
                  required
                ></textarea>
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Long Description : &nbsp;<span
                    style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <textarea
                  v-model="long_description"
                  required
                  placeholder="Write a long description about the books"
                  rows="5"
                  style="padding-left: 4px; width: 350px"
                ></textarea>
              </div>
            </div>

            <br />
            <div class="d-flex justify-content-between">
              <!-- btn-outline-dark -->

              <div>
                <label for="" class="form-label"
                  >Cover Type : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              <div>
                <select
                  class="btn btn-outline-primary"
                  style="padding-left: 4px; width: 350px"
                  v-model="cover"
                  required
                >
                  <option value="" disabled>Select cover type</option>
                  <option value="Both">Both</option>
                  <option value="Paper Back">Paper Back</option>
                  <option value="Hard Cover">Hard Cover</option>
                </select>
              </div>
            </div>

            <br />

            <div
              v-if="cover !== 'Paper Back'"
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Hard Cover Price : &nbsp;<span
                    style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="hc_price"
                  @input="handleInput('Hard Cover Price', 'hc_price', $event)"
                  placeholder="Enter Hard Cover book price"
                  style="padding-left: 4px; width: 350px"
                  required
                />
              </div>
            </div>

            <br />

            <div
              v-if="cover !== 'Paper Back'"
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Hard Cover Quantity : &nbsp;<span
                    style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="hc_quantity"
                  @input="
                    handleInput('Hard Cover Quantity', 'hc_quantity', $event)
                  "
                  required
                  placeholder="Enter Hard Cover books quantity"
                  style="padding-left: 4px; width: 350px"
                />
              </div>
            </div>

            <br />

            <div
              v-if="cover !== 'Paper Back'"
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Hard Cover Discount : &nbsp;<span
                    style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="hc_discount"
                  @input="
                    handleInput('Hard Cover Discount', 'hc_discount', $event)
                  "
                  required
                  placeholder="Enter Hard Cover books Discount"
                  style="padding-left: 4px; width: 350px"
                />
              </div>
            </div>

            <br />

            <div
              v-if="cover !== 'Hard Cover'"
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Paper Back Price : &nbsp;<span
                    style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="pb_price"
                  @input="handleInput('Paper Back Price', 'pb_price', $event)"
                  placeholder="Enter Paper Back book price"
                  style="padding-left: 4px; width: 350px"
                  required
                />
              </div>
            </div>

            <br />

            <div
              v-if="cover !== 'Hard Cover'"
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Paper Back Quantity : &nbsp;<span
                    style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="pb_quantity"
                  @input="
                    handleInput('Paper Back Quantity', 'pb_quantity', $event)
                  "
                  required
                  placeholder="Enter Paper Back books quantity"
                  style="padding-left: 4px; width: 350px"
                />
              </div>
            </div>

            <br />
            <div
              v-if="cover !== 'Hard Cover'"
              class="d-flex justify-content-between align-items-center"
            >
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Paper Back Discount : &nbsp;<span
                    style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="pc_discount"
                  @input="
                    handleInput('Paper Back Discount', 'pc_discount', $event)
                  "
                  required
                  placeholder="Enter Paper Back books Discount"
                  style="padding-left: 4px; width: 350px"
                />
              </div>
            </div>

            <br />
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Page Number:</label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  v-model="pageNumber"
                  @input="handleInput('Page Number', 'pageNumber', $event)"
                  placeholder="Enter page number"
                  style="padding-left: 4px; width: 350px"
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between">
              <div>
                <label for="" class="form-label"
                  >Room Number : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              <div>
                <select
                  class="btn btn-outline-primary"
                  style="padding-left: 4px; width: 350px"
                  v-model="selectedRoom"
                  required
                >
                  <option value="" disabled>Select Room Number</option>
                  <option v-for="room in rooms" :key="room">{{ room }}</option>
                </select>
              </div>
            </div>
            <br />

            <div class="d-flex justify-content-between">
              <!-- btn-outline-dark -->

              <div>
                <label for="" class="form-label"
                  >Shelf Number : &nbsp;<span
                    style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              <div>
                <select
                  class="btn btn-outline-primary"
                  style="padding-left: 4px; width: 350px"
                  v-model="selectedShelf"
                  required
                >
                  <option value="" disabled>Select Shelf Number</option>
                  <option v-for="shelf in roomShelf" :key="shelf">
                    {{ shelf }}
                  </option>
                </select>
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between">
              <!-- btn-outline-dark -->

              <div>
                <label for="" class="form-label"
                  >Row Number : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              <div>
                <select
                  class="btn btn-outline-primary"
                  style="padding-left: 4px; width: 350px"
                  v-model="selectedRow"
                  required
                >
                  <option value="" disabled>Select Row Number</option>
                  <option v-for="row in rows" :key="row">
                    {{ row }}
                  </option>
                </select>
              </div>
            </div>

            <br />
            <div class="d-flex justify-content-between">
              <!-- btn-outline-dark -->

              <div>
                <label for="" class="form-label"
                  >Language : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              <div>
                <select
                  class="btn btn-outline-primary"
                  style="padding-left: 4px; width: 350px"
                  v-model="language"
                  required
                >
                  <option value="" disabled>Select Language</option>
                  <option value="English">English</option>
                  <option value="Bangla">Bangla</option>
                </select>
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Note:</label
                >
              </div>
              &nbsp;
              <div>
                <textarea
                  v-model="notes"
                  placeholder="Write note about the books"
                  rows="3"
                  style="padding-left: 4px; width: 400px"
                ></textarea>
              </div>
            </div>

            <br />
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="formFile" class="form-label"
                  >Picture : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div class="">
                <input
                  type="file"
                  name="image"
                  @change="handleFileChange"
                  style="padding-left: 4px; width: 350px"
                />
              </div>
            </div>

            <br />
            <br />

            <div class="d-grid gap-2">
              <button class="btn btn-outline-dark" type="submit">
                Add Books to stocks
              </button>
            </div>

            <br />
            <br />
          </form>

          <div
            class="col-6"
            style="
              height: 1300px;
              margin-left: 45px;
              margin-top: 0px;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            "
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Image</th>
                  <th scope="col">Title</th>
                  <th scope="col">Author</th>
                  <th scope="col">Price</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(book, index) in books" v-bind:key="book._id">
                  <th scope="row">{{ 15 * (currentPage - 1) + index + 1 }}</th>
                  <td>
                    <!-- Use a loading state or a placeholder image while the actual image is being fetched -->
                    <img
                      v-if="book.avatar"
                      :src="`${book.avatar}`"
                      alt="book image"
                      style="width: 30px; height: 30px; border-radius: 50%"
                    />

                    <!-- <img
                      v-if="book.avatar && book.avatar.contentType"
                      v-bind:src="`
                        ${getBase64Image(
                          book.avatar.data,
                          book.avatar.contentType
                        )}`"
                      alt="book image"
                      style="width: 30px; height: 30px; border-radius: 50%"
                    /> -->
                    <span v-else>No Picture</span>
                  </td>
                  <td>{{ book.name }}</td>
                  <td>{{ book.author }}</td>
                  <td>
                    {{ book.h_price }}<br />
                    {{ book.p_price }}
                  </td>
                  <td>
                    {{ book.h_quantity }} <br />
                    {{ book.p_quantity }}
                  </td>
                  <td>
                    <router-link :to="'/update_book/' + book._id">
                      <button class="btn btn-success">Edit</button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="maxPage != 0"
              style="
                margin-top: 10px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
              "
            >
              <span class="boxDesign">Page :</span>
              <input
                type="text"
                class="boxDesign"
                style="width: 50px; margin-left: 20px"
                @keyup.enter="directPage()"
                v-model="directPageNumber"
              />
            </div>
            <div v-if="maxPage != 0" class="pagination">
              <button
                @click="updateCurrentPage(-1)"
                :disabled="currentPage <= 3"
              >
                Previous
              </button>
              <h2 style="color: black">...</h2>
              <button v-if="currentPage - 2 > 0" @click="updateCurrentPage(-2)">
                {{ currentPage - 2 }}
              </button>
              <button v-if="currentPage - 1 > 0" @click="updateCurrentPage(-1)">
                {{ currentPage - 1 }}
              </button>

              <button class="currentPage">{{ currentPage }}</button>

              <button
                v-if="currentPage + 1 <= maxPage"
                @click="updateCurrentPage(+1)"
              >
                {{ currentPage + 1 }}
              </button>
              <button
                v-if="currentPage + 2 <= maxPage"
                @click="updateCurrentPage(+2)"
              >
                {{ currentPage + 2 }}
              </button>
              <h2 style="color: black">...</h2>

              <button
                @click="updateCurrentPage(+1)"
                :disabled="currentPage >= maxPage - 2"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import constants from "../../../config/constants";
import error_show from "../../models/error_show.vue";
import load_ing from "../../models/loading.vue";
import success_show from "../../models/success_show.vue";
import side_bar from "../sections/sidebar.vue";
import top_bar from "../sections/topbar.vue";
export default {
  name: "add-book",
  components: {
    side_bar,
    top_bar,
    error_show,
    load_ing,
    success_show,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pgTitle: "Add Books",
      isLoading: false,
      showError: false,
      isSuccess: false,
      selectedRoom: "",
      selectedShelf: "",
      selectedRow: "",
      rooms: constants.roomNumber,
      roomShelf: constants.shelfNumber,
      rows: constants.rowNumber,
      msgS: "",
      errorMsg: "",
      cover: "",
      Types: constants.BOOK_TYPES,
      selfNumbers: [],
      name: "",
      author: "",
      issn_num: "",
      isbn_num: "",
      short_description: "",
      long_description: "",
      avatar: "",
      bType: "",
      books: {},
      selectedType: "",
      img: null,
      notes: "",
      pageNumber: "",
      edition: "",
      language: "",
      selfNumber: "",
      roomNumber: "",
      rowNummber: "",
      publisher: "Hakkani Publishers",
      p_type: "Own Publication",
      pb_price: null,
      pb_quantity: null,
      hc_price: null,
      hc_quantity: null,
      hc_discount: null,
      pc_discount: null,
      discount: 0,
      editor: null,

      currentPage: 1,
      pageSize: 15,
      bookLength: 0,
      maxPage: 0,
      directPageNumber: 1,
    };
  },
  methods: {
    updateCurrentPage(num) {
      this.currentPage = parseInt(this.currentPage) + parseInt(num);
      this.book_search();
      this.directPageNumber = parseInt(this.currentPage);
    },
    directPage() {
      if (this.directPageNumber > this.maxPage || this.directPageNumber <= 0) {
        this.showError = true;
        this.errorMsg = "No more page exist in this search results.";
      } else {
        this.currentPage = parseInt(this.directPageNumber);
        this.book_search();
      }
    },
    handleInput(field, model, event) {
      const value = event.target.value;

      if (!Number.isInteger(Number(value))) {
        this.showError = true;
        this.errorMsg = field + " Must be number";
        this[model] = null;
      }
    },
    handleFileChange(event) {
      this.avatar = event.target.files[0];
      console.log(event.target.files[0]);

      // const formData = new FormData();
      // formData.append("image", this.avatar);

      // console.log("Form Data Content:", formData.get("image"));
      // console.log("Full FormData Object:", formData);
      const reader = new FileReader();

      reader.onload = () => {
        this.isLoading = true;
        this.img = reader.result;
        console.log(this.img);
        this.isLoading = false;
      };

      reader.readAsDataURL(this.avatar);
    },
    getBase64Image(imageData, type1) {
      var imageDataUrl = "";
      console.log(imageData.data);
      const uint8Array = new Uint8Array(imageData.data);

      // Create a Blob from Uint8Array
      const blob = new Blob([uint8Array], { type: `${type1}` });

      this.imageDataUrl = URL.createObjectURL(blob);
      console.log(blob);
      return imageDataUrl;
    },
    async book_search() {
      try {
        const token = localStorage.getItem("Hakkani_token");
        const config = {
          headers: { Authorization: `${token}` },
        };
        console.log(config);
        const url = `/search/newarrival?page=${this.currentPage}`;
        this.isLoading = true;
        const book_details = await axios.get(url);
        this.isLoading = false;

        this.currentPage = book_details.data.currentPage;
        this.bookLength = book_details.data.totalCount;
        this.maxPage = Math.ceil(this.bookLength / this.pageSize);
        this.books = book_details.data.book;
        console.log(book_details);
      } catch (error) {
        this.isLoading = false;
        this.showError = true;
        this.errorMsg = error.response.data.message;
      }
    },
    async books_add() {
      try {
        const token = localStorage.getItem("Hakkani_token");
        const config = {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const bookInfo = {
          name: this.name,
          author: this.author,
          type: this.bType,
          cover_type: this.cover,
          isbn_num: this.isbn_num,
          issn_num: this.issn_num,
          price: this.price,
          short_description: this.short_description,
          long_description: this.long_description,
          quantity: this.quantity,
          edition: this.edition,
          pageNumber: this.pageNumber,
          language: this.language,
          note: this.notes,
          room_no: this.selectedRoom,
          self_no: this.selectedShelf,
          row: this.selectedRow,
          avatar: this.img,
          editor: this.editor,
          publication_type: this.p_type,
          publisher: this.publisher,
          h_price: this.hc_price,
          h_quantity: this.hc_quantity,
          p_price: this.pb_price,
          h_discount: this.hc_discount,
          p_discount: this.pc_discount,
          p_quantity: this.pb_quantity,
        };

        console.log(bookInfo);
        this.isLoading = true;
        const response = await axios.post("/book/add", bookInfo, config);
        this.isLoading = false;
        console.log(response);
        if (response.data === "This book is already added") {
          this.showError = true;
          this.errorMsg = response.data;
        } else {
          this.msgS = "Books Added succesfully";
          this.isSuccess = true;
        }
      } catch (error) {
        this.isLoading = false;
        this.showError = true;
        this.errorMsg = error.response.data.message;
      }
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      const book_details = await axios.get("/search/newarrival?page=1");
      this.isLoading = false;
      this.books = book_details.data.book;
      this.currentPage = book_details.data.currentPage;
      this.bookLength = book_details.data.totalCount;
      this.maxPage = Math.ceil(this.bookLength / this.pageSize);
      console.log(book_details);
    } catch (error) {
      console.log(error);
      this.isLoading = false;
      this.showError = true;
      this.errorMsg = error.response.data.errors.common.msg;
    }
  },
  computed: {
    getImageSource() {
      if (this.book.avatar && this.book.avatar.contentType) {
        const base64avatar = this.book.avatar.data.toString("base64");
        return `data:${this.book.avatar.contentType};base64,${base64avatar}`;
      }
      return "../../../assets/imgs/customer01.jpg";
    },
  },
};
</script>

<style>
/* custom css */
</style>
