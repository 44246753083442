<template>
  <div class="container-10">
    <side_bar />

    <!-- ========================= Main ==================== -->
    <div class="main_admin">
      <top_bar :pageTitle="pgTitle" />
      <!-- ======================= Cards ================== -->
      <div class="cardBox">
        <div class="card1">
          <div>
            <div class="numbers">{{ books.book }}</div>
            <div class="cardName">Total Books</div>
          </div>

          <div class="iconBx">
            <ion-icon name="eye-outline"></ion-icon>
          </div>
        </div>

        <div class="card1">
          <div>
            <div class="numbers">{{ books.user }}</div>
            <div class="cardName">Total Customers</div>
          </div>

          <div class="iconBx">
            <ion-icon name="cart-outline"></ion-icon>
          </div>
        </div>

        <div class="card1">
          <div>
            <div class="numbers">{{ books.order }}</div>
            <div class="cardName">Total Orders</div>
          </div>

          <div class="iconBx">
            <ion-icon name="chatbubbles-outline"></ion-icon>
          </div>
        </div>

        <div class="card1">
          <div>
            <div class="numbers">${{ totalAmount }}</div>
            <div class="cardName">Total Amount</div>
          </div>

          <div class="iconBx">
            <ion-icon name="cash-outline"></ion-icon>
          </div>
        </div>
      </div>

      <!-- ================ Order Details List ================= -->
      <div class="details">
        <div class="recentOrders">
          <div class="cardHeader">
            <h2>Recent Orders</h2>
            <a href="#" class="btn">View All</a>
          </div>

          <table>
            <thead>
              <tr>
                <td>Sl.</td>
                <td>Order ID</td>
                <td>Amount</td>
                <td>Payment</td>
                <td>Status</td>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(order, index) in allOrder" v-bind:key="order._id">
                <td>{{ index + 1 }}</td>
                <td>{{ order._id }}</td>
                <td>{{ order.total_amount }}</td>
                <td>{{ order.paymentStatus }}</td>
                <td v-if="order.orderStatus === 'delivered'">
                  <span class="status delivered"> Delivered</span>
                </td>
                <td v-if="order.orderStatus === 'pending'">
                  <span class="status pending">Pending</span>
                </td>
                <td v-if="order.orderStatus === 'cancel'">
                  <span class="status return">Cancel</span>
                </td>
                <td v-if="order.orderStatus === 'inProgress'">
                  <span class="status inProgress">In Progress</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- ================= New Customers ================ -->
        <div class="recentCustomers">
          <div class="cardHeader">
            <h2>Best Sellling Book</h2>
          </div>

          <table>
            <tr>
              <td width="60px">
                <div class="imgBx">
                  <img src="../../../assets/imgs/customer01.jpg" alt="" />
                </div>
              </td>
              <td>
                <h4>
                  David <br />
                  <span>Italy</span>
                </h4>
              </td>
            </tr>

            <tr>
              <td width="60px">
                <div class="imgBx">
                  <img src="../../../assets/imgs/customer01.jpg" alt="" />
                </div>
              </td>
              <td>
                <h4>
                  Amit <br />
                  <span>India</span>
                </h4>
              </td>
            </tr>

            <tr>
              <td width="60px">
                <div class="imgBx">
                  <img src="../../../assets/imgs/customer01.jpg" alt="" />
                </div>
              </td>
              <td>
                <h4>
                  David <br />
                  <span>Italy</span>
                </h4>
              </td>
            </tr>

            <tr>
              <td width="60px">
                <div class="imgBx">
                  <img src="../../../assets/imgs/customer01.jpg" alt="" />
                </div>
              </td>
              <td>
                <h4>
                  Amit <br />
                  <span>India</span>
                </h4>
              </td>
            </tr>

            <tr>
              <td width="60px">
                <div class="imgBx">
                  <img src="../../../assets/imgs/customer01.jpg" alt="" />
                </div>
              </td>
              <td>
                <h4>
                  David <br />
                  <span>Italy</span>
                </h4>
              </td>
            </tr>

            <tr>
              <td width="60px">
                <div class="imgBx">
                  <img src="../../../assets/imgs/customer01.jpg" alt="" />
                </div>
              </td>
              <td>
                <h4>
                  Amit <br />
                  <span>India</span>
                </h4>
              </td>
            </tr>

            <tr>
              <td width="60px">
                <div class="imgBx">
                  <img src="../../../assets/imgs/customer01.jpg" alt="" />
                </div>
              </td>
              <td>
                <h4>
                  David <br />
                  <span>Italy</span>
                </h4>
              </td>
            </tr>

            <tr>
              <td width="60px">
                <div class="imgBx">
                  <img src="../../../assets/imgs/customer01.jpg" alt="" />
                </div>
              </td>
              <td>
                <h4>
                  Amit <br />
                  <span>India</span>
                </h4>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import side_bar from "../sections/sidebar.vue";
import top_bar from "../sections/topbar.vue";
export default {
  name: "dash-board",
  components: {
    side_bar,
    top_bar,
  },
  data() {
    return {
      pgTitle: "Dashboard",
      books: {},
      totalAmount: 0,
      allOrder: {},
    };
  },
  async mounted() {
    try {
      const token = localStorage.getItem("Hakkani_token");
      const config = {
        headers: { Authorization: `${token}` },
      };
      this.isLoading = true;
      const response = await axios.get("/dashboard/total", config);
      this.isLoading = false;
      this.books = response.data;
      this.totalAmount = response.data.amount.reduce(
        (sum, amountObj) => sum + amountObj.totalAmount,
        0
      );

      const url = "/order/admin?page=1";

      this.isLoading = true;
      const order = await axios.get(url, config);
      this.isLoading = false;

      this.allOrder = order.data.order;
      console.log(this.allOrder);
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>
@import "../../../../src/assets/css/style.css";
</style>
