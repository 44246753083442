<template>
  <div class="topbar">
    <div class="toggle" @click="toggleMenu">
      <img
        style="height: 40px; color: white"
        src="../../../assets/imgs/icons/menu-outline.svg"
        alt="Menu Icon"
      />
      <p class="page_title">{{ pageTitle }}</p>
    </div>

    <div class="user">
      <img src="../../../assets/imgs/customer02.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "top-bar",
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMenuActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
  },
  watch: {
    isMenuActive() {
      const navigation = document.querySelector(".navigation");
      const main = document.querySelector(".main");

      if (navigation && main) {
        navigation.classList.toggle("active", this.isMenuActive);
        main.classList.toggle("active", this.isMenuActive);
      }
    },
  },
  mounted() {
    const token = localStorage.getItem("Hakkani_token");
    const key = localStorage.getItem("key");
    if (token == null || key != "apac") {
      this.$router.push("/admin");
    }
  },
};
</script>

<style>
@import "../../../../src/assets/css/style.css";
</style>
