<template>
  <div class="container-01">
    <side_bar />
    <load_ing :isLoading="isLoading" />
    <error_show
      :showError="showError"
      :errorMsg="errorMsg"
      @close-error="showError = false"
    />
    <success_show
      :showMsg="isSuccess"
      :successMsg="msgS"
      @close-error="isSuccess = false"
    />
    <!-- ========================= Main ==================== -->
    <div class="main_admin">
      <top_bar :pageTitle="pgTitle" />

      <div class="boxCentre" style="margin-top: 50px">
        <div class="userForm">
          <form action="">
            <div class="reportBox"></div>
            <div class="reportBox"></div>

            <div class="reportBox">
              <h3>1. Total Book List</h3>
              <router-link to="/re_book_list">Report</router-link>
            </div>

            <div class="reportBox">
              <h3>2. Number of Book Added by Employee</h3>
              <router-link to="/re_book_list">Report</router-link>
            </div>

            <div class="reportBox">
              <h3>3. Stock Out List</h3>
              <router-link to="/re_book_list">Report</router-link>
            </div>

            <div class="reportBox">
              <h3>4. In stock List</h3>
              <router-link to="/re_book_list">Report</router-link>
            </div>

            <div class="reportBox">
              <h3>5. Total Empployee List</h3>
              <router-link to="/re_book_list">Report</router-link>
            </div>

            <div class="reportBox"></div>
            <div class="reportBox"></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import error_show from "../../models/error_show.vue";
import load_ing from "../../models/loading.vue";
import success_show from "../../models/success_show.vue";
import side_bar from "../sections/sidebar.vue";
import top_bar from "../sections/topbar.vue";

export default {
  name: "re-port",
  components: {
    load_ing,
    error_show,
    success_show,
    side_bar,
    top_bar,
  },
  data() {
    return {
      isLoading: false,
      showError: false,
      errorMsg: "",
      pgTitle: "Reports",
    };
  },

  methods: {
    async doLogin() {
      try {
        const loginInfo = {
          email: this.emailLogin,
          password: this.passwordLogin,
        };
        this.isLoading = true;
        const response = await axios.post("/", loginInfo);
        this.isLoading = false;

        console.log(response);
        if (response.status == 200) {
          localStorage.setItem("Hakkani_token", response.data.token);
          localStorage.setItem("key", "apac");

          localStorage.setItem("users", JSON.stringify(response.data.user));
          this.$router.push("/dashboard");
        }
      } catch (error) {
        this.isLoading = false;
        this.showError = true;
        this.errorMsg = error;
      }
    },
  },
};
</script>

<style>
.reportBox {
  margin-top: 8px;
  margin-bottom: 4px;
  padding: 5px;
  width: 85%;
  border-radius: 25px;
  padding-left: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  flex-direction: row;
}

.reportBox h3 {
  margin: 1px;
  margin-left: 10px;
  height: 40px;
  padding-left: 10px;
  width: 60%;
  border-radius: 5px;
  color: #fff;
  background-color: #2e6bf8e6;
  box-shadow: inset 3px 3px 6px var(--black);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
}

.reportBox a {
  margin: 0;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding: 3px 20px;
  background-color: #2e6bf8e6;
  border-radius: 10px;
  transition: 0.5s ease-in;
}
.reportBox a:hover {
  background-color: #053cbde6;
  color: var(--text-color);
}
</style>
