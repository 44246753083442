import axios from 'axios';
import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import config from './config/config';
import router from './router';

// import "./assets/bootstrap/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";

axios.defaults.baseURL = config.baseUrl;
// const store = createStore({
//     state: {
//         token: null,
//         mainUser: null
//       },
//   mutations: {
//     setToken(state, token) {
//         state.token = token
//       },
//       setUser(state, mainUser) {
//         state.mainUser = mainUser
//       }
//   },
//   getters: {
//     getToken: state => state.token,
//     getUser: state => state.mainUser
//   }
// });
const store = createStore({
  state: {
    token: null,
    userId: null,
    key: null
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setKey(state, key) {
      state.key = key;
    }
  },
  actions: {
    login({ commit }, { token, userId ,key}) {
      commit('setToken', token);
      commit('setUserId', userId);
      commit('setKey', key);
    },
    logout({ commit }) {
      commit('setToken', null);
      commit('setUserId', null);
      commit('setKey', null);
   }
  },
  getters: {
    getToken: state => state.token,
    getUserId: state => state.userId,
    getKey: state => state.key
  }
});
createApp(App)
  .use(router, axios)
  .use(store)
  .mount('#app');

