<template>
  <w_header />
  <load_ing :isLoading="isLoading" />

  <section>
    <div class="shadow-lg">
      <div class="section02">
        <div class="content02">
          <br />
          <h1 class="mx-2">E-Preriodicals</h1>
          <div>
            <p class="text-justify mx-2">
              Embark on an enlightening journey of intellectual exploration with
              Hakkani Publishers' exceptional collection of e-periodicals,
              meticulously curated through partnerships with renowned publishers
              and scholarly societies including Springer, IEEE, Taylor &
              Francis, Sage, and Wiley Online Library. Our diverse selection
              covers an extensive range of disciplines, offering access to
              groundbreaking research, insightful analyses, and
              thought-provoking articles. Whether you're a student, researcher,
              or professional, our e-periodicals cater to your specific
              interests and needs, providing a wealth of information tailored to
              enrich your academic and professional pursuits. Stay ahead of the
              curve with our cutting-edge content, which reflects the latest
              advancements and discoveries shaping the landscape of academia and
              beyond. With user-friendly interfaces and customizable search
              options, navigating our collection is seamless and intuitive,
              ensuring that you can easily find the information you seek. Join
              us in unlocking the boundless potential of knowledge and
              redefining the way we engage with scholarly discourse in the
              digital age
            </p>

            <p class="text-justify mx-2">
              Experience the richness of knowledge and the thrill of discovery
              with Hakkani Publishers' e-periodicals. Join us in exploring the
              vast expanse of human understanding and unlocking the potential of
              information in the digital age. With our commitment to excellence
              and innovation, we invite you to embark on a journey of lifelong
              learning and intellectual growth.
            </p>
          </div>
        </div>

        <div class="picture02">
          <div
            class="d-flex flex-column justify-content-center align-items-center m-3"
          >
            <div class="card col-6 p-1 m-2">
              <img
                src="../../assets/imgs/website/e-periodicals.gif"
                class="card-img-top"
                alt="..."
              />
            </div>

            <div class="card col-6 p-1 m-2">
              <img
                src="../../assets/imgs/website/ebook.jpg"
                class="card-img-top"
                alt="history"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <w_footer />
</template>

<script>
import load_ing from "../models/loading.vue";
import w_header from "./section/header.vue";

import w_footer from "./section/footer.vue";
export default {
  name: "eperiodicalPage",
  components: {
    w_header,
    load_ing,
    w_footer,
  },
  data() {
    return {
      district: "Dhaka",
      cartList: [],
      isLoading: false,
      modalMsg: "",
      modal_view: false,
    };
  },
};
</script>

<style></style>
