<template>
  <div class="container-01">
    <!-- ========================= Main ==================== -->
    <div class="main">
      <top_bar :pageTitle="pgTitle" />

      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/pixeden-stroke-7-icon@1.2.3/pe-icon-7-stroke/dist/pe-icon-7-stroke.min.css"
      />
      <div class="container padding-bottom-3x mb-1">
        <div class="card mb-3">
          <div class="p-4 text-center text-white text-lg bg-dark rounded-top">
            <span class="text-uppercase">Tracking Order No - </span
            ><span class="text-medium">34VB5540K83</span>
          </div>
          <div
            class="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2"
            style="background-color: #f5f5f5"
          >
            <div class="w-100 text-center py-1 px-2">
              <span class="text-medium">Shipped Via:</span> UPS Ground
            </div>
            <div class="w-100 text-center py-1 px-2">
              <span class="text-medium">Status:</span> Checking Quality
            </div>
            <div class="w-100 text-center py-1 px-2">
              <button type="button" class="btn btn-outline-dark">Accept</button>
            </div>
          </div>
          <div class="card-body">
            <div
              class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x"
            >
              <div class="step completed">
                <div class="step-icon-wrap">
                  <div class="step-icon"><i class="pe-7s-cart"></i></div>
                </div>
                <h4 class="step-title">Confirmed Order</h4>
              </div>
              <div class="step completed">
                <div class="step-icon-wrap">
                  <div class="step-icon"><i class="pe-7s-config"></i></div>
                </div>
                <h4 class="step-title">Processing Order</h4>
              </div>
              <div class="step completed">
                <div class="step-icon-wrap">
                  <div class="step-icon"><i class="pe-7s-medal"></i></div>
                </div>
                <h4 class="step-title">Quality Check</h4>
              </div>
              <div class="step">
                <div class="step-icon-wrap">
                  <div class="step-icon"><i class="pe-7s-car"></i></div>
                </div>
                <h4 class="step-title">Product Dispatched</h4>
              </div>
              <div class="step">
                <div class="step-icon-wrap">
                  <div class="step-icon"><i class="pe-7s-home"></i></div>
                </div>
                <h4 class="step-title">Product Delivered</h4>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center"
        >
          <div class="custom-control custom-checkbox mr-3">
            <input
              class="custom-control-input"
              type="checkbox"
              id="notify_me"
              checked=""
            />
            <label class="custom-control-label" for="notify_me"
              >Notify me when order is delivered</label
            >
          </div>
          <div class="text-left text-sm-right">
            <a
              class="btn btn-outline-primary btn-rounded btn-sm"
              href="orderDetails"
              data-toggle="modal"
              data-target="#orderDetails"
              >View Order Details</a
            >
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="container">
          <!-- Title -->

          <div class="d-flex justify-content-between align-items-center py-3">
            <h2 class="h5 mb-0">
              <a href="#" class="text-muted"></a> Order #16123222
            </h2>
          </div>

          <!-- Main content -->

          <div class="row">
            <div class="col-lg-8">
              <!-- Details -->
              <div class="card mb-4">
                <div class="card-body">
                  <div class="mb-3 d-flex justify-content-between">
                    <div>
                      <span class="me-3">22-11-2021</span> &nbsp;
                      <span class="me-3">#16123222</span> &nbsp;
                      <span class="me-3">Visa -1234</span> &nbsp;
                      <span class="badge bg-info" style="border-radius: 10px"
                        >SHIPPING</span
                      >
                    </div>
                    <div class="d-flex">
                      <button
                        class="btn btn-link p-0 me-3 d-none d-lg-block btn-icon-text"
                      >
                        <i class="bi bi-download"></i>
                        <span class="text">Invoice</span>
                      </button>
                      <div class="dropdown">
                        <button
                          class="btn btn-link p-0 text-muted"
                          type="button"
                          data-bs-toggle="dropdown"
                        >
                          <i class="bi bi-three-dots-vertical"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <a class="dropdown-item" href="#"
                              ><i class="bi bi-pencil"></i> Edit</a
                            >
                          </li>
                          <li>
                            <a class="dropdown-item" href="#"
                              ><i class="bi bi-printer"></i> Print</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <div class="d-flex mb-2">
                            <div class="flex-shrink-0">
                              <img
                                src="https://www.bootdey.com/image/280x280/87CEFA/000000"
                                alt=""
                                width="35"
                                class="img-fluid"
                              />
                            </div>
                            <div class="flex-lg-grow-1 ms-3">
                              <h6 class="small mb-0 mx-3">
                                <a href="#" class="text-reset mx-3"
                                  >Harry Porter</a
                                >
                              </h6>
                              <span class="small mx-3">Author: Joy Black</span>
                            </div>
                          </div>
                        </td>
                        <td>1</td>
                        <td class="text-end">$79.99</td>
                      </tr>
                      <tr>
                        <td>
                          <div class="d-flex mb-2">
                            <div class="flex-shrink-0">
                              <img
                                src="https://www.bootdey.com/image/280x280/FF69B4/000000"
                                alt=""
                                width="35"
                                class="img-fluid"
                              />
                            </div>
                            <div class="flex-lg-grow-1 ms-3">
                              <h6 class="small mb-0 mx-3">
                                <a href="#" class="text-reset"> Harry Potter</a>
                              </h6>
                              <span class="small mx-3"
                                >Author: Walter White</span
                              >
                            </div>
                          </div>
                        </td>
                        <td>1</td>
                        <td class="text-end">$79.99</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2" style="text-align: right">Subtotal</td>
                        <td class="text-end">$159,98</td>
                      </tr>
                      <tr>
                        <td colspan="2" style="text-align: right">Shipping</td>
                        <td class="text-end">$20.00</td>
                      </tr>
                      <tr>
                        <td colspan="2" style="text-align: right">
                          Discount (Code: NEWYEAR)
                        </td>
                        <td class="text-danger text-end">-$10.00</td>
                      </tr>
                      <tr class="fw-bold">
                        <td colspan="2" style="text-align: right">TOTAL</td>
                        <td class="text-end">$169,98</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <!-- Payment -->

              <div class="card mb-4">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <h3 class="h6">Payment Method</h3>
                      <p>
                        BKash<br />
                        Total: $169,98 &nbsp;
                        <span
                          class="badge bg-success rounded-pill"
                          style="border-radius: 10px"
                          >PAID</span
                        >
                      </p>
                    </div>
                    <div class="col-lg-6">
                      <h3 class="h6">Billing address</h3>
                      <address>
                        <strong>John Doe</strong><br />
                        1355 Market St, Suite 900<br />
                        San Francisco, CA 94103<br />
                        <abbr title="Phone">P:</abbr> (123) 456-7890
                      </address>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <!-- Customer Notes -->

              <div class="card mb-4">
                <div class="card-body">
                  <h3 class="h6">Customer Notes</h3>
                  <p>
                    Sed enim, faucibus litora velit vestibulum habitasse. Cras
                    lobortis cum sem aliquet mauris rutrum. Sollicitudin. Morbi,
                    sem tellus vestibulum porttitor.
                  </p>
                </div>
              </div>

              <div class="card mb-4">
                <div class="card-body">
                  <h3 class="h6">Customer Notes</h3>
                  <p>
                    Sed enim, faucibus litora velit vestibulum habitasse. Cras
                    lobortis cum sem aliquet mauris rutrum. Sollicitudin. Morbi,
                    sem tellus vestibulum porttitor.
                  </p>
                </div>
              </div>

              <div class="card mb-4">
                <!-- Shipping information -->

                <div class="card-body">
                  <h3 class="h6">Shipping Information</h3>
                  <strong>FedEx</strong> &nbsp;
                  <span
                    ><a
                      href="#"
                      class="text-decoration-underline"
                      target="_blank"
                      >FF1234567890</a
                    >
                    <i class="bi bi-box-arrow-up-right"></i>
                  </span>
                  <hr />
                  <h3 class="h6">Address</h3>
                  <address>
                    <strong>John Doe</strong><br />
                    1355 Market St, Suite 900<br />
                    San Francisco, CA 94103<br />
                    <abbr title="Phone">P:</abbr> (123) 456-7890
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import top_bar from "../sections/topbar.vue";

export default {
  name: "single_order",
  components: {
    top_bar,
  },
  data() {
    return {
      pgTitle: "order details",
    };
  },
};
</script>

<style>
.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center;
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 80px;
  text-align: center;
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #aec1d0;
  content: "";
  z-index: 1;
}

.steps .step .step-icon-wrap::before {
  left: 0;
}

.steps .step .step-icon-wrap::after {
  right: 0;
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #000000;
  font-size: 38px;
  line-height: 81px;
  z-index: 5;
}

.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.steps .step:first-child .step-icon-wrap::before {
  display: none;
}

.steps .step:last-child .step-icon-wrap::after {
  display: none;
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: #000000;
}

.steps .step.completed .step-icon {
  border-color: #0da9ef;
  background-color: #0da9ef;
  color: #fff;
}

@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}

.card {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%) !important;
}
.card {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-color: #fff !important;
  background-clip: border-box !important;
  border: 0 solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 1rem !important;
}
.text-reset {
  --bs-text-opacity: 1 !important;
  color: inherit !important;
}
</style>
