<template>
  <div class="s_loading" v-if="isSecLoading">
    <div class="s_loader">
      <div class="s_dot1"></div>
      <div class="s_dot2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sec_load_ing",
  props: {
    isSecLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
.s_loading {
  position: relative;
  /* top: 0;
    left: 0;*/
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.s_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.s_dot1,
.s_dot2 {
  position: absolute;
  top: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  animation: loader 2s infinite ease-in-out;
}

.s_dot1 {
  background-color: rgb(103, 118, 196);
  animation-delay: -0.5s;
}

.s_dot2 {
  background-color: rgb(45, 45, 255);
}

@keyframes s_loader {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
</style>
