<template>
  <teleport to="body">
    <div class="loading" v-if="isLoading">
      <div class="loader">
        <!-- <div class="loading-spinner"></div> -->
        <div class="dot1"></div>
        <div class="dot2"></div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "load_ing",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dot1,
.dot2 {
  position: absolute;
  top: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  animation: loader 2s infinite ease-in-out;
}

.dot1 {
  background-color: var(--text-color);
  animation-delay: -0.5s;
}

.dot2 {
  background-color: var(--light-color);
}

@keyframes loader {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
</style>
