<template>
  <teleport to="body">
    <div class="bgPop" style="z-index: 999" v-if="showMsg">
      <div class="popBg">
        <h2><span style="color: rgb(0, 187, 44)"></span> Successfully Done</h2>

        <div class="popText" style="text-align: center">
          <p class="errorMsg" style="box-shadow: none; border: none">
            {{ successMsg }}
          </p>
        </div>

        <div class="actionButtons">
          <button style="opacity: 0; cursor: default" class="cancel">
            Cancel
          </button>
          <button @click="handleCancel" class="confirm">OK</button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "success_Msg",
  props: {
    showMsg: {
      type: Boolean,
      default: false,
    },
    successMsg: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleCancel() {
      window.location.reload();
    },
  },
};
</script>

<style>
.bgPop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000ae;
}
.popBg {
  position: relative;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.popBg h2 {
  text-decoration: none;
  font-size: 18px;
  color: #000;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;
}

.popBg .popText {
  width: 600px;
  height: 40px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.1px;
  text-align: left;
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.popBg .popText span {
  text-align: left;
  width: 150px;
  padding: 2px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.1px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.popBg .popText p {
  margin: 0;
  color: #000;
  text-align: left;
  height: 40px;
  width: 450px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.1px;
  box-shadow: none;
}

.successMsg {
  color: green;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.3px;
  box-shadow: none;
}
.popText input,
select {
  height: 35px;
  padding-left: 20px;
  width: 350px;
  box-shadow: 2px 2px 6px #000;
}

.stockBox {
  padding: 1px;
  margin: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.stockBox span {
  padding: 2px;
  font-size: 13px;
  font-weight: 600;
}

.popBg .actionButtons {
  margin: 2px;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.popBg .actionButtons .confirm {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: rgb(0, 165, 0);
  box-shadow: 1px 2px 7px #000;
  cursor: pointer;
  transition: 0.3s ease-in;
}

.popBg .actionButtons .Delete,
.cancel {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: red;
  box-shadow: 1px 2px 7px #000;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.popBg .actionButtons .confirm:hover {
  background-color: green;
}

.popBg .actionButtons .cancel:hover {
  background-color: red;
}
</style>
