<template>
  <w_header />
  <load_ing :isLoading="isLoading" />
  <error_show
    :showError="showError"
    :errorMsg="errorMsg"
    @close-error="showError = false"
  />
  <success_show
    :showMsg="isSuccess"
    :successMsg="msgS"
    @close-error="isSuccess = false"
  />
  <div class="container" style="width: 1000px">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" class="h5 tw-200">Date</th>
          <th scope="col" class="h5 tw-500">Order Id</th>
          <th scope="col" class="tw-200">Total amount</th>
          <th scope="col" class="tw-100">Status</th>
          <th scope="col" class="tw-200">Details</th>
          <!-- <th scope="col" class="tw-200"></th> -->
          <!-- <th scope="col"></th> -->
        </tr>
      </thead>

      <tbody>
        <tr v-for="data in orders" :key="data.id">
          <td class="tw-200">{{ formatDate(data.created) }}</td>

          <td
            class="my-auto tw-500"
            style="display: flex; flex-direction: column; align-items: center"
          >
            <p>{{ data._id }}</p>
          </td>

          <td class="p-2 tw-200">{{ data.total_amount }}</td>

          <td class="d-flex p-2 tw-100">
            <span class="m-auto p-1" id="output">{{ data.orderStatus }}</span>
          </td>

          <td class="p-2 tw-200">
            <a
              @click="$router.push('/userOrderPage/' + data.cart)"
              style="color: black; font-weight: 500"
            >
              <button class="btn btn-outline-dark">See more</button>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="clearfix">
      <div v-if="maxPage != 0" class="pagination">
        <button @click="updateCurrentPage(-1)" :disabled="currentPage <= 3">
          Previous
        </button>
        <h2 style="color: black">...</h2>
        <button v-if="currentPage - 2 > 0" @click="updateCurrentPage(-2)">
          {{ currentPage - 2 }}
        </button>
        <button v-if="currentPage - 1 > 0" @click="updateCurrentPage(-1)">
          {{ currentPage - 1 }}
        </button>

        <button class="currentPage">{{ currentPage }}</button>

        <button
          v-if="currentPage + 1 <= maxPage"
          @click="updateCurrentPage(+1)"
        >
          {{ currentPage + 1 }}
        </button>
        <button
          v-if="currentPage + 2 <= maxPage"
          @click="updateCurrentPage(+2)"
        >
          {{ currentPage + 2 }}
        </button>
        <h2 style="color: black">...</h2>

        <button
          @click="updateCurrentPage(+1)"
          :disabled="currentPage >= maxPage - 2"
        >
          Next
        </button>
      </div>
    </div>
  </div>
  <teleport to="body">
    <div class="bgPop" v-if="modal_view">
      <div class="popBg">
        <h2>Massage</h2>

        <div class="popText">
          <p>{{ modalMsg }}</p>
        </div>

        <div class="actionButtons">
          <button @click="handle_cancel()" class="cancel">close</button>
        </div>
      </div>
    </div>
  </teleport>
  <w_footer />
</template>

<script>
import axios from "axios";
import error_show from "../models/error_show.vue";
import load_ing from "../models/loading.vue";
import success_show from "../models/success_show.vue";
import w_footer from "./section/footer.vue";
import w_header from "./section/header.vue";
export default {
  name: "manage_order",
  components: {
    w_footer,
    w_header,
    error_show,
    load_ing,
    success_show,
  },
  data() {
    return {
      pgTitle: "User Orders",
      isLoading: false,
      showError: false,
      isSuccess: false,
      msgS: "",
      errorMsg: "",
      orders: {},

      currentPage: 1,
      pageSize: 15,
      bookLength: 0,
      maxPage: 0,
      directPageNumber: 1,
    };
  },
  methods: {
    formatDate(dateString) {
      const createdDate = new Date(dateString);
      const day = String(createdDate.getDate()).padStart(2, "0");
      const month = String(createdDate.getMonth() + 1).padStart(2, "0");
      const year = createdDate.getFullYear();
      return `${day}-${month}-${year}`;
    },
    updateCurrentPage(num) {
      this.currentPage = parseInt(this.currentPage) + parseInt(num);
      this.book_search();
      this.directPageNumber = parseInt(this.currentPage);
    },
    directPage() {
      if (this.directPageNumber > this.maxPage || this.directPageNumber <= 0) {
        this.showError = true;
        this.errorMsg = "No more page exist in this search results.";
      } else {
        this.currentPage = parseInt(this.directPageNumber);
        this.book_search();
      }
    },
  },
  async mounted() {
    try {
      const token = localStorage.getItem("Hakkani_token");
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };
      const url = `/order/user?page=${this.currentPage}`;
      this.isLoading = true;
      const response = await axios.get(url, config);
      this.isLoading = false;

      this.orders = response.data.order;
      this.currentPage = response.data.currentPage;
      this.bookLength = response.data.totalCount;
      this.maxPage = Math.ceil(this.bookLength / this.pageSize);
      console.log(this.orders);
    } catch (error) {
      this.showError = true;
      this.errorMsg = error;
    }
  },
};
</script>

<style>
/* 
  @media (min-width: 1025px) {
      .h-custom {
      min-height: 100vh !important;
      }
      .cart-image{
          width: 40px !important;
      }
      }
  
  
  
  
  @media (max-width: 600px) {
      .h-custom {
          min-height: 100vh !important;
          font-size: 12px !important;
  
      }
      .cart-image{
          width: 40px !important;
      }
  } */

.counter {
  border: 1px solid black !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.tw-100 {
  width: 100px !important;
}

.tw-200 {
  width: 200px !important;
}
.tw-500 {
  width: 500px !important;
}

/* .col-6 .card .counter .btn{
  
      border: none !important;    
  }
  } */
</style>
