<template>
  <w_header />
  <load_ing :isLoading="isLoading" />

  <!-- <section class="h-100 h-custom"> -->
  <!-- <div class="py-5 d-flex justify-content-center align-self-center"> -->
  <div class="container" style="width: 1000px">
    <!-- <div class="row"> -->

    <!-- <div class="table-responsive"> -->

    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col" class="h5 tw-200">Book cover</th>
          <th scope="col" class="h5 tw-500">Title</th>
          <th scope="col" class="tw-200">Unit Price</th>
          <th scope="col" class="tw-100">Quantity</th>
          <th scope="col" class="tw-200">Total Price</th>
          <th scope="col" class="tw-200"></th>
          <!-- <th scope="col"></th> -->
        </tr>
      </thead>

      <tbody>
        <tr v-for="data in cartList" :key="data.id">
          <td class="tw-200">
            <img
              :src="`${data.book.avatar}`"
              class="img-fluid rounded-3 cart-image"
              alt="Book"
              style="width: 60px"
            />
          </td>

          <td
            class="my-auto tw-500"
            style="display: flex; flex-direction: column; align-items: center"
          >
            <p>{{ data.book.name }}</p>
            <p>{{ data.book.author }}</p>
          </td>

          <td class="p-2 tw-200">{{ data.book.price }}</td>

          <td class="d-flex p-2 tw-100">
            <button
              class="btn btn-outline-dark"
              id="subtract"
              style="width: 53px"
              @click="decrement(data._id, data.amount)"
            >
              -
            </button>
            <span class="m-auto p-1" id="output">{{ data.amount }}</span>
            <button
              class="btn btn-outline-dark"
              id="add"
              style="width: 53px"
              @click="increment(data._id, data.amount, data.book.quantity)"
            >
              +
            </button>
          </td>

          <td class="p-2 tw-200">{{ data.amount * data.book.price }}</td>
          <td
            class="p-2 tw-200"
            style="cursor: pointer"
            @click="cancelBook(data._id)"
          >
            <img
              style="height: 30px"
              src="../../assets/imgs/icons/cross-circle-svgrepo-com.svg"
              alt=""
            />
          </td>
        </tr>
      </tbody>
    </table>

    <!-- </div> -->

    <div class="card shadow-2-strong mb-5 mb-lg-0" style="border-radius: 16px">
      <div class="card-body p-4">
        <div class="row d-flex justify-content-end">
          <div class="col-lg-4 col-xl-3">
            <div
              class="d-flex justify-content-between"
              style="font-weight: 500"
            >
              <p class="mb-2" style="font-size: 19px">Subtotal</p>
              <p class="mb-2" style="font-size: 19px">{{ calculateTotal }}</p>
            </div>

            <div
              class="d-flex justify-content-between"
              style="font-weight: 500"
            >
              <p class="mb-0" style="font-size: 19px">Shipping</p>
              <p class="mb-0" style="font-size: 19px">{{ shippingCharge }}</p>
            </div>

            <hr class="my-4" />

            <div
              class="d-flex justify-content-between mb-4"
              style="font-weight: 500"
            >
              <p class="mb-2" style="font-size: 19px">Total (tax included)</p>
              <p class="mb-2" style="font-size: 19px">
                {{ calculateTotal + shippingCharge }}
              </p>
            </div>

            <a @click="$router.push('place_order')">
              <button type="button" class="btn btn-primary btn-block btn-lg">
                <div class="d-flex justify-content-between">
                  <span>Checkout</span>
                  <span>{{ calculateTotal + shippingCharge }}</span>
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <teleport to="body">
    <div class="bgPop" v-if="modal_view">
      <div class="popBg">
        <h2>Massage</h2>

        <div class="popText">
          <p>{{ modalMsg }}</p>
        </div>

        <div class="actionButtons">
          <button @click="handle_cancel()" class="cancel">close</button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import load_ing from "../models/loading.vue";
import w_header from "./section/header.vue";
// import * as bootstrap from  "bootstrap";
export default {
  name: "cart_list",
  components: {
    w_header,
    load_ing,
  },
  data() {
    return {
      district: "Dhaka",
      cartList: [],
      isLoading: false,
      modalMsg: "",
      modal_view: false,
      config: {},
    };
  },
  methods: {
    handle_cancel() {
      this.modal_view = false;
      window.location.reload();
    },
    async cancelBook(bookId) {
      const url = `/cart/${bookId}`;
      this.isLoading = true;
      const response = await axios.delete(url, this.config);
      this.isLoading = false;
      if (response.status == 200) {
        this.modalMsg = "Book Deleted from cart successfully";
        this.modal_view = true;
      }
      console.log(response);
    },
    async increment(id, qn, stock) {
      if (qn < stock) {
        qn++;
        try {
          const url = `/cart/${id}`;
          const body = {
            amount: qn,
          };
          this.isLoading = true;
          const response = await axios.put(url, body, this.config);
          this.isLoading = false;
          console.log(response);
          window.location.reload();
        } catch (error) {
          console.log(error);
        }
      } else {
        this.modalMsg = "Stock Out...";
        this.modal_view = true;
      }
    },
    async decrement(id, qn) {
      if (qn > 1) {
        qn--;
        try {
          const url = `/cart/${id}`;
          const body = {
            amount: qn,
          };
          console.log(id + "    " + qn);
          this.isLoading = true;
          const response = await axios.put(url, body, this.config);
          this.isLoading = false;
          window.location.reload();
          console.log(response);
        } catch (error) {
          console.log(error);
        }
      } else {
        this.modalMsg = "Quantity Cann't be Zero...";
        this.modal_view = true;
      }
    },
  },
  computed: {
    calculateTotal() {
      let totalPrice = 0;
      this.cartList.forEach((item) => {
        totalPrice += item.book.price * item.amount;
      });
      return totalPrice;
    },
    shippingCharge() {
      if (this.district == "Dhaka") {
        const amount = 80;

        return parseInt(amount);
      } else {
        return 120.0;
      }
    },
  },
  async mounted() {
    try {
      const token = localStorage.getItem("Hakkani_token");
      this.config = {
        headers: { Authorization: `${token}` },
      };
      this.isLoading = true;
      const response = await axios.get("/cart", this.config);
      this.isLoading = false;
      if (response.data[0]) {
        this.cartList = response.data[0].cartList;
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style>
/* 
@media (min-width: 1025px) {
    .h-custom {
    min-height: 100vh !important;
    }
    .cart-image{
        width: 40px !important;
    }
    }




@media (max-width: 600px) {
    .h-custom {
        min-height: 100vh !important;
        font-size: 12px !important;

    }
    .cart-image{
        width: 40px !important;
    }
} */

.counter {
  border: 1px solid black !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.tw-100 {
  width: 100px !important;
}

.tw-200 {
  width: 200px !important;
}
.tw-500 {
  width: 500px !important;
}

/* .col-6 .card .counter .btn{

    border: none !important;    
}
} */
</style>
