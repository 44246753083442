<template>
  <w_header />
  <load_ing :isLoading="isLoading" />

  <section>
    <div class="shadow-lg">
      <div class="section02">
        <div class="content02">
          <br />
          <h1 class="mx-2">E-Journals</h1>
          <div>
            <p class="text-justify mx-2">
              Embark on an odyssey of intellectual discovery and scholarly
              enlightenment with Hakkani Publishers' expansive e-journal
              collection. Born from strategic alliances with prestigious
              institutions such as Springer, IEEE, Taylor & Francis, Sage, and
              Wiley Online Library, our curated repository transcends
              disciplinary boundaries, inviting you into a realm where knowledge
              reigns supreme.Dive deep into a labyrinth of peer-reviewed
              articles, groundbreaking research, and insightful analysis
              meticulously curated to fuel your academic pursuits. With
              accessibility, rigor, and unwavering dedication to academic
              excellence as our guiding principles, Hakkani Publishers invites
              you to join us in a quest for knowledge that knows no bounds.
              Unleash the full potential of your intellectual curiosity, and
              discover a world of limitless possibilities with Hakkani
              Publishers by your side.
            </p>

            <p class="text-justify mx-2">
              Journey into the depths of academic exploration with Hakkani
              Publishers' extensive e-journal collection, cultivated through
              collaborations with prestigious institutions such as Springer,
              IEEE, Taylor & Francis, Sage, and Wiley Online Library. Our
              curated repository traverses disciplinary boundaries, offering a
              rich tapestry of peer-reviewed articles, cutting-edge research,
              and profound insights. Stay at the forefront of your field,
              empowered by the latest advancements and discoveries, while
              engaging in scholarly discourse that transcends traditional
              boundaries. With a steadfast commitment to accessibility, rigor,
              and academic excellence, Hakkani Publishers invites you to embark
              on a transformative voyage of intellectual discovery and scholarly
              growth.
            </p>
          </div>
        </div>

        <div class="picture02">
          <div
            class="d-flex flex-column justify-content-center align-items-center m-3"
          >
            <div class="card col-6 p-1 m-2">
              <img
                src="../../assets/imgs/website/e-journals.gif"
                class="card-img-top"
                alt="..."
              />
            </div>

            <div class="card col-6 p-1 m-2">
              <img
                src="../../assets/imgs/website/ebook.jpg"
                class="card-img-top"
                alt="history"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <w_footer />
</template>

<script>
import load_ing from "../models/loading.vue";
import w_header from "./section/header.vue";

import w_footer from "./section/footer.vue";
// import * as bootstrap from  "bootstrap";
export default {
  name: "ejournalPage",
  components: {
    w_header,
    load_ing,
    w_footer,
  },
  data() {
    return {
      district: "Dhaka",
      cartList: [],
      isLoading: false,
      modalMsg: "",
      modal_view: false,
    };
  },
};
</script>

<style></style>
