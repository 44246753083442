<template>
  <w_header />
  <load_ing :isLoading="isLoading" />
  <error_show
    :showError="showError"
    :errorMsg="errorMsg"
    @close-error="showError = false"
  />
  <success_show
    :showMsg="isSuccess"
    :successMsg="modalMsg"
    @close-error="showMsg = false"
  />
  <!-- carousel -->
  <div class="container-fluid">
    <div
      id="carouselExampleCaptions"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          v-for="(slide, index) in slides"
          :key="index"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          :data-bs-slide-to="index"
          :class="{ active: index === 0 }"
          aria-current="true"
          :aria-label="'Slide ' + (index + 1)"
        ></button>
      </div>
      <div class="carousel-inner">
        <div
          v-for="(slide, index) in slides"
          :key="index"
          class="carousel-item"
          :class="{ active: index === 0 }"
        >
          <img
            v-if="index == 0"
            src="../../assets/cover 02.png"
            class="d-block w-100 banner-height"
            alt="..."
          />
          <img
            v-if="index == 1"
            src="../../assets/cover 05.jpg"
            class="d-block w-100 banner-height"
            alt="..."
          />
          <img
            v-if="index == 2"
            src="../../assets/cover 06.jpg"
            class="d-block w-100 banner-height"
            alt="..."
          />
          <img
            v-if="index == 3"
            src="../../assets/cover 04.jpg"
            class="d-block w-100 banner-height"
            alt="..."
          />
          <div class="carousel-caption d-none d-md-block">
            <h5>{{ slide.label }}</h5>
            <p>{{ slide.content }}</p>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
        @click="prevSlide"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
        @click="nextSlide"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>

  <!-- banner -->
  <section class="p-4">
    <div class="shadow-lg">
      <div class="my-5 section02">
        <div class="content02">
          <br />
          <h1 class="mx-2">Hakkani Publishers</h1>
          <div>
            <p class="text-justify mx-2">
              Hakkani, a trailblazer in academic and scholarly publishing in
              Bangladesh, has fostered the contributions of numerous esteemed
              scholars and literary luminaries. Our extensive catalog
              encompasses works on politics, governance, history, sociology,
              development, gender studies, education, health, environmental
              studies, anthropology, religion, economics, as well as an
              impressive array of autobiography/biography titles. Additionally,
              we boast a commendable collection of literary works available in
              both English and Bangla languages, reflecting the rich cultural
              tapestry of Bangladesh.
            </p>
            <p class="text-justify mx-2">
              Hakkani's scholarly publications stand as unparalleled resources
              for in-depth research on Bangladesh, revered by academics
              worldwide for their comprehensive insights. Moreover, our literary
              collection showcases the captivating works of exceptional writers
              across poetry, fiction, and short story genres, captivating
              readers with their depth and creativity.
            </p>
          </div>
          <div class="d-flex justify-content-end">
            <button
              type="button"
              class="btn btn-outline-dark my-5"
              style="width: 210px"
            >
              DOWNLOAD CATALOGUE
            </button>
          </div>
        </div>

        <div class="picture02">
          <div
            class="d-flex flex-column justify-content-center align-items-center m-3"
          >
            <div class="card col-5 p-1 m-2">
              <img
                src="../../assets/imgs/website/new arrival.jpg"
                class="card-img-top"
                alt="history"
              />
              <div class="card-body">
                <a
                  @click="$router.push('/book_list/newarrival/none')"
                  class="card-text d-flex justify-content-center"
                  style="font-size: 22px; text-decoration: none"
                >
                  New Arrivals
                </a>
              </div>
            </div>

            <div class="card col-5 p-1 m-2">
              <img
                src="../../assets/imgs/website/best.jpg"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
                <a
                  @click="$router.push('/book_list/bestSeller/none')"
                  class="card-text d-flex justify-content-center"
                  style="font-size: 22px; text-decoration: none"
                  >Best Sellers</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- card  fiction -->
  <section class="my-3">
    <div class="container">
      <br />
      <!-- <h1 class="main-title"><span class="firstWord">FICTION</span></h1> -->
      <div class="thirteen">
        <h1 class="title-style">FICTION</h1>

        <br /><br />
      </div>
      <div v-if="isSecLoading" class="text-center" style="height: 100px">
        <sec_loading :isSecLoading="isSecLoading" />
      </div>
      <div v-else>
        <Carousel :itemsToShow="cardItem" :wrapAround="true" :transition="500">
          <Slide v-for="(fBook, index) in fictionBooks" :key="index">
            <div class="card newBooks">
              <a @click="$router.push('/single_cart/' + fBook._id)">
                <div>
                  <span class="newBooksLabel">
                    <span>Fiction</span>
                  </span>
                </div>
                <img
                  :src="`${fBook.avatar}`"
                  style="height: 250px"
                  class="card-img-top"
                  alt="..."
                />
              </a>
              <div class="card-body newBooksBox">
                <h5
                  class="card-title text-center overflow-hidden m-auto"
                  style="font-size: 14px; font-weight: 400; height: 2.3rem"
                >
                  {{ fBook.name }}
                </h5>

                <ul class="newBooksIcon">
                  <li>
                    <a>
                      <img
                        src="../../assets/imgs/icons/eye-svgrepo-com.svg"
                        style="height: 20px; margin: auto"
                        alt="eye"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div class="d-flex justify-content-center">
                <div class="form-group">
                  <select
                    class="form-control"
                    :v-model="selectedPriceCategory[index] == fBook.cover_type"
                    @change="coverTypeChange(index, $event.target.value)"
                  >
                    <option
                      v-if="fBook.cover_type !== 'Hard Cover'"
                      :selected="fBook.cover_type !== 'Hard Cover'"
                      value="Paper Back"
                    >
                      Paper back - {{ fBook.p_price }}
                    </option>
                    <option
                      v-if="fBook.cover_type !== 'Paper Back'"
                      :selected="fBook.cover_type !== 'Paper Back'"
                      value="Hard Cover"
                    >
                      Hard cover - {{ fBook.h_price }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="d-flex flex-column align-items-center">
                  <p v-if="fBook.p_price" class="card-text newBooksPrice">
                    Paperback: {{ fBook.p_price }}৳
                  </p>
                  <p v-if="fBook.h_price" class="card-text newBooksPrice">
                    Hardcover: {{ fBook.h_price }}৳
                  </p>
                </div>
              </div>

              <div class="d-grid m-1">
                <button
                  class="btn newBooksButton"
                  type="button"
                  @click="
                    checkAuthentication(
                      fBook._id,
                      fBook.p_quantity,
                      fBook.h_quantity,
                      selectedPriceCategory[index]
                    )
                  "
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>

      <div class="text-center">
        <br />
        <a
          @click="$router.push('/book_list/type/Fiction')"
          class="btn newBooksButton mb-3"
          >View all Books</a
        >
      </div>
    </div>
  </section>

  <section class="my-3">
    <div class="container">
      <br />
      <div class="thirteen">
        <h1 class="title-style">NON FICTION</h1>

        <br /><br />
      </div>
      <div v-if="isSecLoading" class="text-center" style="height: 100px">
        <sec_loading :isSecLoading="isSecLoading" />
      </div>

      <div v-else>
        <!-- <Carousel v-bind="settings" :breakpoints="breakpoints"> -->
        <Carousel :itemsToShow="cardItem" :wrapAround="true" :transition="500">
          <Slide v-for="(Sbooks, index) in second_books" :key="index">
            <div class="carousel__item">
              <div class="card newBooks">
                <a @click="$router.push('/single_cart/' + Sbooks._id)">
                  <div>
                    <span class="newBooksLabel">
                      <span>Non-Fiction</span>
                    </span>
                  </div>
                  <img
                    :src="`${Sbooks.avatar}`"
                    style="height: 200px"
                    class="card-img-top"
                    alt="..."
                  />
                </a>
                <div class="card-body newBooksBox">
                  <h5
                    class="card-title text-center overflow-hidden m-auto"
                    style="font-size: 14px; font-weight: 400; height: 2rem"
                  >
                    {{ Sbooks.name }}
                  </h5>

                  <ul class="newBooksIcon">
                    <li>
                      <a>
                        <img
                          src="../../assets/imgs/icons/eye-svgrepo-com.svg"
                          style="height: 20px; margin: auto"
                          alt="eye"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="d-flex justify-content-center">
                  <div class="form-group">
                    <select
                      class="form-control"
                      :v-model="
                        selectedPriceCategory[index] == Sbooks.cover_type
                      "
                      @change="coverTypeChange(index, $event.target.value)"
                    >
                      <option
                        v-if="Sbooks.cover_type !== 'Hard Cover'"
                        :selected="Sbooks.cover_type !== 'Hard Cover'"
                        value="Paper Back"
                      >
                        Paper back - {{ Sbooks.p_price }}
                      </option>
                      <option
                        v-if="Sbooks.cover_type !== 'Paper Back'"
                        :selected="Sbooks.cover_type !== 'Paper Back'"
                        value="Hard Cover"
                      >
                        Hard cover - {{ Sbooks.h_price }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <div class="d-flex flex-column align-items-center">
                    <p
                      v-if="selectedPriceCategory[index] === 'Paper Back'"
                      class="card-text newBooksPrice"
                    >
                      Paperback: <br />
                      <br />{{
                        Sbooks.p_price * ((100 - Sbooks.p_discount) / 100)
                      }}৳
                    </p>
                    <p v-else class="card-text newBooksPrice">
                      Hardcover: <br />
                      <br />{{
                        Sbooks.h_price * ((100 - Sbooks.h_discount) / 100)
                      }}৳
                    </p>
                  </div>
                  <div class="mx-2"></div>
                  <div class="d-flex flex-column align-items-center">
                    <p
                      v-if="selectedPriceCategory[index] === 'Paper Back'"
                      class="text-decoration-line-through"
                    >
                      <br />
                      <br />
                      {{ Sbooks.p_price }}৳
                    </p>
                    <p v-else class="text-decoration-line-through">
                      <br />
                      <br />{{ Sbooks.h_price }}৳
                    </p>
                  </div>
                </div>
                <div class="d-grid m-1">
                  <button
                    class="btn newBooksButton"
                    type="button"
                    @click="
                      checkAuthentication(
                        Sbooks._id,
                        Sbooks.p_quantity,
                        Sbooks.h_quantity,
                        selectedPriceCategory[index]
                      )
                    "
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>

      <div class="text-center">
        <br />
        <a
          @click="$router.push('/book_list/type/Non-fiction')"
          class="btn newBooksButton mb-3"
          >View all Books</a
        >
      </div>
    </div>
  </section>

  <!-- event news started -->
  <br />
  <div class="thirteen">
    <h1 class="title-style">E-Resources</h1>

    <br /><br />
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 event-card">
        <div class="event-cards border rounded-3">
          <a @click="$router.push('/ebookPage')">
            <img
              class="event-img rounded-top-3 card-img-top"
              src="../../assets/imgs/website/e-book.gif"
              alt="..."
            />
            <br />
            <br />
            <div>
              <h5 class="card-title fs-4 fw-bolder">E-Books</h5>
              <br />
              <p class="text-justify p-3 testimonial-content">
                Hakkani Publishers' partnership with esteemed publishers like
                Elsevier, Pearson, Oxford, Cambridge, John Wiley, Emerald,
                McGraw Hill, and others positions them as a premier provider of
                e-books. Leveraging these partnerships, Hakkani Publishers is
                poised to offer a diverse and comprehensive selection of e-books
                to their customers, ensuring access to high-quality educational
                and professional resources from leading publishers worldwide.
              </p>
            </div>
          </a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12 event-card">
        <div class="event-cards border rounded-3">
          <a @click="$router.push('/ejournalPage')">
            <img
              class="event-img rounded-top-3 card-img-top"
              src="../../assets/imgs/website/e-journals.gif"
              alt="..."
            />
            <br />
            <br />
            <div>
              <h5 class="card-title fs-4 fw-bolder">E-Journals</h5>
              <br />
              <p class="text-justify p-3 testimonial-content">
                Discover a treasure trove of knowledge through Hakkani
                Publishers' e-journal collection, forged through partnerships
                with leading institutions like Springer, IEEE, Taylor & Francis,
                Sage, and Wiley Online Library. Our curated selection spans
                across various disciplines, providing access to cutting-edge
                research and insightful analysis. Stay abreast of the latest
                advancements and discoveries in your field, empowering your
                scholarly journey with Hakkani Publishers. Accessible,
                enriching, and always at the forefront of academic excellence.
              </p>
            </div>
          </a>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 event-card">
        <div class="event-cards border rounded-3">
          <a @click="$router.push('/ePeriodicalPage')">
            <img
              class="event-img rounded-top-3 card-img-top"
              src="../../assets/imgs/website/e-periodicals.gif"
              alt="..."
            />
            <br />
            <br />
            <div>
              <h5 class="card-title fs-4 fw-bolder">E-Periodicals</h5>
              <br />
              <p class="text-justify p-3 testimonial-content">
                Embark on a journey of discovery with Hakkani Publishers'
                e-periodicals, offering a diverse array of content from renowned
                publishers and scholarly societies. Through strategic
                partnerships with industry leaders like Springer, IEEE, Taylor &
                Francis, Sage, and Wiley Online Library, we provide access to a
                vast spectrum of periodicals spanning multiple disciplines.Our
                commitment to excellence ensures that you have access to
                high-quality content tailored to your academic and professional
                needs.
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <section class="py-12 bg-light testimonial-section">
      <div class="px-4 mx-auto">
        <div class="text-center">
          <h1 style="background: #e9d1b8; margin: auto">
            Client's Testimonial
          </h1>
          <h2 class="mt-4 text-3xl font-bold text-gray-900">
            Our happy clients say about us
          </h2>
        </div>

        <div class="mt-10">
          <div>
            <!-- <Carousel v-bind="settings1" :breakpoints="breakpoints1"> -->
            <Carousel
              style="height: 500px"
              :itemsToShow="testimonialItem"
              :wrapAround="true"
              :transition="500"
            >
              <Slide
                v-for="testimonial in testimonials"
                :key="testimonial.name"
              >
                <div class="carousel__item1">
                  <div class="card shadow">
                    <div class="card-body">
                      <blockquote class="mb-0">
                        <p class="text-lg testimonial-content">
                          “{{ testimonial.content }}”
                        </p>
                      </blockquote>
                    </div>
                    <div class="card-footer d-flex align-items-center">
                      <img
                        class="rounded-circle me-3"
                        :src="testimonial.image"
                        alt=""
                        width="50"
                        height="50"
                      />
                      <div>
                        <p class="mb-0 text-base font-bold text-gray-900">
                          {{ testimonial.name }}
                        </p>
                        <p class="mb-0 text-sm text-gray-600">
                          {{ testimonial.role }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>

              <template #addons>
                <Pagination />
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="cart-icon">
    <img
      src="../../assets/imgs/icons/shopping-cart-11-svgrepo-com.svg"
      alt="Cart Icon"
      @click="openCart"
    />
  </div>
  <div class="scroll-to-top" v-if="showScrollButton" @click="scrollToTop">
    <img
      src="../../assets/imgs/icons/up-arrow-svgrepo-com.svg"
      alt="Scroll to Top"
    />
  </div>
  <br />
  <w_footer />
  <teleport to="body">
    <div class="bgPop" style="z-index: 999" v-if="cart">
      <div class="popBg">
        <h2 class="d-flex justify-content-end">
          <span style="cursor: pointer" @click="handle_cancel()">❌</span>
        </h2>
        <h2><span style="color: rgb(0, 187, 44)"></span>Important Message</h2>

        <div class="popText" style="text-align: center">
          <p class="errorMsg" style="box-shadow: none; border: none">
            {{ cartMsg }}
          </p>
        </div>

        <div class="actionButtons">
          <a @click="$router.push('/userLogin')" class="confirm"> Log In </a>
          <a @click="$router.push('/userRegistration')" class="confirm"
            >Registration</a
          >
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import * as bootstrap from "bootstrap";
import { mapGetters } from "vuex";
import error_show from "../models/error_show.vue";
import load_ing from "../models/loading.vue";
import sec_loading from "../models/sectionLoading.vue";
import success_show from "../models/success_show.vue";
import w_footer from "./section/footer.vue";
import w_header from "./section/header.vue";

import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "index-home",
  components: {
    w_header,
    load_ing,
    error_show,
    success_show,
    w_footer,
    sec_loading,
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  data() {
    return {
      isLoading: false,
      isSecLoading: false,
      currentSlide: 0,
      fictionBooks: {},
      second_books: {},
      load_ing: false,
      modal_view: false,
      isSuccess: false,
      showError: false,
      showScrollButton: false,
      cart: false,
      cartMsg: "",
      errorMsg: "",
      modalMsg: "",
      swiper: null,
      selectedPriceCategory: [],
      slides: [
        {
          label: "",
          content: "",
        },
        {
          label: "",
          content: "",
        },
        {
          label: "",
          content: "",
        },
        {
          label: "",
          content: "",
        },
      ],
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        // 700px and up
        500: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        700: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
      settings1: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints1: {
        // 700px and up
        500: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        700: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
      testimonialItem: 3.25,
      cardItem: 5.25,
      testimonials: [
        {
          content:
            "We extend our gratitude to Hakkani Publishers for 15 years of enriching collaboration. Their fearless exploration of unconventional topics has broadened our horizons and enriched our collection. Hakkani's legacy will inspire generations to come.",
          name: "Prime Minister Office Library",
          role: "",
          image: require("../../assets/imgs/website/pmo.png"),
        },
        {
          content:
            "Hakkani is undoubtedly one of the most prestigious publications in Bangladesh. They publish books on topics that most publishers don't touch and most people don't care about. But they are very important for the growth of our national intellect. I pray to god this publication survives and thrives for a long long time.",
          name: "Ajay Das Gupta",
          role: "",
          image: require("../../assets/imgs/website/ajoy.jpg"),
        },
        {
          content:
            "Hakkani Publishers stands as a beacon of intellectual exploration in Bangladesh. Their commitment to publishing niche topics is commendable, fostering a culture of curiosity and critical thinking. Long live Hakkani!",
          name: "Mizanur Rahman",
          role: "",
          image: require("../../assets/imgs/website/mizan.png"),
        },
        {
          content:
            "Hakkani Publishers fills a crucial gap in our literary landscape by daring to delve into subjects others overlook. Their dedication to broadening perspectives enriches our society and contributes to our collective knowledge. Here's to Hakkani's continued success!",
          name: "Abul Kalam Azad",
          role: "",
          image: require("../../assets/imgs/website/azad.jpg"),
        },
      ],
    };
  },
  methods: {
    handle_cancel() {
      this.cart = false;
    },
    coverTypeChange(index, type) {
      this.selectedPriceCategory[index] = type;
      console.log(this.selectedPriceCategory[index]);
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    openCart() {
      const token = localStorage.getItem("Hakkani_token");
      if (!token) {
        this.cart = true;
        this.cartMsg = "You have to login to veiw the cart.";
      } else {
        this.$router.push("/cart_list");
      }
    },
    handleScroll() {
      if (window.scrollY > 300) {
        this.showScrollButton = true;
      } else {
        this.showScrollButton = false;
      }
    },
    handleResize() {
      // Adjust the number of testimonials displayed based on screen width
      if (window.innerWidth <= 768) {
        this.testimonialItem = 0.95;
        this.cardItem = 0.95; // Show 1 testimonial for smaller screens
      } else if (window.innerWidth <= 1024) {
        this.testimonialItem = 2.15;
        this.cardItem = 3.15; // Show 2 testimonials for medium screens
      } else {
        this.testimonialItem = 3.25;
        this.cardItem = 5.25; // Show 3 testimonials for larger screens
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async checkAuthentication(bookId, p_qn, h_qn, type) {
      console.log(p_qn + "   " + h_qn + "    " + type);
      const token = localStorage.getItem("Hakkani_token");
      const key = localStorage.getItem("key");
      const config = {
        headers: {
          Authorization: `${token}`,
        },
      };
      if (token != null && key == "kufan") {
        if (
          (type === "Paper Back" && p_qn > 0) ||
          (type === "Hard Cover" && h_qn > 0)
        ) {
          const body = {
            amount: 1,
            cover_type: type,
          };
          const url = `/book/view/addtocart/${bookId}`;
          const response = await axios.post(url, body, config);

          if (response.data.message === "This Book is already added in cart") {
            this.showError = true;
            this.errorMsg = "This Book is already added in cart..!!!";
          } else {
            this.isSuccess = true;
            this.modalMsg = "The book added to the cart successfully";
          }
        } else {
          this.showError = true;
          this.errorMsg = "The  book is out off stock..!!!";
        }
      } else {
        this.cart = true;
        this.cartMsg = "You have to login to add books into the cart.";
      }
    },
  },
  computed: {
    ...mapGetters(["getToken", "getUserId", "getKey"]),
  },
  beforeUnmount() {
    // Remove resize event listener before component is destroyed
    window.removeEventListener("resize", this.handleResize);
  },
  async mounted() {
    // Listen for window resize event
    window.addEventListener("resize", this.handleResize);
    // Call handleResize initially
    this.handleResize();

    window.addEventListener("scroll", this.handleScroll);
    this.$nextTick(() => {
      // Initialize Bootstrap carousel after the component is mounted
      let carousel = new bootstrap.Carousel(
        document.getElementById("carouselExampleCaptions"),
        {
          interval: 5000, // Set the interval as needed (in milliseconds)
          wrap: true,
          pause: "hover",
          // Add other carousel options if needed
        }
      );

      // Add event listeners to update currentSlide
      carousel._element.addEventListener("slide.bs.carousel", () => {
        this.currentSlide = carousel._activeIndex;
      });
    });

    try {
      // const body = {
      //   type: "Fiction",
      // };
      const url = "/search/newarrival";
      this.isSecLoading = true;
      const book_details = await axios.get(url);

      // const book_details = await axios.post(url, body);
      this.isSecLoading = false;
      this.fictionBooks = book_details.data.book.slice(0, 10);
    } catch (error) {
      console.log(error);
    }

    try {
      const body = {
        type: "Non-fiction",
      };
      const url = "/search/book";
      this.isSecLoading = true;
      const book_details = await axios.post(url, body);
      this.isSecLoading = false;
      this.second_books = book_details.data.book.slice(0, 10);
    } catch (err) {
      console.log(err);
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
/* CARD start */

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  cursor: pointer;
}

.scroll-to-top img {
  width: 40px;
  height: 40px;
}

.section02 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/imgs/website/background sky.png");
}
.content02 {
  width: 45%;
  font-size: 14px;
}
.content02 p {
  font-size: 18px;
  line-height: 1.2;
  color: #333;
}
.picture02 {
  width: 45%;
  margin: 25px;
  background-color: #f6f3c8;
  background-size: auto;
  background-position: center;
  box-shadow: inset 12px 12px 12px rgba(92, 92, 92, 0.262);
}
.cart-icon {
  background-color: #fff;
  padding: 10px;
  border-radius: 50%;
  position: fixed;
  top: 50%;
  right: 1.5%;
  transform: translateY(-50%);
  z-index: 9999; /* Ensure it appears above other content */
  cursor: pointer;
}
.cart-icon img {
  height: 40px;
}

.testimonial-section {
  position: relative;
  background-image: url("../../assets/imgs/website/background-brown.png");
  background-size: contain;
  background-position: center;
}

.newBooks {
  text-decoration: none !important;
  border: 1px solid #000 !important;
  border-radius: 2px !important;
  width: 180px !important;
}

.newBooksButton {
  color: #fff !important;
  background-color: #d8a50eed !important;
  border-radius: 25px !important;
}

.newBooksButton:hover {
  background-color: #957105cc !important;
  transition: 0.3s;
}

.newBooksPrice:hover {
  color: #6ed0f7 !important;
}

.newBooksLabel {
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
  bottom: auto !important;
  right: auto !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50rem !important;
  background-color: #b18500cc !important;
  color: #ffffff !important;
}

.newBooksLabel-2 {
  position: absolute !important;
  top: 18px !important;
  left: 0px !important;
  bottom: auto !important;
  right: auto !important;
  font-weight: 500 !important;
  padding: 0 6px !important;
  font-size: 12px !important;
  line-height: 18px !important;
  background-color: rgba(168, 0, 0, 0.8) !important;
  color: #ffffff !important;
  box-sizing: border-box !important;
}

.newBooksLabel-2-after {
  position: absolute !important;
  top: 0px !important;
  left: 100% !important;
  bottom: auto !important;
  right: auto !important;
  border-style: solid !important;
  border-width: 9px !important;
  width: 0 !important;
  height: 0 !important;
  border-color: rgba(168, 0, 0, 0.5) transparent rgba(168, 0, 0, 0.5)
    rgba(168, 0, 0, 0.8) !important;
}

/* icon hover effect */
.newBooks:hover {
  transform: scale(1.01) !important;
  transition: 0.4s !important;
  box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.1) !important ;
}

.newBooksBox .newBooksIcon {
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important;
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  z-index: 1 !important;
  transition: all 0.5s ease-in-out !important;
}

.newBooksBox .newBooksIcon li {
  margin: 0 3px 0 0 !important;
  /* display: inline-block !important;  */
  opacity: 0 !important;
  transition: all 0.5s ease-in-out !important;
}

.newBooks:hover .newBooksIcon li {
  opacity: 1 !important;
}

.newBooks .newBooksIcon li a {
  color: #0f070a99 !important;
  background-color: #f6e8e8 !important;
  font-size: 16px !important;
  line-height: 28px !important;
  margin: 5px !important;
  height: 30px !important;
  width: 30px !important;
  border: 2px solid transparent !important;
  border-radius: 50% !important;
  display: inline-block !important;
  text-align: center !important;
  transform: rotate(0) !important;
  transition: all 0.3s !important;
}

.newBooks:hover .newBooksIcon li a {
  color: #0b0708 !important;
  background: #f3eaea !important;
  border-color: #030202 !important;
}

/* card end */

/* event news started */

h5 {
  color: #000 !important;
}

.event-img {
  width: 100%;
  height: 150px;
}
.card-img {
  width: 40px;
  height: 55px;
}
.event-card {
  margin-bottom: 10px;
}

.event-card p {
  margin-bottom: 10px;
  min-height: 200px !important;
}
.event-card:hover {
  margin-top: 0px;
  transition: 0.2s;
}
.client-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.client {
  background-color: #67c8eeb3;
  font-size: 14px !important;
}

/* footer section start here */

.footer {
  color: white;
}
.email {
  width: 500px;
  height: 45px;
}
.search-btn {
  width: 28px;
  height: 45px;
  border: none;
  background: none;
  font-size: 20px;
  color: rgb(5, 39, 69);
  margin-left: -35px;
}
.footer-info {
  color: white;
  font-size: 17px;
  margin-left: 80px;
  font-weight: 200;
}
.footer-bg {
  background-color: #232f3e;
}
.menu {
  font-size: 22px;
  font-weight: 350;
  color: #6ed0f7;
}

#icon {
  padding: 4px;
  font-size: 25px;
}
.hori {
  background-color: rgb(165, 163, 163);
  height: 0.3px;
  width: 90%;
}

.bgPop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000ae;
}
.popBg {
  position: relative;
  max-width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.popBg h2 {
  text-decoration: none;
  font-size: 18px;
  color: #000;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;
}

.popBg .popText {
  width: 600px;
  height: 40px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.1px;
  text-align: left;
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.popBg .popText span {
  text-align: left;
  width: 150px;
  padding: 2px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.1px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.popBg .popText p {
  margin: 0;
  color: #000;
  text-align: left;
  height: 40px;
  width: 450px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.1px;
  box-shadow: none;
}

.errorMsg {
  color: red;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.3px;
  box-shadow: none;
}
.successMsg {
  color: green;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.3px;
  box-shadow: none;
}
.popText input,
select {
  height: 35px;
  padding-left: 20px;
  width: 350px;
  box-shadow: 2px 2px 6px #000;
}

.stockBox {
  padding: 1px;
  margin: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.stockBox span {
  padding: 2px;
  font-size: 13px;
  font-weight: 600;
}

.popBg .actionButtons {
  margin: 2px;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.popBg .actionButtons .confirm {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: rgb(0, 165, 0);
  box-shadow: 1px 2px 7px #000;
  cursor: pointer;
  transition: 0.3s ease-in;
}

.popBg .actionButtons .Delete,
.cancel {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: red;
  box-shadow: 1px 2px 7px #000;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.popBg .actionButtons .confirm:hover {
  background-color: green;
}

.popBg .actionButtons .cancel:hover {
  background-color: red;
}

.testimonial-content {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  color: #030202;
  font-size: 13px;
}

/* heading title */

.main-title {
  text-transform: uppercase !important ;
  color: #37383d !important ;
  width: 50%;
  background-color: #ffc964 !important ;
  font-weight: normal !important ;
  line-height: 47px !important ;
  font-size: 44px !important ;
  letter-spacing: 1.76px !important ;
  position: relative !important ;
  margin: auto !important ;
  text-align: center !important ;
  display: table !important ;
  width: fit-content !important ;
  width: -moz-fit-content !important ;
  z-index: 1 !important ;
  transition: all 0.3s ease !important ;
}

.thirteen h1 {
  color: #bf810e;
  position: relative;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 300px;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  /* border: 2px solid #9f6700; */
  /* padding: 5px 11px 3px 11px; */
  transition: all 0.5s ease;
}
.thirteen h1:hover {
  color: #fff;
  background: #9f6700;
  transition: all 0.5s ease;
}
.thirteen h1:before,
.thirteen h1:after {
  background-color: #b77700;
  position: absolute;
  content: "";
  height: 15px;

  width: 15px;
  border-radius: 50%;
  bottom: 25%;
}
.thirteen h1:before {
  left: -15px;
}
.thirteen h1:after {
  right: -15px;
}

.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  padding-top: 20px;
  perspective: 2000px;
  height: 600px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}

@media (min-width: 992px) {
  .banner-height {
    height: 500px;
  }
}

@media (max-width: 991.98px) {
  .banner-height {
    height: auto;
  }
  .section02 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/imgs/website/background sky.png");
  }
  .content02 {
    width: 90%;
    font-size: 12px;
  }
  .content02 p {
    font-size: 12px;
    line-height: 1;
    color: #333;
  }
  .picture02 {
    width: 90%;
    margin: 5px;
  }
  .thirteen h1 {
    color: #bf810e;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 150px;
    text-align: center;
    margin: auto;
    white-space: nowrap;
    /* border: 2px solid #9f6700; */
    /* padding: 5px 11px 3px 11px; */
    transition: all 0.5s ease;
  }
}
</style>
