<template>
  <section>
    <div class="container-fluid footer-bg">
      <br /><br />

      <br /><br /><br />

      <div class="row">
        <div class="col-3">
          <img
            src="../../../assets/logo 1x.png"
            class="fotterlogo"
            alt=""
          /><br /><br />
        </div>
      </div>
      <div class="row footer-info">
        <div class="col-sm-6 col-lg-3">
          <p class="footer_p">
            Hakkani Publishers<br /><br />
            Momtaz Plaza, House No-7, 3rd Floor <br /><br />
            Road No. 4, Dhaka 1205 <br /><br />

            Helpline <br /><br />
            <i class="fa fa-phone"></i> (+8802)9611971-5 <br />

            <br />
            <i class="fa fa-tty"></i> (+88) 01811-416027<br />
          </p>
        </div>
        <div class="col-sm-6 col-lg-3">
          <p class="footer_p">
            <span class="menu">MENU</span><br />
            <br /><br />
            <a @click="$router.push('/book_list/newarrival/none')">Books</a>
            <br /><br />
            Authors <br /><br />
            Blogs <br /><br />
            Privacy Policy <br /><br />
          </p>
        </div>
        <div class="col-sm-6 col-lg-3">
          <p class="footer_p">
            <span class="menu"> CATEGORIES <br /></span><br />

            Road to Bangladesh <br /><br />
            <a @click="$router.push('/book_list/type/Anthropology')"
              >Anthropology</a
            >
            <br /><br />
            <a @click="$router.push('/book_list/type/Social Science')"
              >Social Science</a
            >
            <br /><br />
            <a @click="$router.push('/book_list/type/Political Science')"
              >Political Science</a
            >
            <br /><br />
            <a @click="$router.push('/book_list/type/History')">History</a>
            <br /><br />
            <a @click="$router.push('/book_list/type/Gender & Women Studies')"
              >Gender & Women Studies</a
            >
            <br /><br />
          </p>
        </div>
        <div class="col-sm-6 col-lg-3">
          <p class="footer_p">
            <span class="menu"> OTHERS <br /></span><br />

            Download Catalogs <br /><br />
            How to Order <br /><br />
            Sitemap
          </p>
          <!-- <i id="icon" class="fa fa-facebook"></i>
                        <i id="icon" class="fa fa-twitter"></i>
                        <i id="icon" class="fa fa-instagram"></i>
                        <i id="icon" class="fa fa-linkedin"></i>
                        <i id="icon" class="fa fa-youtube"></i> -->
        </div>

        <p class="footer_p">
          <img
            src="../../../assets/SSL Commerz.jpg"
            alt=""
            class="img img-fluid o_we_custom_image mx-auto d-block"
            data-original-id="3539"
            data-original-src="/web/image/3539-c215fdd7/SSL%20Commerz%20Pay%20With%20logo%20All%20Size_Feb%202023-05.jpg"
            data-mimetype="image/jpeg"
            data-bs-original-title=""
            title=""
            aria-describedby="tooltip965340"
            data-quality="100"
            data-resize-width="1024"
            loading="lazy"
            style=""
          />
          <br />
        </p>
        <div class="hori"></div>

        <br />
        <p class="footer_p" @click="$router.push('/admin')">
          Copyright © Hakkani Publishers 1975 - 2023 || Developed by Digital
          Solution Bangladesh
        </p>
        <br /><br /><br />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "foot_er",
};
</script>

<style>
.footer_p {
  color: #fff !important;
}
.footer {
  color: white !important;
}
.email {
  width: 500px !important;
  height: 45px !important;
}
.search-btn {
  width: 28px !important;
  height: 45px !important;
  border: none !important;
  background: none !important;
  font-size: 20px !important;
  color: rgb(5, 39, 69) !important;
  margin-left: -35px !important;
}
.footer-info {
  color: white !important;
  font-size: 17px !important;
  margin-left: 80px !important;
  font-weight: 200 !important;
}
.footer-bg {
  background-color: #232f3e !important;
}
.fotterlogo {
  margin-left: 80px !important;
  height: 100px !important;
  padding-left: 80px !important;
}
.menu {
  font-size: 22px !important;
  font-weight: 350 !important;
  color: #6ed0f7 !important;
}

#icon {
  padding: 4px !important;
  font-size: 25px !important;
}
.hori {
  background-color: rgb(165, 163, 163) !important;
  height: 0.3px !important;
  width: 90% !important;
}

.client {
  background-color: #67c8eeb3 !important;
}

@media (max-width: 550px) {
  .footer-info {
    color: white !important;
    font-size: 14px !important;
    margin-left: -10px !important;
    font-weight: 200 !important;
  }
  .fotterlogo {
    margin-left: 50px !important;
    height: 80px !important;
    padding-left: 60px !important;
    display: flex;
    align-items: center;
  }
}
</style>
