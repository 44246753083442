<template>
  <w_header />
  <load_ing :isLoading="isLoading" />

  <section class="cart-section">
    <div class="container p-5">
      <div class="row">
        <div
          class="col-xxl-6 col-lg-6 col-md-12 col-sm-12 d-grid justify-content-center"
        >
          <img
            v-if="bookDetails.avatar"
            :src="`${bookDetails.avatar}`"
            class="img-thumbnail cart-img"
            alt="..."
          />
        </div>

        <div
          class="col-xxl-6 col-lg-6 col-md-12 col-sm-12 d-grid justify-content-center"
        >
          <div
            class="card"
            style="width: 400px; height: 450px; background-color: fff"
          >
            <div class="card-body">
              <h5 class="card-title">{{ bookDetails.name }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                {{ bookDetails.author }}
              </h6>
              <h3 v-if="bookDetails.h_price">
                Hard Cover : {{ bookDetails.h_price }}৳
              </h3>
              <h3 v-if="bookDetails.p_price">
                Paper Back : {{ bookDetails.p_price }}৳
              </h3>
              <p class="card-text text-justify">
                {{ bookDetails.short_description }}
              </p>
            </div>
            <button
              class="btn btn-outline-dark"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              ADD TO CART
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container">
    <div class="row">
      <div class="col-6">
        <div>
          <h4>Description</h4>
        </div>

        <br />
        <hr />

        <div class="d-grid justify-content-center">
          <p class="text-justify">
            {{ bookDetails.long_description }}
          </p>
        </div>
      </div>

      <div class="col-6">
        <div>
          <h4>Product Details</h4>
        </div>

        <div class="d-grid justify-content-center">
          <br />
          <hr />
          <table class="table table-hover">
            <tr>
              <td>ISBN:</td>
              <td>{{ bookDetails.isbn_num }}</td>
            </tr>
            <tr>
              <td>Category:</td>
              <td>{{ bookDetails.type }}</td>
            </tr>
            <tr>
              <td>Book Author:</td>
              <td>{{ bookDetails.author }}</td>
            </tr>
            <tr v-if="bookDetails.issn_num">
              <td>ISSN:</td>
              <td>{{ bookDetails.issn_num }}</td>
            </tr>
            <tr>
              <td>STOCKS</td>
              <td>
                <span v-if="bookDetails.h_quantity">
                  Hard Cover : {{ bookDetails.h_quantity }}
                  <br />
                </span>

                <span v-if="bookDetails.p_quantity">
                  Paper back : {{ bookDetails.p_quantity }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </section>

  <teleport to="body">
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Message</h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body m-auto">Added to the cart successfully.</div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import load_ing from "../models/loading.vue";
import w_header from "./section/header.vue";

// import * as bootstrap from  "bootstrap";
export default {
  name: "single_cart",
  components: {
    w_header,
    load_ing,
  },
  data() {
    return {
      count: 1,
      showDescription: false,
      showProduct: true,
      showReview: false,
      showComments: false,
      config: {},
      bookId: "",
      isLoading: false,
      bookDetails: {},
    };
  },
  methods: {
    increment() {
      return this.count++;
    },
    decrement() {
      if (this.count <= 1) return 1;
      return this.count--;
    },
    activeDescription() {
      this.showDescription = true;
      this.showProduct = false;
      this.showReview = false;
      this.showComments = false;
    },
    activeProducts() {
      this.showDescription = false;
      this.showProduct = true;
      this.showReview = false;
      this.showComments = false;
    },
    activeReview() {
      this.showDescription = false;
      this.showProduct = false;
      this.showReview = true;
      this.showComments = false;
    },
    activeComments() {
      this.showDescription = false;
      this.showProduct = false;
      this.showReview = false;
      this.showComments = true;
    },
  },
  async mounted() {
    // console.log(this.$route.params.id);

    try {
      this.bookId = this.$route.params.id;
      const token = localStorage.getItem("Hakkani_token");

      this.config = {
        headers: {
          Authorization: `${token}`,
        },
      };

      const url = "/book/view/" + this.bookId;
      this.isLoading = true;
      const response = await axios.get(url, this.config);
      this.isLoading = false;

      // console.log(response.data);
      this.bookDetails = response.data;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style>
.cart-section {
  background: antiquewhite;
}

.cart-img {
  height: 450px !important;
  width: 300px !important;
}
</style>
