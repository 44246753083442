<template>
  <div class="navigation">
    <ul class="scrollable-container">
      <li>
        <a href="#">
          <span class="icon">
            <img src="../../../assets/logo 1x.png" alt="logo" />
          </span>
          <span class="title" style="margin-top: 0px">Hakkani Publishers</span>
        </a>
      </li>

      <li :class="{ active: $route.path === '/dashboard' }">
        <router-link to="/dashboard">
          <span class="icon"> </span>
          <span class="title">Dashboard</span>
        </router-link>
      </li>

      <li :class="{ active: $route.path === '/add_books' }">
        <router-link to="/add_books">
          <span class="icon">
            <ion-icon name="people-outline"></ion-icon>
          </span>
          <span class="title">Add Books</span>
        </router-link>
      </li>
      <li
        :class="{ active: $route.path === '/add_users' }"
        v-if="user.permission == 'Admin'"
      >
        <router-link to="/add_users">
          <span class="icon">
            <ion-icon name="people-outline"></ion-icon>
          </span>
          <span class="title">Add Users</span>
        </router-link>
      </li>
      <li :class="{ active: $route.path === '/book_search' }">
        <router-link to="/book_search">
          <span class="icon">
            <ion-icon name="people-outline"></ion-icon>
          </span>
          <span class="title">Book List</span>
        </router-link>
      </li>
      <li :class="{ active: $route.path === '/manage_orders' }">
        <router-link to="/manage_orders">
          <span class="icon">
            <ion-icon name="people-outline"></ion-icon>
          </span>
          <span class="title">Manage Order</span>
        </router-link>
      </li>
      <li :class="{ active: $route.path === '/report' }">
        <router-link to="/report">
          <span class="icon"> </span>
          <span class="title">Report</span>
        </router-link>
      </li>
      <li :class="{ active: $route.path === '/backup' }">
        <router-link to="/backup">
          <span class="icon"> </span>
          <span class="title">Backup</span>
        </router-link>
      </li>
      <li>
        <a @click="logout">
          <span class="icon">
            <ion-icon name="people-outline"></ion-icon>
          </span>
          <span class="title">Log Out</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "side-bar",
  data() {
    return {
      user: {},
    };
  },
  methods: {
    async logout() {
      localStorage.clear();
      this.$router.push("/admin");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("users"));
  },
};
</script>

<style>
.scrollable-container {
  max-height: 95vh;
  overflow-y: auto;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.scrollable-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
@import "../../../../src/assets/css/style.css";
</style>
