<template>
  <div class="container-fuild">
    <div class="top_bar">
      <div class="box top_bar_content">
        <p>
          <img src="../../../assets/imgs/website/call.jpg" alt="" />
          (+8802)9611971-5 &nbsp; (+88) 01811-416027&nbsp;
          <img src="../../../assets/imgs/website/mail.jpg" alt="" />
          service.hakkanipublishers@gmail.com
        </p>
        <div>
          <a
            href="https://www.facebook.com/hakkanipublishers?mibextid=ZbWKwL"
            target="_blank"
            ><img src="../../../assets/imgs/website/facebook.png" alt=""
          /></a>
          &nbsp;
          <a
            href="https://www.instagram.com/hakkkani_publihsers/"
            target="_blank"
            ><img src="../../../assets/imgs/website/instagram.png" alt=""
          /></a>
          &nbsp;
          <a href=""
            ><img src="../../../assets/imgs/website/youtube.png" alt=""
          /></a>
          &nbsp;
        </div>
      </div>
    </div>
  </div>

  <nav
    class="navbar navbar-expand-lg navbar-light bg-transparent shadow-sm p-1 mb-5 bg-body-light rounded p-1 mb-5"
  >
    <div class="container">
      <a @click="$router.push('/')">
        <img
          src="../../../assets/imgs/website/hakkani_logo.png"
          style="height: 95px"
          class="logo"
          alt=""
        />
      </a>
      <button
        class="navbar-toggler shadow-none border-0"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="sidebar offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header text-black border-bottom">
          <img
            src="../../../assets/imgs/website/hakkani_logo.png"
            style="height: 95px"
            class="logo"
            alt=""
          />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item mx-3">
              <a
                class="nav-link fs-4 text-primary"
                @click="$router.push('/aboutUs')"
              >
                About us
              </a>
            </li>
            <li class="nav-item mx-3">
              <a class="nav-link fs-4 text-primary" href="">Bestsellers</a>
            </li>

            <li class="nav-item dropdown mx-3">
              <a
                class="nav-link dropdown-toggle fs-4 text-primary"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Books
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Category</a></li>
                <li><a class="dropdown-item" href="#">Own Publications</a></li>
                <li>
                  <a class="dropdown-item" href="#"
                    >Joined Vencher Publication</a
                  >
                </li>
              </ul>
            </li>

            <li class="nav-item mx-3">
              <a class="nav-link fs-4 text-primary" href="#authors">Authors</a>
            </li>
          </ul>
          <form class="d-flex" role="search">
            <input
              class="form-control me-2"
              style="height: 40px; width: 220px"
              type="search"
              v-model="searchKey"
              placeholder="Search"
              aria-label="Search"
              @keyup.enter="searchBooks"
            />
            <button
              class="btn btn-outline-primary"
              style="height: 40px; width: 80px; margin-left: 25px"
              type="submit"
              @click="$router.push(`/book_list/allbook/${searchKey}`)"
            >
              Search
            </button>
            <br /><br />

            <a
              v-if="token && key == 'kufan'"
              @click="$router.push('/cart_list')"
            >
              <img
                style="height: 40px; padding-left: 25px"
                src="../../../assets/imgs/icons/shopping-cart-11-svgrepo-com.svg"
                alt=""
              />
            </a>
            <a
              v-if="token && key == 'kufan'"
              @click="$router.push('/userProfile')"
            >
              <img
                style="height: 40px; padding-left: 25px"
                src="../../../assets/imgs/icons/user-circle-svgrepo-com.svg"
                alt=""
              />
            </a>
            <button
              v-if="token && key == 'kufan'"
              class="btn btn-outline-primary"
              style="height: 40px; width: 80px; margin-left: 25px"
              type="submit"
              @click="logout"
            >
              Log Out
            </button>
            <button
              v-else
              @click="$router.push('/userLogin')"
              class="btn btn-outline-primary"
              style="height: 40px; width: 80px; margin-left: 25px"
              type="submit"
            >
              Log In
            </button>
          </form>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "w-header",
  data() {
    return {
      token: "",
      searchKey: "",
      key: "",
    };
  },
  methods: {
    async logout() {
      localStorage.clear();
      this.$router.push("/");
    },
  },
  mounted() {
    this.token = localStorage.getItem("Hakkani_token");
    this.key = localStorage.getItem("key");
  },
};
</script>

<style scoped>
@import "../../../assets/bootstrap/css/bootstrap.css";
@import "../../../assets/bootstrap/css/bootstrap.min.css";

.navbar-dark .navbar-nav a.nav-link {
  color: #ffffff;
  font-size: 1.1em;
}
.dropdown-menu {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: none;
  border-radius: 0;
  padding: 0.7em;
}
@media only screen and (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    display: flex;
  }
  .dropdown-menu.show {
    display: flex;
  }
}
.dropdown-menu ul {
  list-style: none;
  padding: 3px;
}
.dropdown-menu li .dropdown-item {
  color: #000;
  font-size: 1em;
  padding: 0.5em 1em;
}
.dropdown-menu li .dropdown-item:hover {
  background-color: #f1f1f1;
}
.dropdown-menu li:first-child a {
  font-weight: 400;
  font-size: 1.2em;
  text-transform: uppercase;
  color: #6ed0f7;
  border-bottom: 1px solid #6ed0f7;
}
.dropdown-menu li:first-child a:hover {
  color: #00b7ff;
  background-color: #ffffff;
}

@media only screen and (max-width: 992px) {
  .dropdown-menu.show {
    flex-wrap: wrap;
    max-height: 350px;
    overflow-y: scroll;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1140px) {
  .dropdown:hover .dropdown-menu {
    width: 40vw;
    flex-wrap: wrap;
  }
}

/*    Top bar design      */

.top_bar {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  height: 40px;
  justify-content: space-around;
  border: 1px solid rgb(228, 228, 228);
}

.top_bar .box {
  color: #6ed0f7;
}

.top_bar p {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1.2px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.top_bar .box img {
  height: 20px;
}

.header {
  border-bottom: 2px solid gainsboro;
  box-shadow: 4px 4px 3px gainsboro;
}

.text_fn {
  font-size: 24px;
  font-weight: 500;
}

/* carousel start */

body {
  font-family: poppins;
}

.carousel-item {
  height: 70vh;
  min-height: 300px;
  background: rgb(0, 0, 0, 0);
}

.carousel-caption {
  bottom: 220px;
}

.carousel-caption h5 {
  font-style: 60px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 25px;
  font-weight: 900;
  color: #000000;
  font-size: 35px;
}

.carousel-caption p {
  font-style: 18px;
  margin: auto;
  width: 60%;
  line-height: 1.9;
  color: #000000;
  font-size: 20px;
}

.carousel-caption a {
  text-transform: uppercase;
  background: #6ed0f7;
  padding: 10px 30px;
  display: inline-block;
  color: #000000;
  margin-top: 15px;
  border-radius: 50px;
}

.w-100 {
  height: 100vh;
}

/* carousel end */

/* catagory */

.catagoryButton {
  background-color: #6ed0f7;
  border-radius: 20px;
}

.catagoryButton:hover {
  background-color: #92e0ff;
  transition: 0.3s;
}

/* newbooks */

.newBooksButton {
  background-color: #6ed0f7;
  border-radius: 0px !important;
}

.newBooksButton:hover {
  background-color: #92e0ff;
  transition: 0.3s;
}

.newBooksPrice:hover {
  color: #6ed0f7;
}

.newBooksLabel {
  position: absolute;
  top: 12px;
  left: 12px;
  bottom: auto;
  right: auto;
  font-weight: 500;
  font-size: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 50px;
  width: 50px;
  border-radius: 50rem;
  background-color: rgba(67, 168, 0, 0.8);
  color: #ffffff;
}

.newBooksLabel-2 {
  position: absolute;
  top: 18px;
  left: 0px;
  bottom: auto;
  right: auto;
  font-weight: 500;
  padding: 0 6px;
  font-size: 12px;
  line-height: 18px;
  background-color: rgba(168, 0, 0, 0.8);
  color: #ffffff;
  box-sizing: border-box;
}

.newBooksLabel-2-after {
  position: absolute;
  top: 0px;
  left: 100%;
  bottom: auto;
  right: auto;
  border-style: solid;
  border-width: 9px;
  width: 0;
  height: 0;
  border-color: rgba(168, 0, 0, 0.5) transparent rgba(168, 0, 0, 0.5)
    rgba(168, 0, 0, 0.8);
}

/* icon hover effect */
.newBooks:hover {
  transform: scale(1.01);
  transition: 0.4s;
  box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.2);
}

.newBooksBox .newBooksIcon {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}

.newBooksBox .newBooksIcon li {
  margin: 0 3px 0 0;
  /* display: inline-block;  */
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.newBooks:hover .newBooksIcon li {
  opacity: 1;
}

.newBooks .newBooksIcon li a {
  color: #0f070a99;
  background-color: #f6e8e8;
  font-size: 16px;
  line-height: 28px;
  margin: 5px;
  height: 30px;
  width: 30px;
  border: 2px solid transparent;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  transform: rotate(0);
  transition: all 0.3s;
}

.newBooks:hover .newBooksIcon li a {
  color: #0b0708;
  background: #f3eaea;
  border-color: #030202;
}

/* event news started */

.event-img {
  width: 100%;
  height: 250px;
}
.card-img {
  width: 40px;
  height: 55px;
}
.event-card {
  margin-top: 8px;
}
.event-card:hover {
  margin-top: 0px;
  transition: 0.2s;
}
.client-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

/* footer section start here */

.footer {
  color: white;
}
.email {
  width: 500px;
  height: 45px;
}
.search-btn {
  width: 28px;
  height: 45px;
  border: none;
  background: none;
  font-size: 20px;
  color: rgb(5, 39, 69);
  margin-left: -35px;
}
.footer-info {
  color: white;
  font-size: 17px;
  margin-left: 80px;
  font-weight: 200;
}
.footer-bg {
  background-color: #232f3e;
}
.menu {
  font-size: 22px;
  font-weight: 350;
  color: #6ed0f7;
}

#icon {
  padding: 4px;
  font-size: 25px;
}
.hori {
  background-color: rgb(165, 163, 163);
  height: 0.3px;
  width: 90%;
}

.client {
  background-color: #67c8eeb3;
}

.top_bar_content {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .top_bar p {
    font-size: 10px;
    width: 75%;
  }
  .top_bar .box img {
    height: 10px;
  }
}
</style>
