<template>
  <load_ing :isLoading="isLoading" />
  <error_Msg
    :showError="showError"
    :errorMsg="errorMsg"
    @close-error="showError = false"
  />
  <section class="pdf" id="pdfSection">
    <div class="headerPdf">
      <img src="../../../assets/logo.png" alt="" />
      <div class="box">
        <br />
        <p>
          Hakkani Publishers <br />
          Momtaz Plaza, House No-7, 3rd Floor, Road No. 4, Dhaka 1205 <br />
          Phone : (+8802)9611971-5 , (+88) 01811-416027 <br />
          E-mail: info@hakkanipublishers.com web: www.hakkanipublisher.com
        </p>
        <br />
      </div>
    </div>
    <table>
      <!-- <tr>
        <td colspan="3" style="border: none">
          <h4>{{ category }}</h4>
        </td>

        <td colspan="2" style="border: none"></td>
        <td colspan="2" style="border: none">
          <h4>Book Serial : {{ range }}</h4>
        </td>
      </tr> -->

      <tr>
        <th>Sl.</th>
        <th style="width: 250px; text-align: center">Title</th>
        <th style="width: 250px; text-align: center">Author</th>
        <th>Publisher</th>
        <th>Type</th>
        <th>Quantity</th>
        <th>Unit Price</th>
      </tr>
      <tr v-for="(data, index) in pdfData" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ data.name }}</td>
        <td>{{ data.author }}</td>
        <td>{{ data.publisher }}</td>
        <td>{{ data.type }}</td>
        <td>
          <span v-if="data.p_quantity">P - {{ data.p_quantity }} <br /></span>
          <span v-if="data.h_quantity">H - {{ data.h_quantity }}</span>
        </td>
        <td>
          <span v-if="data.p_price">{{ data.p_price }} <br /></span>
          <span v-if="data.h_price">{{ data.h_price }}</span>
        </td>
      </tr>
    </table>
  </section>
</template>
<script>
import axios from "axios";
import error_Msg from "../../models/error_show.vue";
import load_ing from "../../models/loading.vue";
export default {
  name: "pdf_Maker_SL_Book",
  components: {
    load_ing,
    error_Msg,
  },
  data() {
    return {
      isLoading: false,
      showError: false,
      pdfData: {},
    };
  },
  async mounted() {
    try {
      var currentPage = 1;
      var allBooks = []; // Array to store all books

      while (currentPage) {
        const url = `/book/view?page=${currentPage}`;
        this.isLoading = true;
        const response = await axios.get(url);
        this.isLoading = false;
        const booksOnPage = response.data.book;

        // Add books from this page to the allBooks array
        allBooks.push(...booksOnPage);

        // Check if there are more pages to fetch
        if (currentPage >= response.data.totalPage) {
          break; // Exit the loop if all pages have been fetched
        }

        currentPage++; // Move to the next page
      }

      // Store all fetched books in pdfData
      this.pdfData = allBooks;
    } catch (error) {
      this.isLoading = false;
      this.showError = true;
      this.errorMsg = error.response.data.message;
    }
  },
};
</script>

<style>
.pdf {
  width: 210mm;
  padding-top: 5px;
  margin: 2px;
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pdf .headerPdf {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-top: 5px;
}

.pdf .headerPdf img {
  height: 70px;
}
.pdf .headerPdf .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pdf .headerPdf .box p {
  margin: 1px;
  margin-top: 8px;
  margin-bottom: 4px;
  height: 30px;
  text-align: center;
  width: 100%;
  color: var(--black);
  background-color: var(--text-color);
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
}

.pdf table {
  background-color: #fff !important;
  margin-top: 5px !important;
  width: 100% !important;
  padding: 2px !important;
  border-radius: 1px !important;
  box-shadow: none !important;
  border-collapse: collapse !important;
}

.pdf table th {
  background-color: rgb(97, 97, 97) !important;
  color: white !important;
  width: 30% !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  border: 2px solid black !important;
  border-radius: 0% !important;
  text-align: center !important;
  padding: 0.2% !important;
}
.pdf table th,
td .sl {
  width: 4px !important;
  padding: 4px 10px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.pdf .th50 {
  max-width: 40px !important;
  padding: 4px 2px !important;
}
.pdf .leftAlign {
  text-align: left !important;
}
.pdf table td {
  padding: 5px !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  text-align: center !important;
  border: 2px solid black !important;
  border-top: 0 !important;
}
</style>
