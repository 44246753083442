<template>
  <load_ing :isLoading="isLoading" />
  <error_Msg
    :showError="showError"
    :errorMsg="errorMsg"
    @close-error="showError = false"
  />
  <section class="pdf" id="pdfSection">
    <div class="headerPdf">
      <img src="../../../assets/logo 1x.png" />
      <div class="box">
        <br />
        <p>
          Momtaz Plaza, House No-7, 3rd Floor,<br />
          Road No. 4, Dhaka 1205 <br />
          E-mail: hakkanipublishers@gmail.com web: www.hakknipublishers.com
        </p>
        <br />
      </div>
    </div>
    <table>
      <tr>
        <th>Serial No.</th>
        <th>Title</th>
        <th>Author</th>
        <th>Publisher</th>
        <th>Type</th>
        <th>Unit Price</th>
        <th>Copy</th>
      </tr>
      <tr v-for="(data, index) in pdfData" :key="index">
        <td v-if="data.book != null">{{ data.serialNumber }}</td>
        <td v-if="data.book != null">{{ data.book.title }}</td>
        <td v-if="data.book != null">{{ data.book.author }}</td>
        <td v-if="data.book != null">{{ data.book.publisher.name }}</td>
        <td v-if="data.book != null">{{ data.book.bookType }}</td>
        <td v-if="data.book != null">{{ data.unitPrice }}</td>
        <td v-if="data.book != null">{{ data.copies }}</td>
      </tr>
    </table>
  </section>
</template>
<script>
import error_Msg from "../../models/error_show.vue";
import load_ing from "../../models/loading.vue";

export default {
  name: "book_pdf",
  components: {
    load_ing,
    error_Msg,
  },
  props: ["data"],
  data() {
    return {
      isLoading: false,
      showError: false,
      pdfData: {},
    };
  },
  async mounted() {
    try {
      console.log(this.$route.params);
      const arrayJson = this.$route.params;
      console.log(arrayJson);
      //   this.pdfData = JSON.parse(arrayJson);
      //   console.log(this.pdfData);
      //   const element = document.getElementById("pdfSection");
      //   const options = {
      //     margin: [0.2, 0.2, 0.1, 0.1],
      //     filename: "Serial Wise Book List",
      //     font: "Times New Roman",
      //     image: { type: "jpeg", quality: 0.98 },
      //     html2canvas: { scale: 5 },
      //     jsPDF: { unit: "mm", format: "A4", orientation: "portrait" },
      //     pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      //   };
      //   html2pdf().set(options).from(element);
      // const pdfDoc = html2pdf().set(options).from(element);
      // pdfDoc.save();
    } catch (error) {
      this.isLoading = false;
      this.showError = true;
      this.errorMsg = error.response.data.message;
    }
  },
};
</script>

<style>
.pdf {
  width: 210mm;
  padding-top: 5px;
  margin: 2px;
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pdf .headerPdf {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-top: 5px;
}

.pdf .headerPdf img {
  height: 70px;
}
.pdf .headerPdf .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pdf .headerPdf .box p {
  margin: 1px;
  margin-top: 8px;
  margin-bottom: 4px;
  height: 30px;
  text-align: center;
  width: 100%;
  color: var(--black);
  background-color: var(--text-color);
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
}

.pdf table {
  background-color: #fff;
  margin-top: 5px;
  width: 95%;
  padding: 2px;
  border-radius: 1px;
  box-shadow: none;
  border-collapse: collapse;
}
.pdf table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.pdf table th {
  background-color: rgb(97, 97, 97);
  color: white;
  width: 30%;
  font-size: 16px;
  font-weight: 700;
  border: 2px solid black;
  border-radius: 0%;
  text-align: center;
  padding: 0.2%;
}
.pdf table th,
td .sl {
  width: 4px;
  padding: 4px 10px;
}
.pdf .th50 {
  max-width: 40px;
  padding: 4px 2px;
}
.pdf .leftAlign {
  text-align: left;
}
.pdf table td {
  font-size: 12px;
  font-weight: 450;
  text-align: center;
  border: 2px solid black;
  border-top: 0;
  padding: 1px 1px;
}
</style>
