<template>
  <div class="container-01">
    <side_bar />
    <load_ing :isLoading="isLoading" />
    <error_show
      :showError="showError"
      :errorMsg="errorMsg"
      @close-error="showError = false"
    />
    <success_show
      :showMsg="isSuccess"
      :successMsg="msgS"
      @close-error="showMsg = false"
    />
    <!-- ========================= Main ==================== -->
    <div class="main_admin" style="">
      <top_bar :pageTitle="pgTitle" />

      <div class="container">
        <div class="row mt-3">
          <form
            @submit.prevent="makeUser"
            enctype="multipart/form-data"
            class="pt-4 col-4"
            style="
              margin-left: 30px;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            "
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Name : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  style="padding-left: 4px; width: 300px"
                  class="form-control"
                  v-model="name"
                  placeholder="Enter name"
                  required
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Designation : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  style="padding-left: 4px; width: 300px"
                  class="form-control"
                  v-model="designation"
                  placeholder="Enter Designation"
                  required
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Email : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  style="padding-left: 4px; width: 300px"
                  class="form-control"
                  v-model="email"
                  placeholder="Enter valid mail"
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Phone : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  style="padding-left: 4px; width: 300px"
                  class="form-control"
                  v-model="phone"
                  placeholder="Enter phone number"
                  required
                />
              </div>
            </div>

            <br />

            <!-- <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Address : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <textarea
                  class="form-control"
                  v-model="address"
                  rows="3"
                  style="padding-left: 46px"
                ></textarea>
              </div>
            </div> -->

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >Password : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  style="padding-left: 4px; width: 300px"
                  class="form-control"
                  v-model="password"
                  placeholder=""
                  required
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div class="text-align-left">
                <label for="exampleFormControlInput1" class="form-label"
                  >Confirm Password : &nbsp;<span
                    style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <input
                  type="text"
                  class="form-control"
                  style="padding-left: 4px; width: 300px"
                  v-model="confirm_password"
                  placeholder=""
                  required
                />
              </div>
            </div>

            <br />

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="exampleFormControlInput1" class="form-label"
                  >User Role : &nbsp;<span style="color: red; font-size: 16px"
                    >*</span
                  ></label
                >
              </div>
              &nbsp;
              <div>
                <select
                  class="btn btn-outline-primary"
                  style="padding-left: 4px; width: 200px"
                  v-model="checkedPermissions"
                  required
                >
                  <option value="" disabled>Select user type</option>
                  <option value="Admin">Admin</option>
                  <option value="Employee">Employee</option>
                </select>
              </div>
            </div>
            <br />
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <label for="formFile" class="form-label">Picture:</label>
              </div>
              &nbsp;
              <div class="">
                <input
                  class="form-control"
                  @change="handleFileChange"
                  type="file"
                  id="formFile"
                />
              </div>
            </div>

            <br />
            <br />
            <div class="d-grid gap-2">
              <input
                type="submit"
                class="form-control btn btn-outline-success"
                style="padding-left: 4px; width: 300px"
              />
            </div>

            <br />
            <br />
          </form>

          <div
            class="col-7"
            style="
              margin-left: 30px;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            "
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Email</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in user_details" v-bind:key="user._id">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>
                    <img
                      v-if="user.avatar"
                      :src="`${user.avatar}`"
                      alt=""
                      style="width: 30px; height: 30px; border-radius: 50%"
                    />
                    <img
                      v-else
                      src="../../../assets/cover 01.png"
                      alt=""
                      style="width: 30px; height: 30px; border-radius: 50%"
                    />
                  </td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.designation }}</td>
                  <td>{{ user.email }}</td>
                  <td class="text-right">
                    <a :href="'/update_users/' + user._id">
                      <button class="btn btn-success mr-2">Edit</button>
                    </a>

                    <button
                      class="btn btn-danger"
                      @click="confirmDelete(user._id)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <teleport to="body">
    <div class="bgPop" v-if="showDeletation">
      <div class="popBg">
        <h2>Confirm Delete User</h2>

        <div class="popText">
          <p>Are you sure to delete the user ?</p>
        </div>

        <div class="actionButtons">
          <button
            @click="deleteUser(deleteId)"
            style="margin-right: 50px"
            class="confirm"
          >
            Delete
          </button>
          <button @click="handleCancel" class="cancel">Cancel</button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import error_show from "../../models/error_show.vue";
import load_ing from "../../models/loading.vue";
import success_show from "../../models/success_show.vue";
import side_bar from "../sections/sidebar.vue";
import top_bar from "../sections/topbar.vue";
export default {
  name: "add-users",
  components: {
    side_bar,
    top_bar,
    load_ing,
    error_show,
    success_show,
  },
  data() {
    return {
      isLoading: false,
      showError: false,
      showMsg: false,
      showDeletation: false,
      isSuccess: false,
      msgS: "",
      pgTitle: "Add Users",
      name: "",
      designation: "",
      email: "",
      phone: "",
      address: "",
      password: "",
      confirm_password: "",
      checkedPermissions: "",
      picture: null,
      user_details: {},
      deleteId: "",
      avatar: null,
      img: null,
    };
  },
  methods: {
    confirmDelete(id) {
      this.deleteId = id;
      this.showDeletation = true;
    },
    handleCancel() {
      this.showDeletation = false;
    },
    handleFileChange(event) {
      this.avatar = event.target.files[0];

      const reader = new FileReader();

      reader.onload = () => {
        this.isLoading = true;
        this.img = reader.result;
        this.isLoading = false;
      };

      reader.readAsDataURL(this.avatar);
    },
    async makeUser() {
      try {
        const token = localStorage.getItem("Hakkani_token");
        const config = {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data",
          },
        };
        const userInfo = {
          name: this.name,
          mobile: this.phone,
          email: this.email,
          password: this.password,
          permission: this.checkedPermissions,
          designation: this.designation,
          avatar: this.img,
        };
        console.log(userInfo);
        this.isLoading = true;
        let result = await axios.post("/employee/add", userInfo, config);
        this.isLoading = false;

        console.log(result);
        this.msgS = "User Added Succesfully";
        this.isSuccess = true;
      } catch (error) {
        this.isLoading = false;
        this.showError = true;
        this.errorMsg = error;
      }
    },
  },
  async mounted() {
    try {
      const token = localStorage.getItem("Hakkani_token");
      const config = {
        headers: { Authorization: `${token}` },
      };
      this.isLoading = true;
      let result = await axios.get("/employee/view", {
        headers: config.headers,
      });
      this.isLoading = false;
      this.user_details = result.data;
      console.log(this.user_details);
    } catch (error) {
      this.isLoading = false;
      this.showError = true;
      this.errorMsg = error.response.data.message;
    }
  },
};
</script>

<style>
label {
  margin: 1px !important;
}

.form-control {
  width: 100% !important;
}
.popBg .actionButtons {
  margin: 2px;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.popBg .actionButtons .confirm {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  padding: 5px !important;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: rgb(0, 165, 0) !important;
  box-shadow: 1px 2px 7px #000;
  cursor: pointer;
  transition: 0.3s ease-in;
}

.popBg .actionButtons .Delete,
.cancel {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-top: 15px;
  padding: 5px !important;
  border-radius: 5px;
  letter-spacing: 1.2px;
  background-color: red !important;
  box-shadow: 1px 2px 7px #000;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.popBg .actionButtons .confirm:hover {
  background-color: green;
}

.popBg .actionButtons .cancel:hover {
  background-color: red !important;
}
</style>
